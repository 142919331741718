
import { parseToken, buildObjectProperty, convertRgbHex, getDefaultGroup, setCookie, getCookie, convertRgb,getTokenCookie,assetUrl, getCartCookie } from '../utils/Common'
import axiosInstance from '../utils/axiosInstance.js'
import { API_URL, ESHOW_URL, main_widow_offset_x, main_widow_offset_y  } from '../config.js'

export const saveLog = async (postData)=>{
    const options = {
        headers: {
            'body': '[{"company:","test"}]', 
            'Content-Type': 'application/json'
          }
    }
    var url = `${ESHOW_URL()}?method=putAuditLog&token=${getTokenCookie()}`
    var response = await axiosInstance.post(url, JSON.stringify(postData), options)
    if(response.status == 200){
        
    }
}

export const fetchCategoryOptions =async ()=>{
    try {
        var url = `${ESHOW_URL()}?method=getProductCategories&token=${getTokenCookie()}` 
        var response = await axiosInstance.get(url);

        const result = response.data;
        if(result.SUCCESS){  
            if(result.CATEGORIES){        
                var data = result.CATEGORIES
                data.sort(function (a, b) {
                    return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                });          
                return data;
            }
        }
        else {
            throw new Error("Failed to fetch categories");
        }
    } catch (error) {
        throw error;
    }
}  