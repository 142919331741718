import React ,{ useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { connect } from 'react-redux'
import { setMainLayers, setShowGrid, setBoothNumberLayer, setBoothOutlineLayer, setAnnotationLayer, setOriginalLayer, setCategoryOptions } from '../../../actions/main'
import { setLeftPage, setLeftSelectedCategory } from '../../../actions/ui'
import { setOpenLogin, setLeftBoothList, setBookmardUpdated, setOpenBoothList, setOpenBoothDrawer } from '../../../actions/ui'
import LayersIcon from '@mui/icons-material/Layers';
import LinkIcon from '@mui/icons-material/Link';
import { setLoading } from '../../../actions/main'
import qs, { parse } from 'qs'
import axios from 'axios';
import { API_URL } from '../../../config.js'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { parseToken, listToTree, download } from '../../../utils/Common'
import MenuIcon from '@mui/icons-material/Menu';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import BoothDrawer from './BoothDrawer';
import CompanyEditModal from '../CompanyEditModal'
import { setActiveObject } from '../../../utils/CanvasTool'
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import Fade from '@mui/material/Fade';
import { fetchCategoryOptions } from '../../../services/service.js'
const ColorButton = styled(Button)(({ theme }) => ({
  color: '#7c7c7c',
  minWidth:'55px',
  width: '38px',
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#c9c9c980',
  },
}));

function Index(props) {
  const { onClose, selectedValue, open } = props;
  const [boothList, setBoothList] = useState([]);
  const [filteredBoothList, setFilteredBoothList] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState(null);
  const [editBooth, setEditBooth]  = React.useState(null);
  const [openCompanyModal, setOpenCompanyModal] = React.useState(false);
  var mainLayers = props.main.mainLayers;
  const htmlElRef = React.useRef(null)

  const main_widow_offset_x = 0;
  const main_widow_offset_y = 51;

  const handleClose = () => {
    onClose(selectedValue);
  };


  const getBoothList =() => {
    var boothList = [];
    if(props.main.mainCanvas){
      var objects = props.main.mainCanvas.getObjects();
      for(var i = 0; i< objects.length; i++){
        var object = objects[i]
        var row = {}
        if((object.get('class')=="booth" || object.get('class')=="booth-group") && object.layer != "deleted" ){
          row['name'] = object.booth_number
          row['priced'] = false
          var priceCheck = object.get('priceCheck');
          var premiumCheck = object.get('premiumCheck');
          if(premiumCheck){
            row['priced'] = true
          }
          if(priceCheck && priceCheck.length > 0){
            row['priced'] = true
          }
          if(object.get('sold')){
            row['priced'] = true
          }
          var groupId = object.get('id');
          row['id'] = groupId?groupId.replace("booth-",""):""
          row['company'] = object.get('company')
          row['boothgroup'] = object.get('boothGroup')
          row['boothType'] = object.get('boothType')
          row['bookmark'] = object.get('bookmark')
          row['company_tag_3dbooth'] = object.get('company_tag_3dbooth')
          row['company_category'] = object.get('company_category')
          row['company_tag_featured'] = object.get('company_tag_featured')
          row['booth'] = object
          if(row['company']){
            row['name'] = row['name']?.replace(row['company'],"")
            row['name'] = row['name']?.replace('\n',"")
          }
          if(row['name'])
            boothList.push(row)
        }
      }
    }

    boothList.sort(function(a, b) {
      var a_val = (a.name)?a.name:''
      var b_val = (b.name)?b.name:''
      return a_val.toString().localeCompare(b_val.toString(), 'en', { numeric: true })
    });


    setBoothList(boothList)
  }

  useEffect(() => {
    if(props.open){
      setBoothList([])
      getCategoryOptions();
      props.setOpenBoothDrawer(true)
    }
  }, [props.open])

  useEffect(() => {
    getCategoryOptions();
    getBoothList();
  }, [props.main.mainCanvas, props.main.boothLoaded])

  useEffect(() => {
    if(props.ui.bookmarkUpdated){
      getCategoryOptions();
      getBoothList();
      props.setBookmardUpdated(false)
    }
  }, [props.ui.bookmarkUpdated])

  const getCategoryOptions =async ()=>{
      const categories = await fetchCategoryOptions();
      props.setCategoryOptions(categories)
  }

  const exportPdf = ()=>{
    var mainSvg;
    props.setLoading(true)
    mainSvg = props.main.mainCanvas.toSVG({
      width:'100%',
      height: '100%',
      viewBox: {          
          x:0,
          y:0,
          width:Math.abs(props.main.mainCanvas.endX-props.main.mainCanvas.startX+50),
          height:Math.abs(props.main.mainCanvas.endY-props.main.mainCanvas.startY+50)
      }
      

    });
      
    var bookmarkedExhibitors = [];

    if(boothList && boothList.length > 0){
      var newBoothList = boothList.filter((booth)=>{
        return booth.bookmark
      })
  
      for(var i = 0; i < newBoothList.length; i++){
        bookmarkedExhibitors.push({company: newBoothList[i]['company'], boothName: newBoothList[i]['name']})
      }
    }

    var url = `/generatepdf`
    var formdata = 
    {
        content:mainSvg,
        bookmarkedExhibitors: bookmarkedExhibitors
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axios.post(url, data, options).then(res=>{
        const result = res.data;
        if(result.status == "success"){
          download(`${API_URL()}file.pdf`, 'booth.pdf');
          props.setLoading(false)
        }
        else{
          props.setLoading(false)
        }
        
    }).catch(error => {
        console.log("error", error);
        props.setLoading(false)
    })
  }

  const allExhibitorCount = ()=>{
    var count= 0;
    var objects = props.main.mainCanvas.getObjects();
    for(var i = 0; i< objects.length; i++){
      var object = objects[i]
      if((object.get('class')=="booth" || object.get('class')=="booth-group") && object.layer != "deleted" ){
        if(object.sold)
          count++
      }
    }
    return count;
  }
  const featuredCount = ()=>{
    var count= 0;
    if(boothList && boothList.length > 0){
      var newBoothList = boothList.filter((booth)=>{
        return (booth.company_tag_featured)
      })
  
      return newBoothList.length
    }
    return 0;
  }
  const tableCount = ()=>{
    var count= 0;    
    if(props.main.mainCanvas){
      props.main.mainCanvas.forEachObject(function(object) {
        if(object.class=="table"){
          count++
        }
      });
    }
    return count;
  }
  const bookmarkCount = ()=>{
    var count= 0;
    if(boothList && boothList.length > 0){
      var newBoothList = boothList.filter((booth)=>{
        return booth.bookmark
      })
  
      return newBoothList.length
    }
    return 0;
  }
  const threeCount = ()=>{
    var count= 0;
    if(boothList && boothList.length > 0){
      var newBoothList = boothList.filter((booth)=>{
        return (booth.company_tag_3dbooth)
      })
  
      return newBoothList.length
    }
    return 0;
  }
  const categoryCount = (category)=>{
    var count= 0;
    if( category && boothList && boothList.length > 0){
      var newBoothList = boothList.filter((booth)=>{        
        return ( booth['company_category'] && booth['company_category'].length>0 && booth['company_category'].includes(category))
      })
  
      return newBoothList.length
    }
    return 0;
  }

  const RenderTreeWithCheckboxes = (nodes, main = false) => {
    return (
      <div style={{paddingLeft:'5px'}}>
        <li className={`leftCategoryLine ${main?"main-cat":""}`} onClick={()=>{props.setLeftPage('category'); props.setLeftSelectedCategory(nodes)}}>
          <Box display="flex" style={{justifyContent:'space-between', width:'100%'}}>
            <Box className="" style={{width:'90%', whiteSpace:"normal"}}
            >
              {nodes.TITLE}
            </Box>
            <Box style={{paddingLeft:'12px', width:'10%', fontSize:14.5, color:'rgb(160, 160, 160)'}}
            >
              {nodes.QTY}
            </Box>
          </Box>
        </li>
        {Array.isArray(nodes.SUBCAT)
          ? nodes.SUBCAT.map((node) => RenderTreeWithCheckboxes(node))
          : null}
      </div>
    );
  };

  const handleOpenCompanyModal=(index)=>{
    setEditBooth(filteredBoothList[index])
    setOpenCompanyModal(true)
  }

  const handleCloseCompanyModal=(index)=>{
    setOpenCompanyModal(false)
  }

  const doSearch = (searchVal)=>{
    if(searchVal){
      var filteredBoothList = boothList.filter((booth)=>{
        var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
        return (str.toLowerCase().includes(searchVal.toLowerCase()))
      })
      setFilteredBoothList(filteredBoothList)
    }
    else
      setFilteredBoothList([])
  }

  var cartList = props.cart.carts;

  var categoryData = {
    KEY_ID: "0",
    TITLE: "Parent",
    SUBCAT: []
  }
  if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
    // categoryData.SUBCAT = listToTree(props.main.categoryOptions)
    categoryData.SUBCAT = (props.main.categoryOptions)
  }
  else{

  }
  
  var listHeight = window.innerHeight - 180
  var cssHeight = 'calc(100vh - 180px)'
  if(props.role == "admin"){
    listHeight = window.innerHeight - 180 - 63
    cssHeight = 'calc(100vh - 180px - 63px)'
  }

  return (
    <Fade in={true} timeout={1200}>
      <Box pt={0}>
        <Box display="flex" style={{alignItems:'center', justifyContent:'space-between'}} pt={2}  pb={2}>
          
          <div style={{marginLeft:20}}></div>
          <Box textAlign="left">
            <Box display="flex">
              <h4 style={{textTransform:'capitalize', margin:0}}>
                Categories
              </h4> 
            </Box>
          </Box>
          <div style={{display:'flex', alignItems:'center', paddingRight:3}}>
            {props.role == 'admin' ?          
            <IconButton
                size="small"
                edge="start"
                aria-label="assign"
                style={{float:'right'}}
                onClick={()=>{props.setOpenBoothList(false)}}
              >
                <HighlightOffOutlinedIcon/>
            </IconButton>:
            <IconButton
              size="small"
              edge="start"
              aria-label="assign"
              style={{float:'right'}}
              onClick={()=>{props.setLeftPage('home')}}
            >
              <ChevronLeftIcon/>
            </IconButton>
            }
          </div>
        </Box>
        
        <Box display="flex"  style={{justifyContent:'space-between', padding:'10px 16px', borderBottom:'1px solid #e0e0e0', background:(props.ui?.customStyles?.['left-table-header-bg']?props.ui?.customStyles['left-table-header-bg']:'#4e7090'), color:'white', alignItems:'center'}}>
          <Box style={{width: '88%', textAlign:'left', paddingLeft: '0px'}}><b>Category</b></Box>
          <Box style={{width: '12%', textAlign:'left', paddingLeft: '8px'}}><b><CategoryOutlinedIcon/></b></Box>
        </Box>
        <Box style={{padding:'10px 10px', overflowY:'auto', maxHeight: cssHeight}} textAlign="left">
          {
            categoryData.SUBCAT.map((node) => RenderTreeWithCheckboxes(node, true))
          }
        </Box>
      </Box>
    </Fade>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
      cart: state.cart,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) ,
        setShowGrid: value=>dispatch(setShowGrid(value)) ,
        setBoothNumberLayer: value=>dispatch(setBoothNumberLayer(value)) ,
        setBoothOutlineLayer: value=>dispatch(setBoothOutlineLayer(value)) ,
        setAnnotationLayer: value=>dispatch(setAnnotationLayer(value)) ,
        setOriginalLayer: value=>dispatch(setOriginalLayer(value)) ,
        setLeftPage: value=>dispatch(setLeftPage(value)) ,
        setLoading:  data=>dispatch(setLoading(data)),
        setLeftSelectedCategory: value=>dispatch(setLeftSelectedCategory(value)),
        setLeftBoothList:  data=>dispatch(setLeftBoothList(data)),
        setBookmardUpdated:   data=>dispatch(setBookmardUpdated(data)),
        setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
        setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
        setOpenBoothDrawer: value=>dispatch(setOpenBoothDrawer(value)) ,
        setCategoryOptions:  data=>dispatch(setCategoryOptions(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);