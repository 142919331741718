// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container{
  display:flex
}
.toolbar{
  position: fixed;
  left:0;
  top:0;
  width: 170px;
  height: 100%;
  z-index: 10000;
  background: #e0e0e0;
  padding: 25px 10px;
  border-right: 3px solid #707070;
}
[draggable] {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
  cursor: move;
}

button.active {
  background-color: #32475e!important
}

.sketch textarea{
  position: unset!important;
}`, "",{"version":3,"sources":["webpack://./src/viewer/Main.css"],"names":[],"mappings":"AAAA;EACE;AACF;AACA;EACE,eAAe;EACf,MAAM;EACN,KAAK;EACL,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,+BAA+B;AACjC;AACA;EAGE,yBAAyB;EACzB,iBAAiB;EACjB,yBAAyB;EACzB,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE;AACF;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".main-container{\r\n  display:flex\r\n}\r\n.toolbar{\r\n  position: fixed;\r\n  left:0;\r\n  top:0;\r\n  width: 170px;\r\n  height: 100%;\r\n  z-index: 10000;\r\n  background: #e0e0e0;\r\n  padding: 25px 10px;\r\n  border-right: 3px solid #707070;\r\n}\r\n[draggable] {\r\n  -moz-user-select: none;\r\n  -khtml-user-select: none;\r\n  -webkit-user-select: none;\r\n  user-select: none;\r\n  -khtml-user-drag: element;\r\n  -webkit-user-drag: element;\r\n  cursor: move;\r\n}\r\n\r\nbutton.active {\r\n  background-color: #32475e!important\r\n}\r\n\r\n.sketch textarea{\r\n  position: unset!important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
