export default function Sketch(canvas) {
    // p5.setup = () => {
    //   p5.createCanvas(300, 300);
    //   p5.noFill();
    //   p5.noLoop();
    // };
    return {draw};
  
    function draw(){
    //   p5.background('#FBF8F2');
    //   p5.stroke('#0F0F0F');
    //   p5.strokeWeight(3.7);
  
      const margin = 0.1;
      const x = canvas.width * margin;
      const y = canvas.height * margin;
  
      let w = canvas.width - 2 * x;
      let h = canvas.height - 2 * y;
  
      const rectangle = new Rectangle(x, y, w, h);
    //   rectangle.draw();
  
      const { a, b, c, d } = rectangle.coordinates();
      const { p, q, r, s } = rectangle.centerCoordinates();
      const o = [x + w / 2, y + h / 2];
  
      drawLine(p, r);
      drawLine(q, s);
  
      drawLines([a, p], [s, o], 18);
      drawLines([p, o], [b, q], 18);
  
      drawLines([s, o], [s, d], 13);
      drawLines([d, r], [o, r], 13);
  
      drawLines([q, c], [q, o], 5);
      drawLines([c, r], [o, r], 8);
    };
  
    function drawLines(edge1, edge2, k) {
      const [pointA, pointB] = edge1;
      const [pointC, pointD] = edge2;
  
      for (let i = 0; i < k; ++i) {
        const [x1, y1] = pointCoordinatesOnLine(pointA, pointB, i / k);
        const [x2, y2] = pointCoordinatesOnLine(pointC, pointD, i / k);
        drawLine([x1, y1], [x2, y2]);
      }
    }
  
    function drawLine(pointA, pointB) {
        // p5.line(pointA[0], pointA[1], pointB[0], pointB[1]);
        var ctx = canvas.getContext('2d');
        ctx.lineWidth = 1; // state.lineWidth/2.;
        ctx.strokeStyle = 'red';
        ctx.beginPath();
        const x1 = pointA[0];
        const y1 = pointA[1];
        var x2 = pointB[0];
        var y2 = pointB[1];
        // var rotatePoint = rotate(x1, y1, x2, y2, -45)
        // x2 = rotatePoint[0];
        // y2 = rotatePoint[1];
        // console.log("x1", x1)
        // console.log("y1", y1) 
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
        ctx.closePath();
    }
  
    function Rectangle(x, y, width, height) {
      return {
        centerCoordinates,
        coordinates,
        draw,
      };
  
      function centerCoordinates(midpoints = {}) {
        const midpointMapping = {
          ab: 'p',
          bc: 'q',
          cd: 'r',
          da: 's',
          ba: 'p',
          cb: 'q',
          dc: 'r',
          ad: 's',
        };
  
        const calculateMidpoint = (point1, point2) => {
          let [kA, pointA] = point1;
          let [kB, pointB] = point2;
  
          const edge = `${kA}${kB}`;
  
          midpoints[midpointMapping[edge]] = pointCoordinatesOnLine(
            pointA,
            pointB,
            0.5
          );
  
          return point2;
        };
  
        const coordinatesArray = Object.entries(coordinates());
        const sideDA = [
          coordinatesArray[0],
          coordinatesArray[coordinatesArray.length - 1],
        ];
  
        [...coordinatesArray, ...sideDA].reduce((point1, point2) =>
          calculateMidpoint(point1, point2)
        );
  
        return midpoints;
      }
  
      function coordinates() {
        const w = x + width;
        const h = y + height;
  
        return {
          a: [x, y],
          b: [w, y],
          c: [w, h],
          d: [x, h],
        };
      }
  
      function draw() {
        // p5.rect(x, y, width, height);
      }
    }
  
    // find coordinates of a point on line at distance k
    function pointCoordinatesOnLine(pointA, pointB, k) {
      const x = pointA[0] + k * (pointB[0] - pointA[0]);
      const y = pointA[1] + k * (pointB[1] - pointA[1]);
  
      return [x, y];
    }
  }