export const parsedwg = [
    {
        "Type": "BlockReference",
        "Handle": "10174E4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174E5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174E6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174E7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21316.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174E8",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21436.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174EA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174EF",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19996.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174F3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174F4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174F5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174F7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19876.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174FB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174FC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174FD",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174FE",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10174FF",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017500",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17116.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017501",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017503",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16276.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017505",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017506",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017507",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101750B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17116.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017510",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17116.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017511",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17116.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017518",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017519",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101751A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101751B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101751C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101751D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101751E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16036.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101751F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16036.6",
            "y": "-2727.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017522",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16036.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017523",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-2727.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017524",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15676.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017525",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017526",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017527",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15676.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017529",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101752B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "-2727.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017532",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017536",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "-2967.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101753B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16276.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101753D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101753E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017542",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017543",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017544",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017546",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017548",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017549",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19276.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101754A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101754B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18556.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101754C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101754D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101754F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017550",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017551",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017554",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017555",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101755A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101755B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101755D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101755E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017560",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18556.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017561",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017562",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18556.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017563",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017564",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017565",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017566",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19276.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017567",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017568",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017569",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19276.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101756A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101756D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "-2967.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101756E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017579",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-2967.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101757A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-2967.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101757C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101757D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-2967.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017580",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017581",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017588",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017589",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101758A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101758B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101758D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101758E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-2727.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017592",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-2727.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017594",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-2967.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017595",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017599",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101759A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-2967.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101759C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101759D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101759E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175A2",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175A9",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175AB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175AC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175D9",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19996.6",
            "y": "-2727.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175DB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19516.6",
            "y": "-2727.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175DC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19636.6",
            "y": "-2727.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175E1",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19636.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175E2",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19636.6",
            "y": "-2967.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175EB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19876.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175EF",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19636.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175F0",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19516.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175F7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19996.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10175FA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19996.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101761D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101761F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017621",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017622",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017625",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017626",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017627",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017628",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017629",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101762A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101762F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017630",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017631",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017632",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017633",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017636",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017638",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101763E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101763F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017641",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017651",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-2967.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017653",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017655",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101765A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101765D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017662",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017663",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017668",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-2727.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101766B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101766C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101766E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-2967.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017676",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017677",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017678",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017679",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101767A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101767B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176C6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176C7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176CA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176CB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18556.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176CF",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176D2",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176D4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16276.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176D5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16396.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176D6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176D7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176D8",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176DA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15676.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176DF",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17116.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176E0",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17116.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176E1",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17116.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176E2",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17116.6",
            "y": "-1647.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176E5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17116.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176E7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16036.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176F4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15676.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176F6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176F7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15676.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10176F9",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017709",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16276.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101770A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16396.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101770B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16276.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101770C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16396.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101771A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "-1647.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101771B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "-1647.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101771C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "-1647.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101771D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16036.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101771F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16036.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017735",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15676.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017737",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017738",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15676.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101773A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101773B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017745",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101774A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16276.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101774B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16396.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101774D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16396.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101774E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101774F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017750",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017751",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017752",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017753",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017755",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "-1647.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017756",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017760",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017762",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017763",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017764",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017773",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017774",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017776",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017777",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18556.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017778",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017779",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101777A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18556.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101777B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101777C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18556.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101777D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101777E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017781",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017784",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-1647.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017786",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017789",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-1647.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101778D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-1647.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017792",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017796",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017799",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101779C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101779F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177A0",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177A4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177A5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177A6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177A7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177A8",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177A9",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177AA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177AB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177AC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177AD",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177AF",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177B3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177B4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177B5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177B6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18556.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177B7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177BB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177BD",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177C3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177C6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177C7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177C8",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19276.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177CA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177CB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19276.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177CC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17116.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177CD",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17116.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177D3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16036.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177D4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16036.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177D7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177D9",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177E0",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177E1",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177E2",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16276.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177E6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16276.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177E7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16396.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177EA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16276.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177F4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16276.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177F5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16396.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177F6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16276.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10177F7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16396.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017816",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017817",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017818",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017819",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101781A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101781B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017825",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017826",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017827",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017828",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17476.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017831",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017834",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017837",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017838",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18556.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017839",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101783C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101783D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101783F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19276.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017840",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18916.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017844",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017848",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18556.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017849",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101784A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18556.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101784B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101784E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017852",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017889",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101788E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21316.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017890",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017895",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19996.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017899",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19876.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178D4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19516.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178D5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19636.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178D9",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19516.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178DA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19636.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178DB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19636.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178DC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19516.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178E3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19876.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178E4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19876.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178E5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19636.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178E6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19516.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178EA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19996.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178ED",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19996.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178FA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21316.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178FB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21436.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178FC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21316.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10178FD",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21436.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017904",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21316.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017905",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21436.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017906",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21316.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017907",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21436.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101790F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017910",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21676.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017911",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017912",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017914",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017918",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21676.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017919",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101791D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101791E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101791F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-1647.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017923",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017925",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-1647.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017926",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-1647.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017927",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101792F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101793D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101793F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-1647.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017944",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017945",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017946",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017947",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017949",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101794A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101794B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101794C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21676.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101794D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101794E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101794F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017950",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017951",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017952",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21676.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017953",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017956",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017957",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017958",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101795B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101795E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101795F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017961",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017963",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017964",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017965",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017966",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101796A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101796B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101796E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017970",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017978",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017979",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101797B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017980",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "20236.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017986",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19876.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017988",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19876.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101798A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19636.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101798D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19636.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017990",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19996.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017995",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "20236.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017996",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19996.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10179A1",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "20356.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10179A6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "20356.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10179AA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21436.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10179AB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21316.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10179AE",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21076.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10179B8",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21316.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10179BC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21316.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10179C7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21436.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "10179CA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21436.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A0F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A10",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A14",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A15",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21676.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A16",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A17",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A18",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A19",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A1A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A1C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A22",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21676.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A23",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A24",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A25",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A28",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A29",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A2A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A2B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A2E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21676.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A2F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A30",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21676.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A31",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A37",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A39",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A40",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A45",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A49",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A53",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A54",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A55",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1017A56",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1018CAF",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20956.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x40",
        "Position": {
            "x": "20716.6",
            "y": "-3447.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B211",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15676.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B221",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "15916.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B243",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16396.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B24F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B257",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B259",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "-2127.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B261",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17116.6",
            "y": "-2127.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B26D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17356.6",
            "y": "-2127.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B285",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-2127.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B2A3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18436.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B2AB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18556.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B2B7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18796.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B2C7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19156.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B2EB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19876.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B2F3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "19996.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B349",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21316.6",
            "y": "-2127.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B34F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21316.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B351",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21436.6",
            "y": "-2127.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B361",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-2127.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B369",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22036.6",
            "y": "-2127.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B375",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22156.6",
            "y": "-2127.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B383",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22396.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B385",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-2127.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B38B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22516.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B3A7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "23116.6",
            "y": "-567.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B405",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21316.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x40",
        "Position": {
            "x": "21076.6",
            "y": "-3447.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B42F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20956.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x30",
        "Position": {
            "x": "20716.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B434",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21316.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x30",
        "Position": {
            "x": "21076.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B44E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x40",
        "Position": {
            "x": "20236.6",
            "y": "-3447.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B458",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x40",
        "Position": {
            "x": "19876.6",
            "y": "-3447.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B468",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "19516.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B4A4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x40",
        "Position": {
            "x": "21436.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B844",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x30",
        "Position": {
            "x": "20236.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B882",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x30",
        "Position": {
            "x": "21436.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B8A6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20956.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "20716.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B8AE",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20956.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x40",
        "Position": {
            "x": "20716.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B8DA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x30",
        "Position": {
            "x": "21076.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B8E5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x30",
        "Position": {
            "x": "21076.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101B922",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x40",
        "Position": {
            "x": "21316.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101C18F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x40",
        "Position": {
            "x": "20716.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101C1AA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20956.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x40",
        "Position": {
            "x": "20716.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101C1BA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x50",
        "Position": {
            "x": "19156.6",
            "y": "-2127.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101C4C3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x80",
        "Position": {
            "x": "20236.6",
            "y": "-1287.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D408",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "22756.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D591",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D593",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D595",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D597",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D599",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16636.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D5A1",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D5A5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D5A9",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D5AD",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D5AF",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D5B1",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16756.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D5B3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16996.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101D60F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16396.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DBB8",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "21076.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DBC1",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "21076.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DBD3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20956.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x50",
        "Position": {
            "x": "20716.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DBDF",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "21076.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DBE6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "21076.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DBF1",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "16636.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DBFF",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x30",
        "Position": {
            "x": "17716.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DC0D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "18076.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DC87",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18196.6",
            "y": "-2727.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DC90",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18556.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DC97",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16996.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DCA0",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16996.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DCA9",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16636.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DCB2",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "17356.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DCBC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18796.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DCC5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18436.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DCCE",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18076.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DCD7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22036.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DCE0",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22396.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DCE9",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "21676.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DD64",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16396.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DD74",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16036.6",
            "y": "-1287.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DD7D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16276.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DD8E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16996.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DDFF",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18196.6",
            "y": "-3447.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE06",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "19276.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE0F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18796.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE18",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "23116.6",
            "y": "-2127.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE1F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22036.6",
            "y": "-2007.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE28",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22396.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE2F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22156.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE38",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22156.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE41",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22396.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE48",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "23116.6",
            "y": "-3447.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE4F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22876.6",
            "y": "-3207.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE56",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22036.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE66",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22756.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE68",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "22876.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE7C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "22756.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE8E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "22036.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DE98",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "21676.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DEA4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "19516.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DEAC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "17716.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DF2C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x30",
        "Position": {
            "x": "17356.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DFAC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x30",
        "Position": {
            "x": "16636.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101DFBA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "16636.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E03E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x30",
        "Position": {
            "x": "16036.6",
            "y": "-807.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E0B7",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "18436.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E0C1",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "19156.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E0CB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "19876.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E45B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "22396.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E4DB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x30",
        "Position": {
            "x": "22756.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E55F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "19516.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E575",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "16276.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E58B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "16996.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E599",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "19876.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E5A3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "19516.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E621",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "16276.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E62F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo20x20",
        "Position": {
            "x": "16276.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E6B1",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18916.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E6BA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18796.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E6C1",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18556.6",
            "y": "-1047.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E6CA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18196.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E6D3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "17116.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E6DA",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16996.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E6E3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "17716.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E6EC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "17476.6",
            "y": "-3447.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E6F5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "17356.6",
            "y": "-3447.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E6FC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "17836.6",
            "y": "-3327.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E707",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18076.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E710",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18436.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E719",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16036.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E720",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "17476.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E729",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "17716.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E732",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18556.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E73B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18076.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E744",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "23116.6",
            "y": "-1287.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E74D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "21676.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E756",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "21676.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E75D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22396.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E766",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22516.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E76F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "21316.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E776",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "21436.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E77D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "21436.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E784",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "21676.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E78D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22876.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E794",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "22396.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E79D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "19276.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E7A6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "19516.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E7AD",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "19276.6",
            "y": "-687.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E7B6",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "20356.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E7BF",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "20236.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E7F2",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16036.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E7FB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "15676.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E80C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "16036.6",
            "y": "-1887.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E818",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16036.6",
            "y": "-1767.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E891",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "15676.6",
            "y": "-2367.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E89A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "17356.6",
            "y": "-1287.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E8A3",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "16996.6",
            "y": "-1167.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E8AC",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "17116.6",
            "y": "-927.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E8B5",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "18916.6",
            "y": "-2727.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E8C2",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "19156.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E8CB",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "19516.6",
            "y": "-3087.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E8D4",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "19876.6",
            "y": "-2847.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E8DD",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x20",
        "Position": {
            "x": "19276.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 0,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E95A",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21436.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E95C",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21676.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E95E",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21436.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E960",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21676.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E962",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-2607.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "101E964",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "21796.6",
            "y": "-2487.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F45",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F47",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F49",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F4B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F4D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F4F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F51",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17716.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F53",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F55",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F57",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F59",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F5B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F5D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F5F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F61",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F63",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F65",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F67",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F69",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F6B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "17836.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F6D",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18076.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F6F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "-447.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F71",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "-327.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F73",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "-207.778",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F75",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "-87.7779",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F77",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "32.2221",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F79",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "152.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F7B",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "18196.6",
            "y": "272.222",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F99",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "20236.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020F9F",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "5",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 5
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo10x10",
        "Position": {
            "x": "20356.6",
            "y": "-2247.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "BlockReference",
        "Handle": "1020FC1",
        "Layer": "Expo_BoothOutline",
        "Color_Index": "2",
        "Color": {
            "Color_Method": 3,
            "Color_Index": 2
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Block_Name": "Expo50x70",
        "Position": {
            "x": "19636.6",
            "y": "-1527.78",
            "z": "0"
        },
        "Scale_Factors": {
            "x": "1",
            "y": "1",
            "z": "1"
        },
        "Rotation": 4.71239,
        "XRef": false
    },
    {
        "Type": "Polyline",
        "Handle": "103B5F1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5F2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5F3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5F4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5F5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5F6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5F7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5F8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5F9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5FA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5FB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5FC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5FD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5FE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B5FF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B600",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B601",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B602",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B603",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B604",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B605",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B606",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B607",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B608",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B609",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B60A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B60B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B60C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B60D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B60E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B60F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B610",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B611",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B612",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B613",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B614",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B615",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B616",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B617",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B618",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B619",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B61A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B61B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B61C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B61D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B61E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B61F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B620",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B621",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B622",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B623",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B624",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B625",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B626",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B627",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B628",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B629",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B62A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B62B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B62C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B62D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B62E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B62F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B630",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B631",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B632",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B633",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B634",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B635",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B636",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B637",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B638",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B639",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B63A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B63B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B63C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B63D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B63E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B63F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B640",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B641",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B642",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B643",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B644",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B645",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B646",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B647",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B648",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B649",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B64A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B64B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B64C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B64D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B64E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B64F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B650",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B651",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B652",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B653",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B654",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B655",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B656",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B657",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B658",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B659",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B65A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B65B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B65C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B65D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B65E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B65F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B660",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B661",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B662",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B663",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B664",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B665",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B666",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B667",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B668",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B669",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B66A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B66B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B66C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B66D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B66E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B66F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B670",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B671",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B672",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B673",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B674",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B675",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B676",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B677",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B678",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B679",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B67A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B67B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B67C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B67D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B67E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B67F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B680",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B681",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B682",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B683",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B684",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B685",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B686",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B687",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B688",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B689",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B68A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B68B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B68C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B68D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B68E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B68F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B690",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B691",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B692",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B693",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B694",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B695",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B696",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B697",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B698",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B699",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B69A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B69B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B69C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B69D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B69E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B69F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6A0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6A1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6A2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6A3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6A4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6A5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6A6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6A7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6A8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6A9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6AA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6AB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6AC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6AD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6AE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6AF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6B0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6B1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6B2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6B3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6B4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6B5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6B6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6B7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6B8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6B9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6BA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6BB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6BC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6BD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6BE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6BF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6C0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24796.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24916.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24796.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6C1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6C2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6C3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6C4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6C5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6C6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6C7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6C8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6C9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6CA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6CB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6CC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6CD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6CE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6CF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6D0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6D1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6D2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6D3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6D4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6D5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6D6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6D7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6D8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6D9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6DA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6DB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6DC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6DD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6DE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6DF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6E0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6E1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6E2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6E3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6E4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6E5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6E6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "932.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "1052.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1052.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "1052.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6E7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "1052.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "1172.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1052.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1172.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "1172.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6E8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "1172.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "1292.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1172.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1292.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "1292.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6E9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6EA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "1412.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "1532.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1532.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "1532.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6EB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "1532.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "1652.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "1652.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1532.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1652.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "1652.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6EC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6ED",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6EE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6EF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6F0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6F1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6F2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6F3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6F4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6F5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6F6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6F7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6F8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6F9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6FA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6FB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6FC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6FD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6FE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B6FF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B700",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B701",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B702",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B703",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B704",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B705",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B706",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B707",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B708",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B709",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B70A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B70B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B70C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B70D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B70E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B70F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B710",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B711",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B712",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B713",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B714",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B715",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B716",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B717",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B718",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B719",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B71A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B71B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B71C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B71D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B71E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B71F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B720",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B721",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B722",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B723",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B724",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B725",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B726",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B727",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B728",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B729",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B72A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B72B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B72C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B72D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B72E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B72F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B730",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B731",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B732",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B733",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B734",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B735",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B736",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B737",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B738",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B739",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B73A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B73B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B73C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B73D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B73E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B73F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B740",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B741",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B742",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B743",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B744",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B745",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B746",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B747",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B748",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B749",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B74A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B74B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B74C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B74D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B74E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B74F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B750",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B751",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B752",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B753",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B754",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B755",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B756",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B757",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B758",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B759",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B75A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B75B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B75C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B75D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B75E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B75F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B760",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B761",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B762",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B763",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B764",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B765",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B766",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B767",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B768",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B769",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B76A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B76B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B76C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B76D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B76E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B76F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B770",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B771",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B772",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B773",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B774",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B775",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B776",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B777",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B778",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B779",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B77A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B77B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B77C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B77D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B77E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B77F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B780",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B781",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B782",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B783",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B784",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B785",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B786",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B787",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B788",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B789",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B78A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B78B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B78C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B78D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B78E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B78F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B790",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B791",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B792",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B793",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B794",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B795",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B796",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B797",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B798",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B799",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B79A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B79B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B79C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B79D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B79E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B79F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7A0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7A1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7A2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7A3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7A4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7A5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7A6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7A7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7A8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7A9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7AA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7AB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7AC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7AD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7AE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7AF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7B0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7B1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7B2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7B3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7B4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7B5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7B6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7B7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7B8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7B9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7BA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7BB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7BC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7BD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7BE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7BF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7C0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7C1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7C2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7C3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7C4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7C5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7C6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7C7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7C8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7C9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7CA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7CB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7CC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7CD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7CE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7CF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7D0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7D1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7D2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7D3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7D4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7D5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7D6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7D7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7D8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7D9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7DA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7DB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7DC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7DD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7DE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7DF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7E0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7E1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7E2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7E3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7E4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7E5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7E6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7E7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7E8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7E9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7EA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7EB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7EC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7ED",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7EE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7EF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7F0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7F1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7F2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7F3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7F4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7F5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7F6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7F7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7F8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7F9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7FA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7FB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7FC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7FD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7FE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B7FF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B800",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B801",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B802",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B803",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B804",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B805",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B806",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B807",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B808",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B809",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B80A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B80B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B80C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B80D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B80E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B80F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B810",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B811",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B812",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B813",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B814",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B815",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B816",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B817",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B818",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B819",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B81A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B81B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B81C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B81D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B81E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B81F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B820",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B821",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B822",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B823",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B824",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B825",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B826",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B827",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B828",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B829",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B82A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B82B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B82C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B82D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B82E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B82F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B830",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B831",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B832",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B833",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B834",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B835",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B836",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B837",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B838",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B839",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B83A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B83B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B83C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B83D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B83E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B83F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B840",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B841",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B842",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B843",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B844",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B845",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B846",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B847",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B848",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B849",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B84A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B84B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B84C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B84D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B84E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B84F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B850",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B851",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B852",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B853",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B854",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B855",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B856",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B857",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B858",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B859",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B85A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B85B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B85C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B85D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B85E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B85F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B860",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B861",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B862",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B863",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B864",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B865",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B866",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B867",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B868",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B869",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B86A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B86B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B86C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B86D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B86E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B86F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B870",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B871",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B872",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B873",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B874",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B875",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B876",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B877",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B878",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B879",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B87A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B87B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B87C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B87D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B87E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B87F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B880",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B881",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B882",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B883",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B884",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B885",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B886",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B887",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B888",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B889",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B88A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B88B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B88C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B88D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B88E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B88F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B890",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B891",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B892",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B893",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B894",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B895",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B896",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B897",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B898",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B899",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B89A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B89B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B89C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B89D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B89E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B89F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8A0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8A1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8A2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8A3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8A4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8A5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8A6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8A7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8A8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8A9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8AA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8AB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8AC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8AD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8AE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8AF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8B0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8B1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8B2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8B3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8B4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8B5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8B6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8B7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8B8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8B9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8BA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8BB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8BC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8BD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8BE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8BF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8C0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8C1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8C2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8C3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8C4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8C5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8C6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8C7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8C8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8C9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8CA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8CB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8CC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8CD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8CE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8CF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8D0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8D1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8D2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8D3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8D4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8D5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8D6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8D7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8D8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8D9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8DA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8DB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8DC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8DD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8DE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16516.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16636.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16516.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8DF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8E0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8E1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8E2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8E3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8E4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8E5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8E6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8E7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8E8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8E9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8EA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8EB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8EC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8ED",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8EE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8EF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8F0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8F1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8F2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8F3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8F4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8F5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8F6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8F7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8F8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8F9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8FA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8FB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8FC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8FD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8FE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B8FF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B900",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B901",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B902",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B903",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B904",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B905",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B906",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B907",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B908",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B909",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B90A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B90B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B90C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B90D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B90E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B90F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B910",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B911",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B912",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B913",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B914",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B915",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B916",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B917",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B918",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B919",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17596.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17716.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17596.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B91A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B91B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B91C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17236.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17356.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17236.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B91D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B91E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B91F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18316.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18436.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18316.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B920",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18676.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18796.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18676.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B921",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B922",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B923",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B924",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B925",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B926",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B927",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B928",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B929",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B92A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B92B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B92C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B92D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B92E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B92F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B930",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B931",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B932",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B933",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B934",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B935",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B936",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B937",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B938",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B939",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B93A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B93B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B93C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B93D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B93E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B93F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B940",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B941",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B942",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B943",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B944",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B945",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B946",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B947",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B948",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B949",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B94A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B94B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B94C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B94D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B94E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B94F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B950",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B951",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B952",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B953",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B954",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B955",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B956",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B957",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B958",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B959",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B95A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B95B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B95C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B95D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B95E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B95F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B960",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B961",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B962",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B963",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B964",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B965",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B966",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B967",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B968",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B969",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B96A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B96B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B96C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B96D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B96E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B96F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B970",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B971",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B972",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B973",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B974",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B975",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B976",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B977",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B978",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B979",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B97A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B97B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B97C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B97D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B97E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B97F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B980",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B981",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B982",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B983",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B984",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B985",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B986",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B987",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B988",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B989",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B98A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B98B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B98C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B98D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B98E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B98F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B990",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B991",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B992",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B993",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B994",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B995",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B996",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B997",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B998",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B999",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B99A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B99B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B99C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B99D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B99E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B99F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9A0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9A1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9A2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9A3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9A4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9A5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9A6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9A7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9A8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9A9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9AA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9AB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9AC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9AD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9AE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9AF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9B0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9B1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9B2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9B3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9B4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9B5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9B6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9B7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9B8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9B9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9BA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9BB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9BC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9BD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9BE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9BF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9C0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9C1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9C2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9C3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9C4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9C5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9C6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9C7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9C8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9C9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9CA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9CB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9CC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9CD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9CE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9CF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9D0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9D1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9D2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9D3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9D4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9D5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9D6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9D7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9D8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9D9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9DA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9DB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9DC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9DD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9DE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9DF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9E0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9E1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9E2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9E3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9E4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9E5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9E6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9E7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9E8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9E9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9EA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9EB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9EC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9ED",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9EE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9EF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9F0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9F1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9F2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9F3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9F4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9F5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9F6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9F7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9F8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9F9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9FA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9FB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9FC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9FD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9FE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103B9FF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA00",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA01",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA02",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA03",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA04",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA05",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA06",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA07",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA08",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA09",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA0A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA0B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA0C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA0D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA0E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA0F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA10",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA11",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA12",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA13",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA14",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA15",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA16",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA17",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA18",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA19",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA1A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA1B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA1C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA1D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA1E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA1F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA20",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA21",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA22",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA23",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA24",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA25",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA26",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA27",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA28",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA29",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA2A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA2B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA2C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA2D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA2E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA2F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA30",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA31",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA32",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA33",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA34",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA35",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA36",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA37",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA38",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA39",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA3A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA3B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA3C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA3D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA3E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA3F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA40",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA41",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA42",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA43",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA44",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA45",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA46",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA47",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA48",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA49",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA4A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA4B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA4C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA4D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA4E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA4F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA50",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA51",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA52",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA53",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA54",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA55",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA56",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA57",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA58",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA59",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA5A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA5B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA5C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA5D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA5E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA5F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA60",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA61",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA62",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA63",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA64",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA65",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22996.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23116.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22996.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA66",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA67",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23716.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23836.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23716.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA68",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA69",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA6A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA6B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA6C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA6D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA6E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA6F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA70",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA71",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA72",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA73",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA74",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA75",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA76",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA77",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA78",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA79",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA7A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA7B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA7C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA7D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA7E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA7F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA80",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA81",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA82",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA83",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA84",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA85",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA86",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA87",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA88",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA89",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA8A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA8B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA8C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA8D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA8E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA8F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA90",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA91",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA92",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA93",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA94",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA95",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA96",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA97",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA98",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA99",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA9A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA9B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA9C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA9D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA9E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BA9F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAA0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAA1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAA2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAA3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAA4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAA5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAA6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAA7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAA8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAA9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAAA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAAB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAAC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAAD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAAE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAAF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAB0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAB1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAB2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAB3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAB4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAB5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAB6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAB7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAB8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAB9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BABA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BABB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BABC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BABD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BABE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BABF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAC0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAC1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24076.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24196.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24076.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAC2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAC3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAC4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.7",
                "y": "500.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.7",
                "y": "620.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.7",
                    "y": "620.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.7",
                    "y": "500.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.7",
                    "y": "620.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.7",
                "y": "620.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAC5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAC6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAC7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAC8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAC9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BACA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BACB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BACC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BACD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BACE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BACF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "572.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "692.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "572.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "692.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "692.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAD0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "692.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "812.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "692.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "812.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "812.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAD1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "812.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "932.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "932.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "812.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "932.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "932.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAD2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAD3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAD4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAD5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15556.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15676.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15556.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15556.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAD6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAD7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAD8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAD9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BADA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BADB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BADC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15916.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16036.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15916.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15916.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BADD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BADE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BADF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAE0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAE1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAE2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAE3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAE4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAE5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAE6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAE7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAE8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAE9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAEA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAEB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAEC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAED",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAEE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAEF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAF0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAF1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAF2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAF3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAF4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAF5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAF6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAF7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAF8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAF9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAFA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAFB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAFC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAFD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAFE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BAFF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB00",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB01",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB02",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB03",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB04",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB05",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB06",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB07",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB08",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB09",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB0A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB0B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB0C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB0D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB0E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB0F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB10",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB11",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB12",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB13",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB14",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB15",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB16",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB17",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB18",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB19",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB1A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB1B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB1C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB1D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB1E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB1F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB20",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB21",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB22",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB23",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB24",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB25",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB26",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB27",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB28",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB29",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB2A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB2B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB2C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB2D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB2E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB2F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB30",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB31",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB32",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB33",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB34",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB35",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB36",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB37",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB38",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB39",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB3A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB3B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB3C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB3D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB3E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB3F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB40",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB41",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB42",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB43",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB44",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB45",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB46",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB47",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB48",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB49",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB4A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB4B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB4C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB4D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB4E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB4F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20596.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20716.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20596.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20596.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB50",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB51",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB52",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB53",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB54",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB55",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB56",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB57",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB58",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB59",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB5A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB5B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB5C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB5D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB5E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB5F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB60",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB61",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB62",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB63",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB64",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB65",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB66",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB67",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB68",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB69",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB6A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB6B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB6C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB6D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB6E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB6F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB70",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB71",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB72",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB73",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB74",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB75",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB76",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB77",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB78",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB79",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB7A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB7B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB7C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB7D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB7E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB7F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB80",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB81",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB82",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23236.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23356.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23356.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23236.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB83",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB84",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB85",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB86",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB87",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB88",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB89",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB8A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB8B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB8C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB8D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB8E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB8F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB90",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB91",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB92",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB93",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB94",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB95",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB96",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB97",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB98",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB99",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "272.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "392.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "392.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "392.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "392.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB9A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB9B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB9C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB9D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB9E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BB9F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBA0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBA1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBA2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBA3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBA4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBA5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBA6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBA7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBA8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBA9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBAA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBAB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBAC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBAD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBAE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBAF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBB0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBB1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBB2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBB3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBB4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBB5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBB6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBB7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBB8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBB9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBBA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBBB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBBC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBBD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBBE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBBF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBC0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBC1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBC2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBC3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBC4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBC5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBC6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBC7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBC8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBC9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBCA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBCB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBCC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBCD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBCE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBCF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15676.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15796.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15676.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15796.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15676.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBD0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBD1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBD2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBD3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBD4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBD5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBD6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBD7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBD8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBD9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBDA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBDB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBDC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBDD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBDE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBDF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBE0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBE1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBE2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBE3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBE4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBE5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBE6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16276.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16396.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16276.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16276.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBE7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBE8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16036.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16156.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16036.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16156.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16036.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBE9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBEA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBEB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBEC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBED",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBEE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBEF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBF0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBF1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBF2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBF3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBF4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBF5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBF6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBF7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBF8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBF9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBFA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16636.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16756.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16636.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16636.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBFB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBFC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16756.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16876.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16756.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16876.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16756.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBFD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBFE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BBFF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC00",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC01",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC02",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC03",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC04",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC05",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC06",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC07",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC08",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC09",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC0A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16996.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17116.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16996.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16996.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC0B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC0C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC0D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC0E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC0F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC10",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC11",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC12",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC13",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC14",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC15",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC16",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC17",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC18",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC19",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC1A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17356.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17476.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17356.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17356.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC1B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC1C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC1D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC1E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC1F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC20",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC21",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC22",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17476.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17596.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17476.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17596.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17476.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC23",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC24",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC25",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC26",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC27",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC28",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC29",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC2A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC2B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC2C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17716.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17836.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17716.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17716.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC2D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC2E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC2F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC30",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17836.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17956.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17836.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17956.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17836.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC31",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC32",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC33",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC34",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC35",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC36",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC37",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC38",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC39",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18076.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18196.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18076.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18076.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC3A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC3B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC3C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC3D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC3E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC3F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC40",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC41",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC42",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC43",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC44",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC45",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18436.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18556.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18436.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18436.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC46",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC47",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC48",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18196.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18316.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18196.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18316.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18196.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC49",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC4A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC4B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC4C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC4D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC4E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC4F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC50",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC51",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC52",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC53",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC54",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18796.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18916.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18796.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18796.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC55",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC56",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC57",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC58",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC59",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC5A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC5B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC5C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC5D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC5E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC5F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC60",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC61",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC62",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC63",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC64",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19156.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19276.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19156.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19156.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC65",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC66",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC67",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC68",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18916.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19036.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18916.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19036.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18916.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC69",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC6A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC6B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC6C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC6D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC6E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC6F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC70",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC71",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC72",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC73",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC74",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC75",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC76",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC77",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC78",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC79",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC7A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC7B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC7C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC7D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC7E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC7F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC80",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC81",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC82",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19516.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19636.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19516.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19516.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC83",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC84",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC85",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC86",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC87",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC88",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC89",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC8A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC8B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC8C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC8D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC8E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC8F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC90",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC91",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC92",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC93",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC94",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC95",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC96",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC97",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19636.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19756.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19636.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19756.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19636.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC98",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC99",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC9A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC9B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC9C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC9D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC9E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BC9F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCA0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCA1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCA2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCA3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCA4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCA5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCA6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCA7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCA8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCA9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCAA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCAB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCAC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCAD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19876.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19996.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19876.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19876.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCAE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCAF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19996.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20116.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19996.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20116.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19996.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCB0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCB1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCB2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCB3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCB4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCB5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCB6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCB7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCB8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCB9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCBA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCBB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCBC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCBD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCBE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCBF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCC0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCC1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCC2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCC3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCC4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCC5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCC6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCC7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCC8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-1047.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-927.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-927.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-927.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCC9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCCA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCCB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCCC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCCD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCCE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCCF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20236.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20356.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20236.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20236.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCD0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCD1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20356.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20476.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20356.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20476.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20356.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCD2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCD3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCD4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCD5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCD6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCD7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCD8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCD9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCDA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCDB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCDC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCDD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCDE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCDF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCE0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCE1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCE2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20956.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21076.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20956.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20956.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCE3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCE4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCE5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCE6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCE7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCE8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCE9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCEA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCEB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCEC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCED",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCEE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCEF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCF0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCF1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCF2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCF3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCF4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCF5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCF6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCF7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCF8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCF9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCFA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCFB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCFC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCFD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCFE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BCFF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD00",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD01",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD02",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD03",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD04",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD05",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD06",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD07",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD08",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD09",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD0A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21076.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21196.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21076.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21196.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21076.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD0B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD0C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21316.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21436.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21316.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21316.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD0D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD0E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD0F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD10",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD11",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD12",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD13",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD14",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD15",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD16",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD17",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD18",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD19",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD1A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD1B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD1C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD1D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-927.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-807.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-927.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-807.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-807.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD1E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-807.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-687.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-807.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-687.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-687.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD1F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD20",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD21",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD22",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21676.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21796.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21676.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21676.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD23",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD24",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21796.6",
                "y": "-447.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21916.6",
                "y": "-327.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21796.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21916.6",
                    "y": "-327.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21796.6",
                "y": "-327.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD25",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-327.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-207.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-327.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-207.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-207.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD26",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21436.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21556.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21436.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "21556.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "21436.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD27",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD28",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD29",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD2A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD2B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD2C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD2D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD2E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD2F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD30",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD31",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD32",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD33",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD34",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD35",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD36",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD37",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2847.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2727.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-2727.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD38",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-2727.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-2607.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2727.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-2607.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD39",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2607.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2487.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2607.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-2487.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD3A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22156.6",
                "y": "-2487.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22276.6",
                "y": "-2367.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2487.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22276.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22156.6",
                "y": "-2367.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD3B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2367.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-2247.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2367.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-2247.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD3C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD3D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1887.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-1767.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-1767.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD3E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD3F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD40",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD41",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "32.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "152.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "152.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "152.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD42",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22396.6",
                "y": "152.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22516.6",
                "y": "272.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "272.222",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22396.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "152.222",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "272.222",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22396.6",
                "y": "272.222",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD43",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD44",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD45",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD46",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD47",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD48",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD49",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD4A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22516.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22636.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22516.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22636.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22516.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD4B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD4C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD4D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-1767.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-1647.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1767.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-1647.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD4E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-1647.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-1527.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1647.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-1527.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD4F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD50",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD51",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD52",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-567.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-447.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-447.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-447.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-447.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD53",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-3447.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-3327.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3447.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-3327.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD54",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-3327.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-3207.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3327.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-3207.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD55",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-3207.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-3087.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-3207.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-3087.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD56",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD57",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2127.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-2007.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-2007.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD58",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-2007.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1887.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-2007.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1887.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-1887.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD59",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1287.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1167.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-1167.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD5A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23116.6",
                "y": "-1167.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23236.6",
                "y": "-1047.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23116.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1167.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23236.6",
                    "y": "-1047.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23116.6",
                "y": "-1047.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD5B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD5C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22876.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22996.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22996.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22876.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD5D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD5E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD5F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD60",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23476.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23596.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23476.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23476.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD61",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD62",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD63",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD64",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23596.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23716.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23596.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23716.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23596.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD65",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD66",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD67",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD68",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23836.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23956.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23836.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23836.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD69",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD6A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD6B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD6C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23956.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24076.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "23956.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24076.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "23956.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD6D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD6E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD6F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD70",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD71",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD72",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD73",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD74",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD75",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD76",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24316.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24436.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24436.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24316.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD77",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD78",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD79",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD7A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24556.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24676.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24556.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24556.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD7B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD7C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD7D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD7E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24676.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24796.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24676.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24796.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24676.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD7F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD80",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD81",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD82",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD83",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-207.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-87.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-207.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-87.7779",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD84",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-87.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "32.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "32.2221",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-87.7779",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "32.2221",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "32.2221",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD85",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD86",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD87",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD88",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25036.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25156.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25156.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25036.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD89",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD8A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD8B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD8C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25276.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25396.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25276.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25276.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD8D",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD8E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD8F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD90",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25396.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25516.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25396.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25516.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25396.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD91",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD92",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD93",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD94",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25636.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25756.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25636.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25636.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD95",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD96",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD97",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD98",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25756.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25876.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25756.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25876.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25756.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD99",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD9A",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD9B",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD9C",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25996.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26116.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25996.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "25996.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD9E",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-1527.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-1407.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1527.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-1407.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BD9F",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-1407.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-1287.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1407.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-1287.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-1287.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDA0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26116.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26236.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26116.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "26236.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "26116.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDA1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDA2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16396.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16516.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16396.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "16516.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "16396.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDA3",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDA4",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17116.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17236.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17116.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "17236.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "17116.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDA5",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDA6",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18556.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18676.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18556.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "18676.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "18556.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDA7",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDA8",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19276.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19396.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19276.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "19396.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "19276.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDA9",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDAA",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22036.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22156.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22036.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22156.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22036.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDAB",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDAC",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22756.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22876.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22756.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "22876.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "22756.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDAD",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDAE",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24196.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24316.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24196.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24316.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24196.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDAF",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-3087.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-2967.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-3087.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-2967.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDB0",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24916.6",
                "y": "-2967.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25036.6",
                "y": "-2847.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "24916.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2967.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "25036.6",
                    "y": "-2847.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "24916.6",
                "y": "-2847.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDB1",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Polyline",
        "Handle": "103BDB2",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20716.6",
                "y": "1292.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20836.6",
                "y": "1412.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1412.22",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20716.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "1292.22",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "20836.6",
                    "y": "1412.22",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "20716.6",
                "y": "1412.22",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Text",
        "Handle": "103BDB3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1778",
        "Text_Position": {
            "x": "20735.4",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDB4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "246",
        "Text_Position": {
            "x": "15358.7",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDB5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25033.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2809",
        "Text_Position": {
            "x": "24924.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDB6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2807",
        "Text_Position": {
            "x": "24924.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDB7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24313.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2609",
        "Text_Position": {
            "x": "24204.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDB8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2607",
        "Text_Position": {
            "x": "24204.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDB9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22873.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2209",
        "Text_Position": {
            "x": "22764.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDBA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2207",
        "Text_Position": {
            "x": "22764.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDBB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22153.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2009",
        "Text_Position": {
            "x": "22044.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDBC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2007",
        "Text_Position": {
            "x": "22044.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDBD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1308",
        "Text_Position": {
            "x": "19295.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDBE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1306",
        "Text_Position": {
            "x": "19295.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDBF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1108",
        "Text_Position": {
            "x": "18575.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDC0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1106",
        "Text_Position": {
            "x": "18575.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDC1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "708",
        "Text_Position": {
            "x": "17158.7",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDC2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "706",
        "Text_Position": {
            "x": "17158.7",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDC3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "508",
        "Text_Position": {
            "x": "16438.7",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDC4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "506",
        "Text_Position": {
            "x": "16438.7",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDC5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26245.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3246",
        "Text_Position": {
            "x": "26135.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDC6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26246.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3234",
        "Text_Position": {
            "x": "26135.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDC7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26245.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3232",
        "Text_Position": {
            "x": "26135.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDC9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26114.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3147",
        "Text_Position": {
            "x": "26004.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDCA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26114.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3135",
        "Text_Position": {
            "x": "26004.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDCB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26114.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3133",
        "Text_Position": {
            "x": "26004.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDCC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26106",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3121",
        "Text_Position": {
            "x": "26004.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDCD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3146",
        "Text_Position": {
            "x": "25775.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDCE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25886.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3134",
        "Text_Position": {
            "x": "25775.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDCF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3132",
        "Text_Position": {
            "x": "25775.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDD0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3120",
        "Text_Position": {
            "x": "25775.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDD1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3047",
        "Text_Position": {
            "x": "25644.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDD2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3035",
        "Text_Position": {
            "x": "25644.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDD3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3033",
        "Text_Position": {
            "x": "25644.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDD4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25746",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3021",
        "Text_Position": {
            "x": "25644.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDD5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3046",
        "Text_Position": {
            "x": "25415.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDD6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25526.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3034",
        "Text_Position": {
            "x": "25415.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDD7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3032",
        "Text_Position": {
            "x": "25415.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDD8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3020",
        "Text_Position": {
            "x": "25415.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDD9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2947",
        "Text_Position": {
            "x": "25284.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDDA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2935",
        "Text_Position": {
            "x": "25284.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDDB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2933",
        "Text_Position": {
            "x": "25284.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDDC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25386",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2921",
        "Text_Position": {
            "x": "25284.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDDD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2946",
        "Text_Position": {
            "x": "25055.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDDE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25166.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2934",
        "Text_Position": {
            "x": "25055.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDDF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2932",
        "Text_Position": {
            "x": "25055.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDE0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2920",
        "Text_Position": {
            "x": "25055.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDE1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2857",
        "Text_Position": {
            "x": "24924.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDE2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2855",
        "Text_Position": {
            "x": "24924.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDE3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2847",
        "Text_Position": {
            "x": "24924.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDE4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2835",
        "Text_Position": {
            "x": "24924.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDE5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2833",
        "Text_Position": {
            "x": "24924.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDE6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25026",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2821",
        "Text_Position": {
            "x": "24924.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDE7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2846",
        "Text_Position": {
            "x": "24695.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDE8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24806.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2834",
        "Text_Position": {
            "x": "24695.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDE9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2832",
        "Text_Position": {
            "x": "24695.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDEA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2820",
        "Text_Position": {
            "x": "24695.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDEB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2747",
        "Text_Position": {
            "x": "24564.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDEC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2735",
        "Text_Position": {
            "x": "24564.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDED",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2733",
        "Text_Position": {
            "x": "24564.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDEE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24666",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2721",
        "Text_Position": {
            "x": "24564.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDEF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2746",
        "Text_Position": {
            "x": "24335.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDF0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24446.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2734",
        "Text_Position": {
            "x": "24335.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDF1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2732",
        "Text_Position": {
            "x": "24335.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDF2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2720",
        "Text_Position": {
            "x": "24335.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDF3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2657",
        "Text_Position": {
            "x": "24204.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDF4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2655",
        "Text_Position": {
            "x": "24204.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDF5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2647",
        "Text_Position": {
            "x": "24204.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDF6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2635",
        "Text_Position": {
            "x": "24204.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDF7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2633",
        "Text_Position": {
            "x": "24204.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDF8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24306",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2621",
        "Text_Position": {
            "x": "24204.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDF9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2646",
        "Text_Position": {
            "x": "23975.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDFA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24086.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2634",
        "Text_Position": {
            "x": "23975.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDFB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2632",
        "Text_Position": {
            "x": "23975.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDFC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2620",
        "Text_Position": {
            "x": "23975.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDFD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2547",
        "Text_Position": {
            "x": "23844.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDFE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2535",
        "Text_Position": {
            "x": "23844.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BDFF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2533",
        "Text_Position": {
            "x": "23844.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE00",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23946",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2521",
        "Text_Position": {
            "x": "23844.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE01",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2546",
        "Text_Position": {
            "x": "23615.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE02",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23726.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2534",
        "Text_Position": {
            "x": "23615.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE03",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2532",
        "Text_Position": {
            "x": "23615.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE04",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2520",
        "Text_Position": {
            "x": "23615.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE05",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2447",
        "Text_Position": {
            "x": "23484.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE06",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2435",
        "Text_Position": {
            "x": "23484.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE07",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2433",
        "Text_Position": {
            "x": "23484.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE08",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23586",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2421",
        "Text_Position": {
            "x": "23484.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE09",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26246.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3244",
        "Text_Position": {
            "x": "26135.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE0A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26245.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3242",
        "Text_Position": {
            "x": "26135.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE0B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26245.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3240",
        "Text_Position": {
            "x": "26135.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE0C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26245.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3238",
        "Text_Position": {
            "x": "26135.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE0D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26245.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3236",
        "Text_Position": {
            "x": "26135.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE0E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26245.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3230",
        "Text_Position": {
            "x": "26135.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE0F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26245.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3228",
        "Text_Position": {
            "x": "26135.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE10",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26245.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3226",
        "Text_Position": {
            "x": "26135.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE11",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26246.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3224",
        "Text_Position": {
            "x": "26135.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE12",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26139.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26245.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3222",
        "Text_Position": {
            "x": "26135.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "26228.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE13",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3152",
        "Text_Position": {
            "x": "25775.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE14",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26106",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3151",
        "Text_Position": {
            "x": "26004.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE15",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3150",
        "Text_Position": {
            "x": "25775.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE16",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26113.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3149",
        "Text_Position": {
            "x": "26004.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE17",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3148",
        "Text_Position": {
            "x": "25775.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE18",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26114.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3145",
        "Text_Position": {
            "x": "26004.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE19",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25886.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3144",
        "Text_Position": {
            "x": "25775.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE1A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26114.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3143",
        "Text_Position": {
            "x": "26004.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE1B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3142",
        "Text_Position": {
            "x": "25775.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE1C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26106",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3141",
        "Text_Position": {
            "x": "26004.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE1D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3140",
        "Text_Position": {
            "x": "25775.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE1E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26113.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3139",
        "Text_Position": {
            "x": "26004.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE1F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3138",
        "Text_Position": {
            "x": "25775.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE20",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26114.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3137",
        "Text_Position": {
            "x": "26004.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE21",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3136",
        "Text_Position": {
            "x": "25775.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE22",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26106",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3131",
        "Text_Position": {
            "x": "26004.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE23",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3130",
        "Text_Position": {
            "x": "25775.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE24",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26113.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3129",
        "Text_Position": {
            "x": "26004.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE25",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3128",
        "Text_Position": {
            "x": "25775.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE26",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26114.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3127",
        "Text_Position": {
            "x": "26004.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE27",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3126",
        "Text_Position": {
            "x": "25775.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE28",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26114.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3125",
        "Text_Position": {
            "x": "26004.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE29",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25886.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3124",
        "Text_Position": {
            "x": "25775.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE2A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "26008.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "26114.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3123",
        "Text_Position": {
            "x": "26004.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE2B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3122",
        "Text_Position": {
            "x": "25775.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE2C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3118",
        "Text_Position": {
            "x": "25775.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE2D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25779.6",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25885.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3116",
        "Text_Position": {
            "x": "25775.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25868.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE2E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3058",
        "Text_Position": {
            "x": "25415.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE2F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3056",
        "Text_Position": {
            "x": "25415.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE30",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3055",
        "Text_Position": {
            "x": "25644.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE31",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25526.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3054",
        "Text_Position": {
            "x": "25415.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE32",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3053",
        "Text_Position": {
            "x": "25644.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE33",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3052",
        "Text_Position": {
            "x": "25415.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE34",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25746",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3051",
        "Text_Position": {
            "x": "25644.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE35",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3050",
        "Text_Position": {
            "x": "25415.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE36",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25753.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3049",
        "Text_Position": {
            "x": "25644.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE37",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3048",
        "Text_Position": {
            "x": "25415.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE38",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3045",
        "Text_Position": {
            "x": "25644.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE39",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25526.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3044",
        "Text_Position": {
            "x": "25415.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE3A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3043",
        "Text_Position": {
            "x": "25644.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE3B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3042",
        "Text_Position": {
            "x": "25415.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE3C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25746",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3041",
        "Text_Position": {
            "x": "25644.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE3D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3040",
        "Text_Position": {
            "x": "25415.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE3E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25753.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3039",
        "Text_Position": {
            "x": "25644.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE3F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3038",
        "Text_Position": {
            "x": "25415.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE40",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3037",
        "Text_Position": {
            "x": "25644.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE41",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3036",
        "Text_Position": {
            "x": "25415.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE42",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25746",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3031",
        "Text_Position": {
            "x": "25644.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE43",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3030",
        "Text_Position": {
            "x": "25415.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE44",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25753.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3029",
        "Text_Position": {
            "x": "25644.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE45",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3028",
        "Text_Position": {
            "x": "25415.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE46",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3027",
        "Text_Position": {
            "x": "25644.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE47",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3026",
        "Text_Position": {
            "x": "25415.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE48",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3025",
        "Text_Position": {
            "x": "25644.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE49",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25526.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3024",
        "Text_Position": {
            "x": "25415.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE4A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3023",
        "Text_Position": {
            "x": "25644.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE4B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3022",
        "Text_Position": {
            "x": "25415.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE4C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25753.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3019",
        "Text_Position": {
            "x": "25644.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE4D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3018",
        "Text_Position": {
            "x": "25415.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE4E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3017",
        "Text_Position": {
            "x": "25644.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE4F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3016",
        "Text_Position": {
            "x": "25415.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE50",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25648.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25754.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3015",
        "Text_Position": {
            "x": "25644.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE51",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25526.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3014",
        "Text_Position": {
            "x": "25415.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE52",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3012",
        "Text_Position": {
            "x": "25415.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE53",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25419.6",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25525.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "3010",
        "Text_Position": {
            "x": "25415.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25508.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE54",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2960",
        "Text_Position": {
            "x": "25055.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE55",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25393.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2959",
        "Text_Position": {
            "x": "25284.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE56",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2958",
        "Text_Position": {
            "x": "25055.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE57",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2957",
        "Text_Position": {
            "x": "25284.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE58",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2956",
        "Text_Position": {
            "x": "25055.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE59",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2955",
        "Text_Position": {
            "x": "25284.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE5A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25166.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2954",
        "Text_Position": {
            "x": "25055.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE5B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2953",
        "Text_Position": {
            "x": "25284.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE5C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2952",
        "Text_Position": {
            "x": "25055.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE5D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25386",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2951",
        "Text_Position": {
            "x": "25284.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE5E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2950",
        "Text_Position": {
            "x": "25055.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE5F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25393.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2949",
        "Text_Position": {
            "x": "25284.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE60",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2948",
        "Text_Position": {
            "x": "25055.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE61",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2945",
        "Text_Position": {
            "x": "25284.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE62",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25166.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2944",
        "Text_Position": {
            "x": "25055.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE63",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2943",
        "Text_Position": {
            "x": "25284.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE64",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2942",
        "Text_Position": {
            "x": "25055.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE65",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25386",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2941",
        "Text_Position": {
            "x": "25284.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE66",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2940",
        "Text_Position": {
            "x": "25055.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE67",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25393.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2939",
        "Text_Position": {
            "x": "25284.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE68",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2938",
        "Text_Position": {
            "x": "25055.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE69",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2937",
        "Text_Position": {
            "x": "25284.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE6A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2936",
        "Text_Position": {
            "x": "25055.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE6B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25386",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2931",
        "Text_Position": {
            "x": "25284.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE6C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2930",
        "Text_Position": {
            "x": "25055.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE6D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25393.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2929",
        "Text_Position": {
            "x": "25284.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE6E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2928",
        "Text_Position": {
            "x": "25055.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE6F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2927",
        "Text_Position": {
            "x": "25284.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE70",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2926",
        "Text_Position": {
            "x": "25055.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE71",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2925",
        "Text_Position": {
            "x": "25284.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE72",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25166.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2924",
        "Text_Position": {
            "x": "25055.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE73",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2923",
        "Text_Position": {
            "x": "25284.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE74",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2922",
        "Text_Position": {
            "x": "25055.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE75",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25393.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2919",
        "Text_Position": {
            "x": "25284.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE76",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2918",
        "Text_Position": {
            "x": "25055.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE77",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2917",
        "Text_Position": {
            "x": "25284.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE78",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2916",
        "Text_Position": {
            "x": "25055.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE79",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2915",
        "Text_Position": {
            "x": "25284.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE7A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25166.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2914",
        "Text_Position": {
            "x": "25055.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE7B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25394.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2913",
        "Text_Position": {
            "x": "25284.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE7C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2912",
        "Text_Position": {
            "x": "25055.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE7D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25386",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2911",
        "Text_Position": {
            "x": "25284.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE7E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2910",
        "Text_Position": {
            "x": "25055.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE7F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25288.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25393.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2909",
        "Text_Position": {
            "x": "25284.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE80",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2908",
        "Text_Position": {
            "x": "25055.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE81",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25165.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2906",
        "Text_Position": {
            "x": "25055.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE82",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "25059.6",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25166.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2904",
        "Text_Position": {
            "x": "25055.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "25148.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE83",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24819.8",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24925.5",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2867",
        "Text_Position": {
            "x": "24815.5",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24908.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE84",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.8",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.5",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2866",
        "Text_Position": {
            "x": "24695.5",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE85",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2863",
        "Text_Position": {
            "x": "24924.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE86",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2862",
        "Text_Position": {
            "x": "24695.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE87",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25026",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2861",
        "Text_Position": {
            "x": "24924.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE88",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2860",
        "Text_Position": {
            "x": "24695.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE89",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25033.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2859",
        "Text_Position": {
            "x": "24924.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE8A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2858",
        "Text_Position": {
            "x": "24695.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE8B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2856",
        "Text_Position": {
            "x": "24695.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE8C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24806.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2854",
        "Text_Position": {
            "x": "24695.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE8D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2853",
        "Text_Position": {
            "x": "24924.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE8E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2852",
        "Text_Position": {
            "x": "24695.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE8F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25026",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2851",
        "Text_Position": {
            "x": "24924.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE90",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2850",
        "Text_Position": {
            "x": "24695.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE91",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25033.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2849",
        "Text_Position": {
            "x": "24924.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE92",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2848",
        "Text_Position": {
            "x": "24695.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE93",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2845",
        "Text_Position": {
            "x": "24924.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE94",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24806.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2844",
        "Text_Position": {
            "x": "24695.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE95",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2843",
        "Text_Position": {
            "x": "24924.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE96",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2842",
        "Text_Position": {
            "x": "24695.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE97",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25026",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2841",
        "Text_Position": {
            "x": "24924.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE98",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2840",
        "Text_Position": {
            "x": "24695.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE99",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25033.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2839",
        "Text_Position": {
            "x": "24924.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE9A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2838",
        "Text_Position": {
            "x": "24695.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE9B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2837",
        "Text_Position": {
            "x": "24924.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE9C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2836",
        "Text_Position": {
            "x": "24695.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE9D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25026",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2831",
        "Text_Position": {
            "x": "24924.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE9E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2830",
        "Text_Position": {
            "x": "24695.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BE9F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25033.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2829",
        "Text_Position": {
            "x": "24924.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEA0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2828",
        "Text_Position": {
            "x": "24695.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEA1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2827",
        "Text_Position": {
            "x": "24924.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEA2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2826",
        "Text_Position": {
            "x": "24695.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEA3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2825",
        "Text_Position": {
            "x": "24924.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEA4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24806.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2824",
        "Text_Position": {
            "x": "24695.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEA5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2823",
        "Text_Position": {
            "x": "24924.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEA6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2822",
        "Text_Position": {
            "x": "24695.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEA7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25033.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2819",
        "Text_Position": {
            "x": "24924.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEA8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2818",
        "Text_Position": {
            "x": "24695.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEA9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2817",
        "Text_Position": {
            "x": "24924.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEAA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2816",
        "Text_Position": {
            "x": "24695.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEAB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2815",
        "Text_Position": {
            "x": "24924.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEAC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24806.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2814",
        "Text_Position": {
            "x": "24695.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEAD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2813",
        "Text_Position": {
            "x": "24924.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEAE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2812",
        "Text_Position": {
            "x": "24695.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEAF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25026",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2811",
        "Text_Position": {
            "x": "24924.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEB0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2810",
        "Text_Position": {
            "x": "24695.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEB1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2808",
        "Text_Position": {
            "x": "24695.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEB2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2806",
        "Text_Position": {
            "x": "24695.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEB3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2805",
        "Text_Position": {
            "x": "24924.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEB4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24806.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2804",
        "Text_Position": {
            "x": "24695.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEB5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24928.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "25034.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2803",
        "Text_Position": {
            "x": "24924.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEB6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2802",
        "Text_Position": {
            "x": "24695.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEB7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24699.6",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24805.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2800",
        "Text_Position": {
            "x": "24695.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24788.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEB8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24569",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.7",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2767",
        "Text_Position": {
            "x": "24564.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEB9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2763",
        "Text_Position": {
            "x": "24564.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEBA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2762",
        "Text_Position": {
            "x": "24335.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEBB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24666",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2761",
        "Text_Position": {
            "x": "24564.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEBC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2760",
        "Text_Position": {
            "x": "24335.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEBD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24673.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2759",
        "Text_Position": {
            "x": "24564.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEBE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2758",
        "Text_Position": {
            "x": "24335.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEBF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2757",
        "Text_Position": {
            "x": "24564.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEC0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2756",
        "Text_Position": {
            "x": "24335.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEC1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2755",
        "Text_Position": {
            "x": "24564.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEC2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24446.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2754",
        "Text_Position": {
            "x": "24335.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEC3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2753",
        "Text_Position": {
            "x": "24564.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEC4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2752",
        "Text_Position": {
            "x": "24335.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEC5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24666",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2751",
        "Text_Position": {
            "x": "24564.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEC6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2750",
        "Text_Position": {
            "x": "24335.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEC7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24673.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2749",
        "Text_Position": {
            "x": "24564.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEC8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2748",
        "Text_Position": {
            "x": "24335.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEC9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2745",
        "Text_Position": {
            "x": "24564.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BECA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24446.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2744",
        "Text_Position": {
            "x": "24335.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BECB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2743",
        "Text_Position": {
            "x": "24564.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BECC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2742",
        "Text_Position": {
            "x": "24335.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BECD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24666",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2741",
        "Text_Position": {
            "x": "24564.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BECE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2740",
        "Text_Position": {
            "x": "24335.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BECF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24673.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2739",
        "Text_Position": {
            "x": "24564.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BED0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2738",
        "Text_Position": {
            "x": "24335.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BED1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2737",
        "Text_Position": {
            "x": "24564.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BED2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2736",
        "Text_Position": {
            "x": "24335.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BED3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24666",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2731",
        "Text_Position": {
            "x": "24564.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BED4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2730",
        "Text_Position": {
            "x": "24335.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BED5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24673.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2729",
        "Text_Position": {
            "x": "24564.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BED6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2728",
        "Text_Position": {
            "x": "24335.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BED7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2727",
        "Text_Position": {
            "x": "24564.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BED8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2726",
        "Text_Position": {
            "x": "24335.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BED9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2725",
        "Text_Position": {
            "x": "24564.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEDA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24446.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2724",
        "Text_Position": {
            "x": "24335.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEDB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2723",
        "Text_Position": {
            "x": "24564.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEDC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2722",
        "Text_Position": {
            "x": "24335.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEDD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24673.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2719",
        "Text_Position": {
            "x": "24564.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEDE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2718",
        "Text_Position": {
            "x": "24335.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEDF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2717",
        "Text_Position": {
            "x": "24564.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEE0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2716",
        "Text_Position": {
            "x": "24335.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEE1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2715",
        "Text_Position": {
            "x": "24564.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEE2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24446.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2714",
        "Text_Position": {
            "x": "24335.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEE3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2713",
        "Text_Position": {
            "x": "24564.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEE4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2712",
        "Text_Position": {
            "x": "24335.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEE5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24666",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2711",
        "Text_Position": {
            "x": "24564.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEE6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2710",
        "Text_Position": {
            "x": "24335.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEE7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24673.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2709",
        "Text_Position": {
            "x": "24564.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEE8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2708",
        "Text_Position": {
            "x": "24335.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEE9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2707",
        "Text_Position": {
            "x": "24564.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEEA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2706",
        "Text_Position": {
            "x": "24335.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEEB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2705",
        "Text_Position": {
            "x": "24564.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEEC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24446.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2704",
        "Text_Position": {
            "x": "24335.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEED",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24674.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2703",
        "Text_Position": {
            "x": "24564.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEEE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2702",
        "Text_Position": {
            "x": "24335.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEEF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24568.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24666",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2701",
        "Text_Position": {
            "x": "24564.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEF0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24339.6",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24445.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2700",
        "Text_Position": {
            "x": "24335.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24428.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEF1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24099.8",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24205.5",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2668",
        "Text_Position": {
            "x": "24095.5",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24188.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEF2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24219.8",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24325.5",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2667",
        "Text_Position": {
            "x": "24215.5",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24308.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEF3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.8",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.5",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2666",
        "Text_Position": {
            "x": "23975.5",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEF4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2663",
        "Text_Position": {
            "x": "24204.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEF5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2662",
        "Text_Position": {
            "x": "23975.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEF6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24306",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2661",
        "Text_Position": {
            "x": "24204.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEF7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2660",
        "Text_Position": {
            "x": "23975.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEF8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24313.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2659",
        "Text_Position": {
            "x": "24204.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEF9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2658",
        "Text_Position": {
            "x": "23975.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEFA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2656",
        "Text_Position": {
            "x": "23975.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEFB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24086.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2654",
        "Text_Position": {
            "x": "23975.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEFC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2653",
        "Text_Position": {
            "x": "24204.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEFD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2652",
        "Text_Position": {
            "x": "23975.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEFE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24306",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2651",
        "Text_Position": {
            "x": "24204.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BEFF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2650",
        "Text_Position": {
            "x": "23975.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF00",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24313.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2649",
        "Text_Position": {
            "x": "24204.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF01",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2648",
        "Text_Position": {
            "x": "23975.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF02",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2645",
        "Text_Position": {
            "x": "24204.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF03",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24086.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2644",
        "Text_Position": {
            "x": "23975.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF04",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2643",
        "Text_Position": {
            "x": "24204.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF05",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2642",
        "Text_Position": {
            "x": "23975.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF06",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24306",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2641",
        "Text_Position": {
            "x": "24204.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF07",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2640",
        "Text_Position": {
            "x": "23975.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF08",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24313.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2639",
        "Text_Position": {
            "x": "24204.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF09",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2638",
        "Text_Position": {
            "x": "23975.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF0A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2637",
        "Text_Position": {
            "x": "24204.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF0B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2636",
        "Text_Position": {
            "x": "23975.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF0C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24306",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2631",
        "Text_Position": {
            "x": "24204.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF0D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2630",
        "Text_Position": {
            "x": "23975.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF0E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24313.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2629",
        "Text_Position": {
            "x": "24204.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF0F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2628",
        "Text_Position": {
            "x": "23975.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF10",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2627",
        "Text_Position": {
            "x": "24204.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF11",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2626",
        "Text_Position": {
            "x": "23975.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF12",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2625",
        "Text_Position": {
            "x": "24204.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF13",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24086.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2624",
        "Text_Position": {
            "x": "23975.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF14",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2623",
        "Text_Position": {
            "x": "24204.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF15",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2622",
        "Text_Position": {
            "x": "23975.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF16",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24313.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2619",
        "Text_Position": {
            "x": "24204.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF17",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2618",
        "Text_Position": {
            "x": "23975.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF18",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2617",
        "Text_Position": {
            "x": "24204.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF19",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2616",
        "Text_Position": {
            "x": "23975.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF1A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2615",
        "Text_Position": {
            "x": "24204.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF1B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24086.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2614",
        "Text_Position": {
            "x": "23975.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF1C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2613",
        "Text_Position": {
            "x": "24204.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF1D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2612",
        "Text_Position": {
            "x": "23975.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF1E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24306",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2611",
        "Text_Position": {
            "x": "24204.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF1F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2610",
        "Text_Position": {
            "x": "23975.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF20",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2608",
        "Text_Position": {
            "x": "23975.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF21",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2606",
        "Text_Position": {
            "x": "23975.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF22",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2605",
        "Text_Position": {
            "x": "24204.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF23",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24086.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2604",
        "Text_Position": {
            "x": "23975.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF24",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24314.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2603",
        "Text_Position": {
            "x": "24204.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF25",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2602",
        "Text_Position": {
            "x": "23975.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF26",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "24208.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24306",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2601",
        "Text_Position": {
            "x": "24204.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF27",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23979.6",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "24085.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2600",
        "Text_Position": {
            "x": "23975.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "24068.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF28",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23739.8",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23845.5",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2568",
        "Text_Position": {
            "x": "23735.5",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23828.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF29",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23859.8",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23965.5",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2567",
        "Text_Position": {
            "x": "23855.5",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23948.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF2A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.8",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.5",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2566",
        "Text_Position": {
            "x": "23615.5",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF2B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2563",
        "Text_Position": {
            "x": "23844.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF2C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2562",
        "Text_Position": {
            "x": "23615.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF2D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23946",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2561",
        "Text_Position": {
            "x": "23844.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF2E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2560",
        "Text_Position": {
            "x": "23615.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF2F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23953.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2559",
        "Text_Position": {
            "x": "23844.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF30",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2558",
        "Text_Position": {
            "x": "23615.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF31",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2557",
        "Text_Position": {
            "x": "23844.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF32",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2556",
        "Text_Position": {
            "x": "23615.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF33",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2555",
        "Text_Position": {
            "x": "23844.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF34",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23726.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2554",
        "Text_Position": {
            "x": "23615.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF35",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2553",
        "Text_Position": {
            "x": "23844.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF36",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2552",
        "Text_Position": {
            "x": "23615.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF37",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23946",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2551",
        "Text_Position": {
            "x": "23844.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF38",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2550",
        "Text_Position": {
            "x": "23615.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF39",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23953.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2549",
        "Text_Position": {
            "x": "23844.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF3A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2548",
        "Text_Position": {
            "x": "23615.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF3B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2545",
        "Text_Position": {
            "x": "23844.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF3C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23726.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2544",
        "Text_Position": {
            "x": "23615.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF3D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2543",
        "Text_Position": {
            "x": "23844.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF3E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2542",
        "Text_Position": {
            "x": "23615.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF3F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23946",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2541",
        "Text_Position": {
            "x": "23844.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF40",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2540",
        "Text_Position": {
            "x": "23615.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF41",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23953.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2539",
        "Text_Position": {
            "x": "23844.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF42",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2538",
        "Text_Position": {
            "x": "23615.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF43",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2537",
        "Text_Position": {
            "x": "23844.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF44",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2536",
        "Text_Position": {
            "x": "23615.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF45",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23946",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2531",
        "Text_Position": {
            "x": "23844.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF46",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2530",
        "Text_Position": {
            "x": "23615.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF47",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23953.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2529",
        "Text_Position": {
            "x": "23844.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF48",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2528",
        "Text_Position": {
            "x": "23615.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF49",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2527",
        "Text_Position": {
            "x": "23844.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF4A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2526",
        "Text_Position": {
            "x": "23615.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF4B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2525",
        "Text_Position": {
            "x": "23844.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF4C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23726.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2524",
        "Text_Position": {
            "x": "23615.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF4D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2523",
        "Text_Position": {
            "x": "23844.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF4E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2522",
        "Text_Position": {
            "x": "23615.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF4F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23953.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2519",
        "Text_Position": {
            "x": "23844.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF50",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2518",
        "Text_Position": {
            "x": "23615.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF51",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2517",
        "Text_Position": {
            "x": "23844.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF52",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2516",
        "Text_Position": {
            "x": "23615.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF53",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2515",
        "Text_Position": {
            "x": "23844.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF54",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23726.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2514",
        "Text_Position": {
            "x": "23615.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF55",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2513",
        "Text_Position": {
            "x": "23844.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF56",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2512",
        "Text_Position": {
            "x": "23615.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF57",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23946",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2511",
        "Text_Position": {
            "x": "23844.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF58",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2510",
        "Text_Position": {
            "x": "23615.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF59",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23953.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2509",
        "Text_Position": {
            "x": "23844.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF5A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2508",
        "Text_Position": {
            "x": "23615.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF5B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2507",
        "Text_Position": {
            "x": "23844.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF5C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2506",
        "Text_Position": {
            "x": "23615.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF5D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2505",
        "Text_Position": {
            "x": "23844.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF5E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23726.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2504",
        "Text_Position": {
            "x": "23615.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF5F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23954.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2503",
        "Text_Position": {
            "x": "23844.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF60",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2502",
        "Text_Position": {
            "x": "23615.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF61",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23848.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23946",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2501",
        "Text_Position": {
            "x": "23844.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF62",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23619.6",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23725.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2500",
        "Text_Position": {
            "x": "23615.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23708.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF63",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2463",
        "Text_Position": {
            "x": "23484.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF64",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23586",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2461",
        "Text_Position": {
            "x": "23484.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF65",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23593.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2459",
        "Text_Position": {
            "x": "23484.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF66",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2457",
        "Text_Position": {
            "x": "23484.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF67",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2455",
        "Text_Position": {
            "x": "23484.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF68",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2453",
        "Text_Position": {
            "x": "23484.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF69",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23586",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2451",
        "Text_Position": {
            "x": "23484.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF6A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23593.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2449",
        "Text_Position": {
            "x": "23484.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF6B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2445",
        "Text_Position": {
            "x": "23484.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF6C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2443",
        "Text_Position": {
            "x": "23484.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF6D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23586",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2441",
        "Text_Position": {
            "x": "23484.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF6E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23593.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2439",
        "Text_Position": {
            "x": "23484.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF6F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2437",
        "Text_Position": {
            "x": "23484.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF70",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23586",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2431",
        "Text_Position": {
            "x": "23484.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF71",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23593.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2429",
        "Text_Position": {
            "x": "23484.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF72",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2427",
        "Text_Position": {
            "x": "23484.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF73",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2425",
        "Text_Position": {
            "x": "23484.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF74",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2423",
        "Text_Position": {
            "x": "23484.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF75",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23593.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2419",
        "Text_Position": {
            "x": "23484.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF76",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2417",
        "Text_Position": {
            "x": "23484.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF77",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2415",
        "Text_Position": {
            "x": "23484.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF78",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2413",
        "Text_Position": {
            "x": "23484.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF79",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23586",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2411",
        "Text_Position": {
            "x": "23484.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF7A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23593.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2409",
        "Text_Position": {
            "x": "23484.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF7B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2407",
        "Text_Position": {
            "x": "23484.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF7C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2405",
        "Text_Position": {
            "x": "23484.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF7D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23594.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2403",
        "Text_Position": {
            "x": "23484.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF7E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23488.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23586",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2401",
        "Text_Position": {
            "x": "23484.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF7F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20713.2",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1679",
        "Text_Position": {
            "x": "20604.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF80",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1683",
        "Text_Position": {
            "x": "20604.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF81",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20706",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1681",
        "Text_Position": {
            "x": "20604.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF82",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1677",
        "Text_Position": {
            "x": "20604.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF83",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1675",
        "Text_Position": {
            "x": "20604.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF84",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1673",
        "Text_Position": {
            "x": "20604.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF85",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20706",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1671",
        "Text_Position": {
            "x": "20604.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF86",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20713.2",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1669",
        "Text_Position": {
            "x": "20604.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF87",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1667",
        "Text_Position": {
            "x": "20604.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF88",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20713.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1619",
        "Text_Position": {
            "x": "20604.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF89",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1617",
        "Text_Position": {
            "x": "20604.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF8A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1615",
        "Text_Position": {
            "x": "20604.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF8B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1613",
        "Text_Position": {
            "x": "20604.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF8C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20706",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1611",
        "Text_Position": {
            "x": "20604.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF8D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20713.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1609",
        "Text_Position": {
            "x": "20604.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF8E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1607",
        "Text_Position": {
            "x": "20604.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF8F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1605",
        "Text_Position": {
            "x": "20604.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF90",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1603",
        "Text_Position": {
            "x": "20604.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF91",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20706",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1601",
        "Text_Position": {
            "x": "20604.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF92",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15286",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "123",
        "Text_Position": {
            "x": "15204.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF93",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15286",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "125",
        "Text_Position": {
            "x": "15204.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF94",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15286",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "127",
        "Text_Position": {
            "x": "15204.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF95",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15284.6",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "129",
        "Text_Position": {
            "x": "15204.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF96",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15277.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "131",
        "Text_Position": {
            "x": "15204.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF97",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15286",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "137",
        "Text_Position": {
            "x": "15204.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF98",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15284.6",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "139",
        "Text_Position": {
            "x": "15204.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF99",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15277.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "141",
        "Text_Position": {
            "x": "15204.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF9A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15286",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "143",
        "Text_Position": {
            "x": "15204.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF9B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15286",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "145",
        "Text_Position": {
            "x": "15204.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF9C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15646",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "217",
        "Text_Position": {
            "x": "15564.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF9D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15644.6",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "219",
        "Text_Position": {
            "x": "15564.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF9E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "222",
        "Text_Position": {
            "x": "15358.7",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BF9F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15646",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "223",
        "Text_Position": {
            "x": "15564.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFA0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15441.5",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "224",
        "Text_Position": {
            "x": "15358.7",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFA1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15646",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "225",
        "Text_Position": {
            "x": "15564.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFA2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "226",
        "Text_Position": {
            "x": "15358.7",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFA3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15646",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "227",
        "Text_Position": {
            "x": "15564.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFA4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "228",
        "Text_Position": {
            "x": "15358.7",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFA5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15644.6",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "229",
        "Text_Position": {
            "x": "15564.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFA6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "230",
        "Text_Position": {
            "x": "15358.7",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFA7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15637.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "231",
        "Text_Position": {
            "x": "15564.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFA8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "236",
        "Text_Position": {
            "x": "15358.7",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFA9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15646",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "237",
        "Text_Position": {
            "x": "15564.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFAA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "238",
        "Text_Position": {
            "x": "15358.7",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFAB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15644.6",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "239",
        "Text_Position": {
            "x": "15564.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFAC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "240",
        "Text_Position": {
            "x": "15358.7",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFAD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15637.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "241",
        "Text_Position": {
            "x": "15564.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFAE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "242",
        "Text_Position": {
            "x": "15358.7",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFAF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15646",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "243",
        "Text_Position": {
            "x": "15564.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFB0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15441.5",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "244",
        "Text_Position": {
            "x": "15358.7",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFB1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15646",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "245",
        "Text_Position": {
            "x": "15564.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFB2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "248",
        "Text_Position": {
            "x": "15358.7",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFB3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15644.6",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "249",
        "Text_Position": {
            "x": "15564.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFB4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "250",
        "Text_Position": {
            "x": "15358.7",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFB5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15637.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "251",
        "Text_Position": {
            "x": "15564.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFB6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15646",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "253",
        "Text_Position": {
            "x": "15564.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFB7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15997.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "311",
        "Text_Position": {
            "x": "15924.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFB8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "313",
        "Text_Position": {
            "x": "15924.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFB9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15801.5",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "314",
        "Text_Position": {
            "x": "15718.7",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFBA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "315",
        "Text_Position": {
            "x": "15924.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFBB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "316",
        "Text_Position": {
            "x": "15718.7",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFBC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "317",
        "Text_Position": {
            "x": "15924.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFBD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16004.6",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "319",
        "Text_Position": {
            "x": "15924.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFBE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "322",
        "Text_Position": {
            "x": "15718.7",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFBF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "323",
        "Text_Position": {
            "x": "15924.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFC0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15801.5",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "324",
        "Text_Position": {
            "x": "15718.7",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFC1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "325",
        "Text_Position": {
            "x": "15924.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFC2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "326",
        "Text_Position": {
            "x": "15718.7",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFC3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "327",
        "Text_Position": {
            "x": "15924.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFC4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "328",
        "Text_Position": {
            "x": "15718.7",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFC5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16004.6",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "329",
        "Text_Position": {
            "x": "15924.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFC6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "330",
        "Text_Position": {
            "x": "15718.7",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFC7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15997.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "331",
        "Text_Position": {
            "x": "15924.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFC8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "336",
        "Text_Position": {
            "x": "15718.7",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFC9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "337",
        "Text_Position": {
            "x": "15924.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFCA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "338",
        "Text_Position": {
            "x": "15718.7",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFCB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16004.6",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "339",
        "Text_Position": {
            "x": "15924.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFCC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "340",
        "Text_Position": {
            "x": "15718.7",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFCD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15997.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "341",
        "Text_Position": {
            "x": "15924.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFCE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "342",
        "Text_Position": {
            "x": "15718.7",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFCF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "343",
        "Text_Position": {
            "x": "15924.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFD0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15801.5",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "344",
        "Text_Position": {
            "x": "15718.7",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFD1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "345",
        "Text_Position": {
            "x": "15924.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFD2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "348",
        "Text_Position": {
            "x": "15718.7",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFD3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16004.6",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "349",
        "Text_Position": {
            "x": "15924.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFD4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15997.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "351",
        "Text_Position": {
            "x": "15924.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFD5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "353",
        "Text_Position": {
            "x": "15924.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFD6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "355",
        "Text_Position": {
            "x": "15924.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFD7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "357",
        "Text_Position": {
            "x": "15924.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFD8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16004.6",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "359",
        "Text_Position": {
            "x": "15924.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFD9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "405",
        "Text_Position": {
            "x": "16284.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFDA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "407",
        "Text_Position": {
            "x": "16284.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFDB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "408",
        "Text_Position": {
            "x": "16078.7",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFDC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16364.6",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "409",
        "Text_Position": {
            "x": "16284.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFDD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "410",
        "Text_Position": {
            "x": "16078.7",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFDE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "412",
        "Text_Position": {
            "x": "16078.7",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFDF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16161.5",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "414",
        "Text_Position": {
            "x": "16078.7",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFE0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "415",
        "Text_Position": {
            "x": "16284.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFE1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "417",
        "Text_Position": {
            "x": "16284.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFE2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16364.6",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "419",
        "Text_Position": {
            "x": "16284.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFE3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "422",
        "Text_Position": {
            "x": "16078.7",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFE4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "423",
        "Text_Position": {
            "x": "16284.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFE5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "425",
        "Text_Position": {
            "x": "16284.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFE6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "427",
        "Text_Position": {
            "x": "16284.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFE7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "440",
        "Text_Position": {
            "x": "16078.7",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFE8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16357.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "441",
        "Text_Position": {
            "x": "16284.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFE9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "442",
        "Text_Position": {
            "x": "16078.7",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFEA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "443",
        "Text_Position": {
            "x": "16284.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFEB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16364.6",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "449",
        "Text_Position": {
            "x": "16284.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFEC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "450",
        "Text_Position": {
            "x": "16078.7",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFED",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16357.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "451",
        "Text_Position": {
            "x": "16284.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFEE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "452",
        "Text_Position": {
            "x": "16078.7",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFEF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "453",
        "Text_Position": {
            "x": "16284.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFF0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16161.5",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "454",
        "Text_Position": {
            "x": "16078.7",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFF1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "455",
        "Text_Position": {
            "x": "16284.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFF2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "457",
        "Text_Position": {
            "x": "16284.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFF3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16364.6",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "459",
        "Text_Position": {
            "x": "16284.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFF4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16357.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "461",
        "Text_Position": {
            "x": "16284.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFF5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16717.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "501",
        "Text_Position": {
            "x": "16644.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFF6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "503",
        "Text_Position": {
            "x": "16644.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFF7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "505",
        "Text_Position": {
            "x": "16644.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFF8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "513",
        "Text_Position": {
            "x": "16644.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFF9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16521.5",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "514",
        "Text_Position": {
            "x": "16438.7",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFFA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "515",
        "Text_Position": {
            "x": "16644.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFFB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "517",
        "Text_Position": {
            "x": "16644.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFFC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16724.6",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "519",
        "Text_Position": {
            "x": "16644.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFFD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "522",
        "Text_Position": {
            "x": "16438.7",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFFE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16521.5",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "524",
        "Text_Position": {
            "x": "16438.7",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103BFFF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "526",
        "Text_Position": {
            "x": "16438.7",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C000",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "527",
        "Text_Position": {
            "x": "16644.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C001",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16724.6",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "529",
        "Text_Position": {
            "x": "16644.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C002",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "540",
        "Text_Position": {
            "x": "16438.7",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C003",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16717.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "541",
        "Text_Position": {
            "x": "16644.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C004",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "542",
        "Text_Position": {
            "x": "16438.7",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C005",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "543",
        "Text_Position": {
            "x": "16644.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C006",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16521.5",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "544",
        "Text_Position": {
            "x": "16438.7",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C007",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "545",
        "Text_Position": {
            "x": "16644.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C008",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "548",
        "Text_Position": {
            "x": "16438.7",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C009",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16724.6",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "549",
        "Text_Position": {
            "x": "16644.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C00A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "550",
        "Text_Position": {
            "x": "16438.7",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C00B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "552",
        "Text_Position": {
            "x": "16438.7",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C00C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "558",
        "Text_Position": {
            "x": "16438.7",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C00D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "560",
        "Text_Position": {
            "x": "16438.7",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C00E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "562",
        "Text_Position": {
            "x": "16438.7",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C00F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16562.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16640.1",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "566",
        "Text_Position": {
            "x": "16558.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16628.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C010",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16682.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16760.1",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "567",
        "Text_Position": {
            "x": "16678.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16748.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C011",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "600",
        "Text_Position": {
            "x": "16798.7",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C012",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17077.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "601",
        "Text_Position": {
            "x": "17004.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C013",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "602",
        "Text_Position": {
            "x": "16798.7",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C014",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "603",
        "Text_Position": {
            "x": "17004.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C015",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16881.5",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "604",
        "Text_Position": {
            "x": "16798.7",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C016",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "605",
        "Text_Position": {
            "x": "17004.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C017",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "607",
        "Text_Position": {
            "x": "17004.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C018",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17084.6",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "609",
        "Text_Position": {
            "x": "17004.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C019",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17077.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "611",
        "Text_Position": {
            "x": "17004.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C01A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "612",
        "Text_Position": {
            "x": "16798.7",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C01B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "613",
        "Text_Position": {
            "x": "17004.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C01C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16881.5",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "614",
        "Text_Position": {
            "x": "16798.7",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C01D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "615",
        "Text_Position": {
            "x": "17004.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C01E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "616",
        "Text_Position": {
            "x": "16798.7",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C01F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "618",
        "Text_Position": {
            "x": "16798.7",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C020",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "623",
        "Text_Position": {
            "x": "17004.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C021",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "626",
        "Text_Position": {
            "x": "16798.7",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C022",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "628",
        "Text_Position": {
            "x": "16798.7",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C023",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17084.6",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "629",
        "Text_Position": {
            "x": "17004.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C024",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17084.6",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "639",
        "Text_Position": {
            "x": "17004.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C025",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "640",
        "Text_Position": {
            "x": "16798.7",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C026",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17077.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "641",
        "Text_Position": {
            "x": "17004.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C027",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "642",
        "Text_Position": {
            "x": "16798.7",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C028",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "643",
        "Text_Position": {
            "x": "17004.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C029",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16881.5",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "644",
        "Text_Position": {
            "x": "16798.7",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C02A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "645",
        "Text_Position": {
            "x": "17004.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C02B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "648",
        "Text_Position": {
            "x": "16798.7",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C02C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "666",
        "Text_Position": {
            "x": "16798.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C02D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "700",
        "Text_Position": {
            "x": "17158.7",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C02E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "702",
        "Text_Position": {
            "x": "17158.7",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C02F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17241.5",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "704",
        "Text_Position": {
            "x": "17158.7",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C030",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "705",
        "Text_Position": {
            "x": "17364.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C031",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "707",
        "Text_Position": {
            "x": "17364.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C032",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17444.6",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "709",
        "Text_Position": {
            "x": "17364.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C033",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17241.5",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "714",
        "Text_Position": {
            "x": "17158.7",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C034",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "716",
        "Text_Position": {
            "x": "17158.7",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C035",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "717",
        "Text_Position": {
            "x": "17364.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C036",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "718",
        "Text_Position": {
            "x": "17158.7",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C037",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17444.6",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "719",
        "Text_Position": {
            "x": "17364.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C038",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "722",
        "Text_Position": {
            "x": "17158.7",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C039",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "723",
        "Text_Position": {
            "x": "17364.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C03A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17241.5",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "724",
        "Text_Position": {
            "x": "17158.7",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C03B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "725",
        "Text_Position": {
            "x": "17364.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C03C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "726",
        "Text_Position": {
            "x": "17158.7",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C03D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "727",
        "Text_Position": {
            "x": "17364.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C03E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "728",
        "Text_Position": {
            "x": "17158.7",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C03F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "738",
        "Text_Position": {
            "x": "17158.7",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C040",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "740",
        "Text_Position": {
            "x": "17158.7",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C041",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17437.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "741",
        "Text_Position": {
            "x": "17364.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C042",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "743",
        "Text_Position": {
            "x": "17364.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C043",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "745",
        "Text_Position": {
            "x": "17364.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C044",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17444.6",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "749",
        "Text_Position": {
            "x": "17364.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C045",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17437.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "751",
        "Text_Position": {
            "x": "17364.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C046",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "752",
        "Text_Position": {
            "x": "17158.7",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C047",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "753",
        "Text_Position": {
            "x": "17364.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C048",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "755",
        "Text_Position": {
            "x": "17364.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C049",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "757",
        "Text_Position": {
            "x": "17364.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C04A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "758",
        "Text_Position": {
            "x": "17158.7",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C04B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17444.6",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "759",
        "Text_Position": {
            "x": "17364.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C04C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "760",
        "Text_Position": {
            "x": "17158.7",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C04D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17437.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "761",
        "Text_Position": {
            "x": "17364.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C04E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "762",
        "Text_Position": {
            "x": "17158.7",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C04F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "763",
        "Text_Position": {
            "x": "17364.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C050",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "766",
        "Text_Position": {
            "x": "17158.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C051",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17402.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17480.1",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "767",
        "Text_Position": {
            "x": "17398.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17468.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C052",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17282.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17360.1",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "768",
        "Text_Position": {
            "x": "17278.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17348.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C053",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17797.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "801",
        "Text_Position": {
            "x": "17724.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C054",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "803",
        "Text_Position": {
            "x": "17724.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C055",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17601.5",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "804",
        "Text_Position": {
            "x": "17518.7",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C056",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "805",
        "Text_Position": {
            "x": "17724.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C057",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "806",
        "Text_Position": {
            "x": "17518.7",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C058",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "808",
        "Text_Position": {
            "x": "17518.7",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C059",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17797.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "811",
        "Text_Position": {
            "x": "17724.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C05A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "813",
        "Text_Position": {
            "x": "17724.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C05B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "815",
        "Text_Position": {
            "x": "17724.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C05C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "816",
        "Text_Position": {
            "x": "17518.7",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C05D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "817",
        "Text_Position": {
            "x": "17724.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C05E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "818",
        "Text_Position": {
            "x": "17518.7",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C05F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17804.6",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "819",
        "Text_Position": {
            "x": "17724.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C060",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17601.5",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "824",
        "Text_Position": {
            "x": "17518.7",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C061",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "825",
        "Text_Position": {
            "x": "17724.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C062",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "826",
        "Text_Position": {
            "x": "17518.7",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C063",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "836",
        "Text_Position": {
            "x": "17518.7",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C064",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "838",
        "Text_Position": {
            "x": "17518.7",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C065",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "840",
        "Text_Position": {
            "x": "17518.7",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C066",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17797.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "841",
        "Text_Position": {
            "x": "17724.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C067",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "842",
        "Text_Position": {
            "x": "17518.7",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C068",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "843",
        "Text_Position": {
            "x": "17724.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C069",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17601.5",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "844",
        "Text_Position": {
            "x": "17518.7",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C06A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "845",
        "Text_Position": {
            "x": "17724.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C06B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "848",
        "Text_Position": {
            "x": "17518.7",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C06C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "850",
        "Text_Position": {
            "x": "17518.7",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C06D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "852",
        "Text_Position": {
            "x": "17518.7",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C06E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17601.5",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "854",
        "Text_Position": {
            "x": "17518.7",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C06F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "856",
        "Text_Position": {
            "x": "17518.7",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C070",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "858",
        "Text_Position": {
            "x": "17518.7",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C071",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "860",
        "Text_Position": {
            "x": "17518.7",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C072",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "862",
        "Text_Position": {
            "x": "17518.7",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C073",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "863",
        "Text_Position": {
            "x": "17724.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C074",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "866",
        "Text_Position": {
            "x": "17518.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C075",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17762.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17840.1",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "867",
        "Text_Position": {
            "x": "17758.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17828.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C076",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17642.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17720.1",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "868",
        "Text_Position": {
            "x": "17638.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17708.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C077",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "900",
        "Text_Position": {
            "x": "17878.7",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C078",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18157.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "901",
        "Text_Position": {
            "x": "18084.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C079",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "903",
        "Text_Position": {
            "x": "18084.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C07A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "905",
        "Text_Position": {
            "x": "18084.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C07B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "906",
        "Text_Position": {
            "x": "17878.7",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C07C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "908",
        "Text_Position": {
            "x": "17878.7",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C07D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "910",
        "Text_Position": {
            "x": "17878.7",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C07E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18157.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "911",
        "Text_Position": {
            "x": "18084.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C07F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "912",
        "Text_Position": {
            "x": "17878.7",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C080",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "913",
        "Text_Position": {
            "x": "18084.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C081",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17961.5",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "914",
        "Text_Position": {
            "x": "17878.7",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C082",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "915",
        "Text_Position": {
            "x": "18084.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C083",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "916",
        "Text_Position": {
            "x": "17878.7",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C084",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "917",
        "Text_Position": {
            "x": "18084.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C085",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "918",
        "Text_Position": {
            "x": "17878.7",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C086",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18164.6",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "919",
        "Text_Position": {
            "x": "18084.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C087",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "922",
        "Text_Position": {
            "x": "17878.7",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C088",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17961.5",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "924",
        "Text_Position": {
            "x": "17878.7",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C089",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "927",
        "Text_Position": {
            "x": "18084.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C08A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18164.6",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "939",
        "Text_Position": {
            "x": "18084.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C08B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "940",
        "Text_Position": {
            "x": "17878.7",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C08C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18157.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "941",
        "Text_Position": {
            "x": "18084.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C08D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "942",
        "Text_Position": {
            "x": "17878.7",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C08E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "943",
        "Text_Position": {
            "x": "18084.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C08F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17961.5",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "944",
        "Text_Position": {
            "x": "17878.7",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C090",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "945",
        "Text_Position": {
            "x": "18084.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C091",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "962",
        "Text_Position": {
            "x": "17878.7",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C092",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "963",
        "Text_Position": {
            "x": "18084.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C093",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18546",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1001",
        "Text_Position": {
            "x": "18444.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C094",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1003",
        "Text_Position": {
            "x": "18444.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C095",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18326.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1004",
        "Text_Position": {
            "x": "18215.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C096",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1005",
        "Text_Position": {
            "x": "18444.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C097",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1006",
        "Text_Position": {
            "x": "18215.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C098",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1008",
        "Text_Position": {
            "x": "18215.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C099",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1010",
        "Text_Position": {
            "x": "18215.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C09A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18546",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1011",
        "Text_Position": {
            "x": "18444.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C09B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1013",
        "Text_Position": {
            "x": "18444.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C09C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1015",
        "Text_Position": {
            "x": "18444.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C09D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1016",
        "Text_Position": {
            "x": "18215.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C09E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1018",
        "Text_Position": {
            "x": "18215.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C09F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1023",
        "Text_Position": {
            "x": "18444.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0A0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1025",
        "Text_Position": {
            "x": "18444.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0A1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1026",
        "Text_Position": {
            "x": "18215.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0A2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1027",
        "Text_Position": {
            "x": "18444.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0A3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1028",
        "Text_Position": {
            "x": "18215.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0A4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18553.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1029",
        "Text_Position": {
            "x": "18444.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0A5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1030",
        "Text_Position": {
            "x": "18215.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0A6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18546",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1031",
        "Text_Position": {
            "x": "18444.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0A7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1038",
        "Text_Position": {
            "x": "18215.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0A8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18553.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1039",
        "Text_Position": {
            "x": "18444.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0A9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1040",
        "Text_Position": {
            "x": "18215.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0AA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18546",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1041",
        "Text_Position": {
            "x": "18444.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0AB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1043",
        "Text_Position": {
            "x": "18444.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0AC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1045",
        "Text_Position": {
            "x": "18444.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0AD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18553.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1049",
        "Text_Position": {
            "x": "18444.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0AE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18546",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1051",
        "Text_Position": {
            "x": "18444.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0AF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1053",
        "Text_Position": {
            "x": "18444.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0B0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1055",
        "Text_Position": {
            "x": "18444.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0B1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1057",
        "Text_Position": {
            "x": "18444.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0B2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18553.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1059",
        "Text_Position": {
            "x": "18444.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0B3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18546",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1061",
        "Text_Position": {
            "x": "18444.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0B4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1062",
        "Text_Position": {
            "x": "18215.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0B5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1063",
        "Text_Position": {
            "x": "18444.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0B6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1066",
        "Text_Position": {
            "x": "18215.4",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0B7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18463.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18565.4",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1067",
        "Text_Position": {
            "x": "18455.4",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18548.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0B8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18343.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18445.4",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1068",
        "Text_Position": {
            "x": "18335.4",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18428.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0B9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1100",
        "Text_Position": {
            "x": "18575.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0BA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18906",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1101",
        "Text_Position": {
            "x": "18804.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0BB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1102",
        "Text_Position": {
            "x": "18575.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0BC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18686.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1104",
        "Text_Position": {
            "x": "18575.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0BD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1107",
        "Text_Position": {
            "x": "18804.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0BE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18913.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1109",
        "Text_Position": {
            "x": "18804.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0BF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18906",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1111",
        "Text_Position": {
            "x": "18804.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0C0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1113",
        "Text_Position": {
            "x": "18804.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0C1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18686.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1114",
        "Text_Position": {
            "x": "18575.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0C2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1115",
        "Text_Position": {
            "x": "18804.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0C3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1117",
        "Text_Position": {
            "x": "18804.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0C4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18913.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1119",
        "Text_Position": {
            "x": "18804.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0C5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1122",
        "Text_Position": {
            "x": "18575.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0C6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1123",
        "Text_Position": {
            "x": "18804.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0C7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18686.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1124",
        "Text_Position": {
            "x": "18575.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0C8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1125",
        "Text_Position": {
            "x": "18804.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0C9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1126",
        "Text_Position": {
            "x": "18575.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0CA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1127",
        "Text_Position": {
            "x": "18804.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0CB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18913.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1129",
        "Text_Position": {
            "x": "18804.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0CC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1137",
        "Text_Position": {
            "x": "18804.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0CD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1138",
        "Text_Position": {
            "x": "18575.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0CE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18913.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1139",
        "Text_Position": {
            "x": "18804.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0CF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18686.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1144",
        "Text_Position": {
            "x": "18575.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0D0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1145",
        "Text_Position": {
            "x": "18804.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0D1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1148",
        "Text_Position": {
            "x": "18575.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0D2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18913.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1149",
        "Text_Position": {
            "x": "18804.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0D3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1150",
        "Text_Position": {
            "x": "18575.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0D4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18906",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1151",
        "Text_Position": {
            "x": "18804.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0D5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1152",
        "Text_Position": {
            "x": "18575.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0D6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1153",
        "Text_Position": {
            "x": "18804.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0D7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1155",
        "Text_Position": {
            "x": "18804.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0D8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1157",
        "Text_Position": {
            "x": "18804.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0D9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1158",
        "Text_Position": {
            "x": "18575.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0DA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18913.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1159",
        "Text_Position": {
            "x": "18804.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0DB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1160",
        "Text_Position": {
            "x": "18575.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0DC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18906",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1161",
        "Text_Position": {
            "x": "18804.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0DD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1162",
        "Text_Position": {
            "x": "18575.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0DE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1163",
        "Text_Position": {
            "x": "18804.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0DF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18823.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18925.4",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1167",
        "Text_Position": {
            "x": "18815.4",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18908.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0E0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18703.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18805.4",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1168",
        "Text_Position": {
            "x": "18695.4",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18788.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0E1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1200",
        "Text_Position": {
            "x": "18935.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0E2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19266",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1201",
        "Text_Position": {
            "x": "19164.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0E3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1202",
        "Text_Position": {
            "x": "18935.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0E4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1203",
        "Text_Position": {
            "x": "19164.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0E5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19046.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1204",
        "Text_Position": {
            "x": "18935.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0E6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1205",
        "Text_Position": {
            "x": "19164.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0E7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1206",
        "Text_Position": {
            "x": "18935.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0E8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1208",
        "Text_Position": {
            "x": "18935.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0E9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1210",
        "Text_Position": {
            "x": "18935.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0EA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19266",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1211",
        "Text_Position": {
            "x": "19164.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0EB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1213",
        "Text_Position": {
            "x": "19164.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0EC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1215",
        "Text_Position": {
            "x": "19164.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0ED",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1216",
        "Text_Position": {
            "x": "18935.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0EE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1217",
        "Text_Position": {
            "x": "19164.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0EF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1218",
        "Text_Position": {
            "x": "18935.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0F0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19273.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1219",
        "Text_Position": {
            "x": "19164.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0F1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1222",
        "Text_Position": {
            "x": "18935.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0F2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19046.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1224",
        "Text_Position": {
            "x": "18935.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0F3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1226",
        "Text_Position": {
            "x": "18935.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0F4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1228",
        "Text_Position": {
            "x": "18935.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0F5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1236",
        "Text_Position": {
            "x": "18935.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0F6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1238",
        "Text_Position": {
            "x": "18935.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0F7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1240",
        "Text_Position": {
            "x": "18935.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0F8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19266",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1241",
        "Text_Position": {
            "x": "19164.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0F9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1242",
        "Text_Position": {
            "x": "18935.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0FA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1243",
        "Text_Position": {
            "x": "19164.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0FB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19046.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1244",
        "Text_Position": {
            "x": "18935.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0FC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1245",
        "Text_Position": {
            "x": "19164.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0FD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19273.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1249",
        "Text_Position": {
            "x": "19164.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0FE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1250",
        "Text_Position": {
            "x": "18935.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C0FF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19266",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1251",
        "Text_Position": {
            "x": "19164.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C100",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1252",
        "Text_Position": {
            "x": "18935.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C101",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1253",
        "Text_Position": {
            "x": "19164.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C102",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19046.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1254",
        "Text_Position": {
            "x": "18935.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C103",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1255",
        "Text_Position": {
            "x": "19164.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C104",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1256",
        "Text_Position": {
            "x": "18935.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C105",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1257",
        "Text_Position": {
            "x": "19164.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C106",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1258",
        "Text_Position": {
            "x": "18935.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C107",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19273.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1259",
        "Text_Position": {
            "x": "19164.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C108",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1260",
        "Text_Position": {
            "x": "18935.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C109",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19266",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1261",
        "Text_Position": {
            "x": "19164.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C10A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1262",
        "Text_Position": {
            "x": "18935.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C10B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1263",
        "Text_Position": {
            "x": "19164.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C10C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1266",
        "Text_Position": {
            "x": "18935.4",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C10D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1300",
        "Text_Position": {
            "x": "19295.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C10E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1302",
        "Text_Position": {
            "x": "19295.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C10F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19406.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1304",
        "Text_Position": {
            "x": "19295.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C110",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1305",
        "Text_Position": {
            "x": "19524.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C111",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19626",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1311",
        "Text_Position": {
            "x": "19524.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C112",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1313",
        "Text_Position": {
            "x": "19524.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C113",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19406.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1314",
        "Text_Position": {
            "x": "19295.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C114",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1315",
        "Text_Position": {
            "x": "19524.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C115",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1323",
        "Text_Position": {
            "x": "19524.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C116",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1325",
        "Text_Position": {
            "x": "19524.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C117",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1327",
        "Text_Position": {
            "x": "19524.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C118",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1337",
        "Text_Position": {
            "x": "19524.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C119",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19633.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1339",
        "Text_Position": {
            "x": "19524.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C11A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1340",
        "Text_Position": {
            "x": "19295.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C11B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19626",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1341",
        "Text_Position": {
            "x": "19524.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C11C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1342",
        "Text_Position": {
            "x": "19295.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C11D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1343",
        "Text_Position": {
            "x": "19524.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C11E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19406.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1344",
        "Text_Position": {
            "x": "19295.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C11F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1345",
        "Text_Position": {
            "x": "19524.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C120",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1350",
        "Text_Position": {
            "x": "19295.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C121",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19626",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1351",
        "Text_Position": {
            "x": "19524.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C122",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1352",
        "Text_Position": {
            "x": "19295.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C123",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1357",
        "Text_Position": {
            "x": "19524.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C124",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1362",
        "Text_Position": {
            "x": "19295.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C125",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1363",
        "Text_Position": {
            "x": "19524.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C126",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1367",
        "Text_Position": {
            "x": "19524.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C127",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19633.2",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1369",
        "Text_Position": {
            "x": "19524.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C128",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19626",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1371",
        "Text_Position": {
            "x": "19524.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C129",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1373",
        "Text_Position": {
            "x": "19524.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C12A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1375",
        "Text_Position": {
            "x": "19524.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C12B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1377",
        "Text_Position": {
            "x": "19524.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C12C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19633.2",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1379",
        "Text_Position": {
            "x": "19524.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C12D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19626",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1381",
        "Text_Position": {
            "x": "19524.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C12E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1383",
        "Text_Position": {
            "x": "19524.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C12F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19766.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1404",
        "Text_Position": {
            "x": "19655.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C130",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1406",
        "Text_Position": {
            "x": "19655.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C131",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1408",
        "Text_Position": {
            "x": "19655.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C132",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19993.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1409",
        "Text_Position": {
            "x": "19884.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C133",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1410",
        "Text_Position": {
            "x": "19655.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C134",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1412",
        "Text_Position": {
            "x": "19655.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C135",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19766.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1414",
        "Text_Position": {
            "x": "19655.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C136",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1415",
        "Text_Position": {
            "x": "19884.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C137",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1417",
        "Text_Position": {
            "x": "19884.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C138",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19993.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1419",
        "Text_Position": {
            "x": "19884.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C139",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1436",
        "Text_Position": {
            "x": "19655.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C13A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1438",
        "Text_Position": {
            "x": "19655.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C13B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1440",
        "Text_Position": {
            "x": "19655.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C13C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19986",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1441",
        "Text_Position": {
            "x": "19884.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C13D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1442",
        "Text_Position": {
            "x": "19655.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C13E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1443",
        "Text_Position": {
            "x": "19884.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C13F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19766.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1444",
        "Text_Position": {
            "x": "19655.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C140",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1445",
        "Text_Position": {
            "x": "19884.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C141",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19993.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1449",
        "Text_Position": {
            "x": "19884.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C142",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1450",
        "Text_Position": {
            "x": "19655.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C143",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19986",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1451",
        "Text_Position": {
            "x": "19884.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C144",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1452",
        "Text_Position": {
            "x": "19655.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C145",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1453",
        "Text_Position": {
            "x": "19884.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C146",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19766.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1454",
        "Text_Position": {
            "x": "19655.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C147",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1455",
        "Text_Position": {
            "x": "19884.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C148",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1456",
        "Text_Position": {
            "x": "19655.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C149",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1457",
        "Text_Position": {
            "x": "19884.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C14A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1462",
        "Text_Position": {
            "x": "19655.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C14B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1463",
        "Text_Position": {
            "x": "19884.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C14C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1466",
        "Text_Position": {
            "x": "19655.4",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C14D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1467",
        "Text_Position": {
            "x": "19884.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C14E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1468",
        "Text_Position": {
            "x": "19655.4",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C14F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19993.2",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1469",
        "Text_Position": {
            "x": "19884.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C150",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1470",
        "Text_Position": {
            "x": "19655.4",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C151",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19986",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1471",
        "Text_Position": {
            "x": "19884.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C152",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1472",
        "Text_Position": {
            "x": "19655.4",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C153",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1473",
        "Text_Position": {
            "x": "19884.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C154",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19766.8",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1474",
        "Text_Position": {
            "x": "19655.4",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C155",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1475",
        "Text_Position": {
            "x": "19884.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C156",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1476",
        "Text_Position": {
            "x": "19655.4",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C157",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1477",
        "Text_Position": {
            "x": "19884.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C158",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1478",
        "Text_Position": {
            "x": "19655.4",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C159",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19993.2",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1479",
        "Text_Position": {
            "x": "19884.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C15A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1480",
        "Text_Position": {
            "x": "19655.4",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C15B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19986",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1481",
        "Text_Position": {
            "x": "19884.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C15C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1482",
        "Text_Position": {
            "x": "19655.4",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C15D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1483",
        "Text_Position": {
            "x": "19884.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C15E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1508",
        "Text_Position": {
            "x": "20015.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C15F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20353.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1509",
        "Text_Position": {
            "x": "20244.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C160",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1510",
        "Text_Position": {
            "x": "20015.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C161",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1512",
        "Text_Position": {
            "x": "20015.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C162",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20126.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1514",
        "Text_Position": {
            "x": "20015.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C163",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1516",
        "Text_Position": {
            "x": "20015.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C164",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1517",
        "Text_Position": {
            "x": "20244.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C165",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1518",
        "Text_Position": {
            "x": "20015.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C166",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1540",
        "Text_Position": {
            "x": "20015.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C167",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1542",
        "Text_Position": {
            "x": "20015.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C168",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20126.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1544",
        "Text_Position": {
            "x": "20015.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C169",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1548",
        "Text_Position": {
            "x": "20015.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C16A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1550",
        "Text_Position": {
            "x": "20015.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C16B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1552",
        "Text_Position": {
            "x": "20015.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C16C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1553",
        "Text_Position": {
            "x": "20244.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C16D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20126.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1554",
        "Text_Position": {
            "x": "20015.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C16E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1555",
        "Text_Position": {
            "x": "20244.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C16F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1556",
        "Text_Position": {
            "x": "20015.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C170",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1557",
        "Text_Position": {
            "x": "20244.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C171",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1562",
        "Text_Position": {
            "x": "20015.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C172",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1563",
        "Text_Position": {
            "x": "20244.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C173",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1566",
        "Text_Position": {
            "x": "20015.4",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C174",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1567",
        "Text_Position": {
            "x": "20244.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C175",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1568",
        "Text_Position": {
            "x": "20015.4",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C176",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20353.2",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1569",
        "Text_Position": {
            "x": "20244.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C177",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1570",
        "Text_Position": {
            "x": "20015.4",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C178",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20346",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1571",
        "Text_Position": {
            "x": "20244.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C179",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1572",
        "Text_Position": {
            "x": "20015.4",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C17A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1573",
        "Text_Position": {
            "x": "20244.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C17B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20126.8",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1574",
        "Text_Position": {
            "x": "20015.4",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C17C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1575",
        "Text_Position": {
            "x": "20244.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C17D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1576",
        "Text_Position": {
            "x": "20015.4",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C17E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1577",
        "Text_Position": {
            "x": "20244.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C17F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1578",
        "Text_Position": {
            "x": "20015.4",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C180",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20353.2",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1579",
        "Text_Position": {
            "x": "20244.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C181",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1580",
        "Text_Position": {
            "x": "20015.4",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C182",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20346",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1581",
        "Text_Position": {
            "x": "20244.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C183",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1582",
        "Text_Position": {
            "x": "20015.4",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C184",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1583",
        "Text_Position": {
            "x": "20244.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C185",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1608",
        "Text_Position": {
            "x": "20375.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C186",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1616",
        "Text_Position": {
            "x": "20375.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C187",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1652",
        "Text_Position": {
            "x": "20375.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C188",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1658",
        "Text_Position": {
            "x": "20375.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C189",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1660",
        "Text_Position": {
            "x": "20375.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C18A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1662",
        "Text_Position": {
            "x": "20375.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C18B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1666",
        "Text_Position": {
            "x": "20375.4",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C18C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1668",
        "Text_Position": {
            "x": "20375.4",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C18D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1670",
        "Text_Position": {
            "x": "20375.4",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C18E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1672",
        "Text_Position": {
            "x": "20375.4",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C18F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20486.8",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1674",
        "Text_Position": {
            "x": "20375.4",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C190",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1676",
        "Text_Position": {
            "x": "20375.4",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C191",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1678",
        "Text_Position": {
            "x": "20375.4",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C192",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1680",
        "Text_Position": {
            "x": "20375.4",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C193",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1682",
        "Text_Position": {
            "x": "20375.4",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C194",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21066",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1701",
        "Text_Position": {
            "x": "20964.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C195",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1703",
        "Text_Position": {
            "x": "20964.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C196",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1705",
        "Text_Position": {
            "x": "20964.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C197",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1707",
        "Text_Position": {
            "x": "20964.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C198",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1708",
        "Text_Position": {
            "x": "20735.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C199",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21073.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1709",
        "Text_Position": {
            "x": "20964.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C19A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21066",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1711",
        "Text_Position": {
            "x": "20964.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C19B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1713",
        "Text_Position": {
            "x": "20964.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C19C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1715",
        "Text_Position": {
            "x": "20964.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C19D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1716",
        "Text_Position": {
            "x": "20735.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C19E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1717",
        "Text_Position": {
            "x": "20964.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C19F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21073.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1719",
        "Text_Position": {
            "x": "20964.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1A0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1723",
        "Text_Position": {
            "x": "20964.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1A1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1725",
        "Text_Position": {
            "x": "20964.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1A2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1726",
        "Text_Position": {
            "x": "20735.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1A3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1727",
        "Text_Position": {
            "x": "20964.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1A4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21073.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1729",
        "Text_Position": {
            "x": "20964.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1A5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21066",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1731",
        "Text_Position": {
            "x": "20964.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1A6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1740",
        "Text_Position": {
            "x": "20735.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1A7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21066",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1741",
        "Text_Position": {
            "x": "20964.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1A8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1743",
        "Text_Position": {
            "x": "20964.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1A9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1745",
        "Text_Position": {
            "x": "20964.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1AA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21073.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1749",
        "Text_Position": {
            "x": "20964.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1AB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1750",
        "Text_Position": {
            "x": "20735.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1AC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21066",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1751",
        "Text_Position": {
            "x": "20964.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1AD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1753",
        "Text_Position": {
            "x": "20964.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1AE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1755",
        "Text_Position": {
            "x": "20964.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1AF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1757",
        "Text_Position": {
            "x": "20964.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1B0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21073.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1759",
        "Text_Position": {
            "x": "20964.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1B1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21066",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1761",
        "Text_Position": {
            "x": "20964.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1B2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1762",
        "Text_Position": {
            "x": "20735.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1B3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1763",
        "Text_Position": {
            "x": "20964.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1B4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1766",
        "Text_Position": {
            "x": "20735.4",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1B5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1767",
        "Text_Position": {
            "x": "20964.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1B6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1768",
        "Text_Position": {
            "x": "20735.4",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1B7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21073.2",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1769",
        "Text_Position": {
            "x": "20964.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1B8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1770",
        "Text_Position": {
            "x": "20735.4",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1B9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21066",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1771",
        "Text_Position": {
            "x": "20964.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1BA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1772",
        "Text_Position": {
            "x": "20735.4",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1BB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1773",
        "Text_Position": {
            "x": "20964.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1BC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20846.8",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1774",
        "Text_Position": {
            "x": "20735.4",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1BD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1775",
        "Text_Position": {
            "x": "20964.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1BE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1776",
        "Text_Position": {
            "x": "20735.4",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1BF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1777",
        "Text_Position": {
            "x": "20964.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1C0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21073.2",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1779",
        "Text_Position": {
            "x": "20964.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1C1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1780",
        "Text_Position": {
            "x": "20735.4",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1C2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21066",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1781",
        "Text_Position": {
            "x": "20964.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1C3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1782",
        "Text_Position": {
            "x": "20735.4",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1C4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1783",
        "Text_Position": {
            "x": "20964.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1C5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21426",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1801",
        "Text_Position": {
            "x": "21324.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1C6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1803",
        "Text_Position": {
            "x": "21324.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1C7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1805",
        "Text_Position": {
            "x": "21324.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1C8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1807",
        "Text_Position": {
            "x": "21324.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1C9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1808",
        "Text_Position": {
            "x": "21095.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1CA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21433.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1809",
        "Text_Position": {
            "x": "21324.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1CB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21426",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1811",
        "Text_Position": {
            "x": "21324.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1CC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1813",
        "Text_Position": {
            "x": "21324.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1CD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1815",
        "Text_Position": {
            "x": "21324.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1CE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1816",
        "Text_Position": {
            "x": "21095.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1CF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1817",
        "Text_Position": {
            "x": "21324.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1D0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21433.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1819",
        "Text_Position": {
            "x": "21324.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1D1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1823",
        "Text_Position": {
            "x": "21324.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1D2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21206.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1824",
        "Text_Position": {
            "x": "21095.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1D3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1837",
        "Text_Position": {
            "x": "21324.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1D4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21433.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1839",
        "Text_Position": {
            "x": "21324.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1D5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1840",
        "Text_Position": {
            "x": "21095.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1D6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21426",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1841",
        "Text_Position": {
            "x": "21324.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1D7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1843",
        "Text_Position": {
            "x": "21324.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1D8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1845",
        "Text_Position": {
            "x": "21324.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1D9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21433.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1849",
        "Text_Position": {
            "x": "21324.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1DA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1850",
        "Text_Position": {
            "x": "21095.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1DB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21426",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1851",
        "Text_Position": {
            "x": "21324.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1DC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1853",
        "Text_Position": {
            "x": "21324.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1DD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1855",
        "Text_Position": {
            "x": "21324.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1DE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1856",
        "Text_Position": {
            "x": "21095.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1DF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1857",
        "Text_Position": {
            "x": "21324.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1E0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1862",
        "Text_Position": {
            "x": "21095.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1E1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1863",
        "Text_Position": {
            "x": "21324.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1E2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1866",
        "Text_Position": {
            "x": "21095.4",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1E3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1867",
        "Text_Position": {
            "x": "21324.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1E4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1868",
        "Text_Position": {
            "x": "21095.4",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1E5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21433.2",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1869",
        "Text_Position": {
            "x": "21324.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1E6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1870",
        "Text_Position": {
            "x": "21095.4",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1E7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21426",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1871",
        "Text_Position": {
            "x": "21324.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1E8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1872",
        "Text_Position": {
            "x": "21095.4",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1E9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1873",
        "Text_Position": {
            "x": "21324.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1EA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21206.8",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1874",
        "Text_Position": {
            "x": "21095.4",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1EB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1875",
        "Text_Position": {
            "x": "21324.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1EC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1876",
        "Text_Position": {
            "x": "21095.4",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1ED",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1877",
        "Text_Position": {
            "x": "21324.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1EE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1878",
        "Text_Position": {
            "x": "21095.4",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1EF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21433.2",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1879",
        "Text_Position": {
            "x": "21324.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1F0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1880",
        "Text_Position": {
            "x": "21095.4",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1F1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21426",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1881",
        "Text_Position": {
            "x": "21324.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1F2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1882",
        "Text_Position": {
            "x": "21095.4",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1F3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1883",
        "Text_Position": {
            "x": "21324.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1F4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21566.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1904",
        "Text_Position": {
            "x": "21455.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1F5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1905",
        "Text_Position": {
            "x": "21684.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1F6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1916",
        "Text_Position": {
            "x": "21455.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1F7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1917",
        "Text_Position": {
            "x": "21684.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1F8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1918",
        "Text_Position": {
            "x": "21455.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1F9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1922",
        "Text_Position": {
            "x": "21455.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1FA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1923",
        "Text_Position": {
            "x": "21684.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1FB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1925",
        "Text_Position": {
            "x": "21684.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1FC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1927",
        "Text_Position": {
            "x": "21684.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1FD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1936",
        "Text_Position": {
            "x": "21455.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1FE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1938",
        "Text_Position": {
            "x": "21455.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C1FF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21793.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1939",
        "Text_Position": {
            "x": "21684.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C200",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1940",
        "Text_Position": {
            "x": "21455.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C201",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21786",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1941",
        "Text_Position": {
            "x": "21684.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C202",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1942",
        "Text_Position": {
            "x": "21455.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C203",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21566.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1944",
        "Text_Position": {
            "x": "21455.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C204",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1950",
        "Text_Position": {
            "x": "21455.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C205",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1953",
        "Text_Position": {
            "x": "21684.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C206",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1955",
        "Text_Position": {
            "x": "21684.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C207",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1956",
        "Text_Position": {
            "x": "21455.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C208",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1957",
        "Text_Position": {
            "x": "21684.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C209",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1958",
        "Text_Position": {
            "x": "21455.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C20A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21793.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1959",
        "Text_Position": {
            "x": "21684.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C20B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1960",
        "Text_Position": {
            "x": "21455.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C20C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21786",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1961",
        "Text_Position": {
            "x": "21684.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C20D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1962",
        "Text_Position": {
            "x": "21455.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C20E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1963",
        "Text_Position": {
            "x": "21684.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C20F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1966",
        "Text_Position": {
            "x": "21455.4",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C210",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1967",
        "Text_Position": {
            "x": "21684.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C211",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1968",
        "Text_Position": {
            "x": "21455.4",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C212",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21793.2",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1969",
        "Text_Position": {
            "x": "21684.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C213",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1970",
        "Text_Position": {
            "x": "21455.4",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C214",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21786",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1971",
        "Text_Position": {
            "x": "21684.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C215",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1972",
        "Text_Position": {
            "x": "21455.4",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C216",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1973",
        "Text_Position": {
            "x": "21684.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C217",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21566.8",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1974",
        "Text_Position": {
            "x": "21455.4",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C218",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1975",
        "Text_Position": {
            "x": "21684.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C219",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1976",
        "Text_Position": {
            "x": "21455.4",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C21A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1977",
        "Text_Position": {
            "x": "21684.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C21B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1978",
        "Text_Position": {
            "x": "21455.4",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C21C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21793.2",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1979",
        "Text_Position": {
            "x": "21684.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C21D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1980",
        "Text_Position": {
            "x": "21455.4",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C21E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21786",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1981",
        "Text_Position": {
            "x": "21684.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C21F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1982",
        "Text_Position": {
            "x": "21455.4",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C220",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1983",
        "Text_Position": {
            "x": "21684.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C221",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22146",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2001",
        "Text_Position": {
            "x": "22044.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C222",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2003",
        "Text_Position": {
            "x": "22044.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C223",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21926.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2004",
        "Text_Position": {
            "x": "21815.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C224",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2005",
        "Text_Position": {
            "x": "22044.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C225",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22146",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2011",
        "Text_Position": {
            "x": "22044.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C226",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2013",
        "Text_Position": {
            "x": "22044.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C227",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2015",
        "Text_Position": {
            "x": "22044.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C228",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2016",
        "Text_Position": {
            "x": "21815.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C229",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2017",
        "Text_Position": {
            "x": "22044.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C22A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2018",
        "Text_Position": {
            "x": "21815.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C22B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22153.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2019",
        "Text_Position": {
            "x": "22044.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C22C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2022",
        "Text_Position": {
            "x": "21815.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C22D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2023",
        "Text_Position": {
            "x": "22044.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C22E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21926.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2024",
        "Text_Position": {
            "x": "21815.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C22F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2026",
        "Text_Position": {
            "x": "21815.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C230",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2028",
        "Text_Position": {
            "x": "21815.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C231",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22153.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2029",
        "Text_Position": {
            "x": "22044.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C232",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2030",
        "Text_Position": {
            "x": "21815.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C233",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2037",
        "Text_Position": {
            "x": "22044.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C234",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2038",
        "Text_Position": {
            "x": "21815.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C235",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22153.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2039",
        "Text_Position": {
            "x": "22044.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C236",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2040",
        "Text_Position": {
            "x": "21815.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C237",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22146",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2041",
        "Text_Position": {
            "x": "22044.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C238",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2042",
        "Text_Position": {
            "x": "21815.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C239",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2043",
        "Text_Position": {
            "x": "22044.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C23A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21926.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2044",
        "Text_Position": {
            "x": "21815.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C23B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2045",
        "Text_Position": {
            "x": "22044.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C23C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22146",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2051",
        "Text_Position": {
            "x": "22044.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C23D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2052",
        "Text_Position": {
            "x": "21815.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C23E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2053",
        "Text_Position": {
            "x": "22044.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C23F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21926.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2054",
        "Text_Position": {
            "x": "21815.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C240",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2056",
        "Text_Position": {
            "x": "21815.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C241",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2058",
        "Text_Position": {
            "x": "21815.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C242",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2060",
        "Text_Position": {
            "x": "21815.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C243",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2062",
        "Text_Position": {
            "x": "21815.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C244",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2063",
        "Text_Position": {
            "x": "22044.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C245",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "580.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "610.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2066",
        "Text_Position": {
            "x": "21815.4",
            "y": "580.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "580.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C246",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "700.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "730.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2068",
        "Text_Position": {
            "x": "21815.4",
            "y": "700.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "700.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C247",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "820.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "850.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2070",
        "Text_Position": {
            "x": "21815.4",
            "y": "820.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "820.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C248",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "940.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "970.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2072",
        "Text_Position": {
            "x": "21815.4",
            "y": "940.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "940.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C249",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "1060.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21926.8",
                "y": "1090.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2074",
        "Text_Position": {
            "x": "21815.4",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "1060.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C24A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "1180.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "1210.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2076",
        "Text_Position": {
            "x": "21815.4",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "1180.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C24B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "1300.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "1330.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2078",
        "Text_Position": {
            "x": "21815.4",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "1300.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C24C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "1420.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "1450.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2080",
        "Text_Position": {
            "x": "21815.4",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "1420.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C24D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "1540.22",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "1570.22",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2082",
        "Text_Position": {
            "x": "21815.4",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "1540.22",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C24E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2100",
        "Text_Position": {
            "x": "22175.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C24F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22506",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2101",
        "Text_Position": {
            "x": "22404.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C250",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2102",
        "Text_Position": {
            "x": "22175.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C251",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2103",
        "Text_Position": {
            "x": "22404.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C252",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22286.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2104",
        "Text_Position": {
            "x": "22175.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C253",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22513.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2109",
        "Text_Position": {
            "x": "22404.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C254",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2110",
        "Text_Position": {
            "x": "22175.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C255",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2112",
        "Text_Position": {
            "x": "22175.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C256",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2115",
        "Text_Position": {
            "x": "22404.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C257",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2117",
        "Text_Position": {
            "x": "22404.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C258",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2118",
        "Text_Position": {
            "x": "22175.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C259",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2122",
        "Text_Position": {
            "x": "22175.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C25A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2123",
        "Text_Position": {
            "x": "22404.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C25B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22286.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2124",
        "Text_Position": {
            "x": "22175.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C25C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2125",
        "Text_Position": {
            "x": "22404.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C25D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2126",
        "Text_Position": {
            "x": "22175.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C25E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2128",
        "Text_Position": {
            "x": "22175.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C25F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22506",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2131",
        "Text_Position": {
            "x": "22404.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C260",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2136",
        "Text_Position": {
            "x": "22175.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C261",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2138",
        "Text_Position": {
            "x": "22175.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C262",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22513.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2139",
        "Text_Position": {
            "x": "22404.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C263",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2140",
        "Text_Position": {
            "x": "22175.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C264",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22506",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2141",
        "Text_Position": {
            "x": "22404.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C265",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2142",
        "Text_Position": {
            "x": "22175.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C266",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2143",
        "Text_Position": {
            "x": "22404.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C267",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22286.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2144",
        "Text_Position": {
            "x": "22175.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C268",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2145",
        "Text_Position": {
            "x": "22404.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C269",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22513.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2149",
        "Text_Position": {
            "x": "22404.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C26A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2150",
        "Text_Position": {
            "x": "22175.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C26B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22506",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2151",
        "Text_Position": {
            "x": "22404.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C26C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2152",
        "Text_Position": {
            "x": "22175.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C26D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2153",
        "Text_Position": {
            "x": "22404.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C26E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22286.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2154",
        "Text_Position": {
            "x": "22175.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C26F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2155",
        "Text_Position": {
            "x": "22404.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C270",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2156",
        "Text_Position": {
            "x": "22175.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C271",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2157",
        "Text_Position": {
            "x": "22404.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C272",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2158",
        "Text_Position": {
            "x": "22175.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C273",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2160",
        "Text_Position": {
            "x": "22175.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C274",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2162",
        "Text_Position": {
            "x": "22175.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C275",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2163",
        "Text_Position": {
            "x": "22404.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C276",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22409",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.7",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2167",
        "Text_Position": {
            "x": "22404.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C277",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2200",
        "Text_Position": {
            "x": "22535.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C278",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22866",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2201",
        "Text_Position": {
            "x": "22764.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C279",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2202",
        "Text_Position": {
            "x": "22535.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C27A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2203",
        "Text_Position": {
            "x": "22764.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C27B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22646.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2204",
        "Text_Position": {
            "x": "22535.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C27C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2205",
        "Text_Position": {
            "x": "22764.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C27D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2206",
        "Text_Position": {
            "x": "22535.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C27E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2208",
        "Text_Position": {
            "x": "22535.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C27F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22866",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2211",
        "Text_Position": {
            "x": "22764.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C280",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2213",
        "Text_Position": {
            "x": "22764.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C281",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22646.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2214",
        "Text_Position": {
            "x": "22535.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C282",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2215",
        "Text_Position": {
            "x": "22764.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C283",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2216",
        "Text_Position": {
            "x": "22535.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C284",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2217",
        "Text_Position": {
            "x": "22764.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C285",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2218",
        "Text_Position": {
            "x": "22535.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C286",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22873.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2219",
        "Text_Position": {
            "x": "22764.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C287",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2222",
        "Text_Position": {
            "x": "22535.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C288",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22646.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2224",
        "Text_Position": {
            "x": "22535.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C289",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2226",
        "Text_Position": {
            "x": "22535.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C28A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2227",
        "Text_Position": {
            "x": "22764.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C28B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2237",
        "Text_Position": {
            "x": "22764.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C28C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2238",
        "Text_Position": {
            "x": "22535.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C28D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22873.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2239",
        "Text_Position": {
            "x": "22764.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C28E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2240",
        "Text_Position": {
            "x": "22535.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C28F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22866",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2241",
        "Text_Position": {
            "x": "22764.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C290",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2242",
        "Text_Position": {
            "x": "22535.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C291",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2243",
        "Text_Position": {
            "x": "22764.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C292",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22646.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2244",
        "Text_Position": {
            "x": "22535.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C293",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2245",
        "Text_Position": {
            "x": "22764.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C294",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2248",
        "Text_Position": {
            "x": "22535.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C295",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2250",
        "Text_Position": {
            "x": "22535.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C296",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22866",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2251",
        "Text_Position": {
            "x": "22764.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C297",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2252",
        "Text_Position": {
            "x": "22535.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C298",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22646.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2254",
        "Text_Position": {
            "x": "22535.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C299",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2256",
        "Text_Position": {
            "x": "22535.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C29A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2258",
        "Text_Position": {
            "x": "22535.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C29B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22873.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2259",
        "Text_Position": {
            "x": "22764.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C29C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2260",
        "Text_Position": {
            "x": "22535.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C29D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22866",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2261",
        "Text_Position": {
            "x": "22764.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C29E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2262",
        "Text_Position": {
            "x": "22535.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C29F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2263",
        "Text_Position": {
            "x": "22764.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2A0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22769",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.7",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2267",
        "Text_Position": {
            "x": "22764.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2A1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2300",
        "Text_Position": {
            "x": "22895.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2A2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2302",
        "Text_Position": {
            "x": "22895.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2A3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2305",
        "Text_Position": {
            "x": "23124.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2A4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2307",
        "Text_Position": {
            "x": "23124.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2A5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2308",
        "Text_Position": {
            "x": "22895.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2A6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23233.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2309",
        "Text_Position": {
            "x": "23124.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2A7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2310",
        "Text_Position": {
            "x": "22895.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2A8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23226",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2311",
        "Text_Position": {
            "x": "23124.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2A9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2312",
        "Text_Position": {
            "x": "22895.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2AA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2313",
        "Text_Position": {
            "x": "23124.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2AB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23006.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2314",
        "Text_Position": {
            "x": "22895.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2AC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2315",
        "Text_Position": {
            "x": "23124.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2AD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2316",
        "Text_Position": {
            "x": "22895.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2AE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2317",
        "Text_Position": {
            "x": "23124.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2AF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2318",
        "Text_Position": {
            "x": "22895.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2B0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23233.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2319",
        "Text_Position": {
            "x": "23124.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2B1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2326",
        "Text_Position": {
            "x": "22895.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2B2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2327",
        "Text_Position": {
            "x": "23124.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2B3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23233.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2329",
        "Text_Position": {
            "x": "23124.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2B4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23226",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2331",
        "Text_Position": {
            "x": "23124.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2B5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2336",
        "Text_Position": {
            "x": "22895.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2B6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2338",
        "Text_Position": {
            "x": "22895.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2B7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2340",
        "Text_Position": {
            "x": "22895.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2B8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23226",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2341",
        "Text_Position": {
            "x": "23124.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2B9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2342",
        "Text_Position": {
            "x": "22895.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2BA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2343",
        "Text_Position": {
            "x": "23124.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2BB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23006.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2344",
        "Text_Position": {
            "x": "22895.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2BC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2345",
        "Text_Position": {
            "x": "23124.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2BD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23233.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2349",
        "Text_Position": {
            "x": "23124.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2BE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2350",
        "Text_Position": {
            "x": "22895.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2BF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23226",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2351",
        "Text_Position": {
            "x": "23124.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2C0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2353",
        "Text_Position": {
            "x": "23124.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2C1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2355",
        "Text_Position": {
            "x": "23124.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2C2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2357",
        "Text_Position": {
            "x": "23124.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2C3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2358",
        "Text_Position": {
            "x": "22895.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2C4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23233.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2359",
        "Text_Position": {
            "x": "23124.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2C5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2360",
        "Text_Position": {
            "x": "22895.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2C6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23226",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2361",
        "Text_Position": {
            "x": "23124.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2C7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2362",
        "Text_Position": {
            "x": "22895.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2C8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2363",
        "Text_Position": {
            "x": "23124.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2C9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.8",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.5",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2366",
        "Text_Position": {
            "x": "22895.5",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2CA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23139.8",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23245.5",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2367",
        "Text_Position": {
            "x": "23135.5",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23228.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2CB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23019.8",
                "y": "508.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23125.5",
                "y": "538.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2368",
        "Text_Position": {
            "x": "23015.5",
            "y": "508.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23108.7",
            "y": "508.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2CC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2400",
        "Text_Position": {
            "x": "23255.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2CD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2402",
        "Text_Position": {
            "x": "23255.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2CE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23366.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2404",
        "Text_Position": {
            "x": "23255.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2CF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2406",
        "Text_Position": {
            "x": "23255.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2D0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2408",
        "Text_Position": {
            "x": "23255.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2D1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2410",
        "Text_Position": {
            "x": "23255.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2D2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2412",
        "Text_Position": {
            "x": "23255.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2D3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23366.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2414",
        "Text_Position": {
            "x": "23255.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2D4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2416",
        "Text_Position": {
            "x": "23255.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2D5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2418",
        "Text_Position": {
            "x": "23255.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2D6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2422",
        "Text_Position": {
            "x": "23255.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2D7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23366.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2424",
        "Text_Position": {
            "x": "23255.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2D8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2426",
        "Text_Position": {
            "x": "23255.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2D9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2428",
        "Text_Position": {
            "x": "23255.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2DA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2430",
        "Text_Position": {
            "x": "23255.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2DB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2436",
        "Text_Position": {
            "x": "23255.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2DC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2438",
        "Text_Position": {
            "x": "23255.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2DD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2440",
        "Text_Position": {
            "x": "23255.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2DE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2442",
        "Text_Position": {
            "x": "23255.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2DF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23366.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2444",
        "Text_Position": {
            "x": "23255.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2E0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2448",
        "Text_Position": {
            "x": "23255.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2E1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2450",
        "Text_Position": {
            "x": "23255.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2E2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2452",
        "Text_Position": {
            "x": "23255.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2E3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23366.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2454",
        "Text_Position": {
            "x": "23255.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2E4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2456",
        "Text_Position": {
            "x": "23255.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2E5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2458",
        "Text_Position": {
            "x": "23255.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2E6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2460",
        "Text_Position": {
            "x": "23255.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2E7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2462",
        "Text_Position": {
            "x": "23255.4",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2E8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15637.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "221",
        "Text_Position": {
            "x": "15564.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2E9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15646",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "233",
        "Text_Position": {
            "x": "15564.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2EA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15646",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "235",
        "Text_Position": {
            "x": "15564.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2EB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15568.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15646",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "247",
        "Text_Position": {
            "x": "15564.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2EC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "332",
        "Text_Position": {
            "x": "15718.7",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2ED",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15801.5",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "334",
        "Text_Position": {
            "x": "15718.7",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2EE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "346",
        "Text_Position": {
            "x": "15718.7",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2EF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15997.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "321",
        "Text_Position": {
            "x": "15924.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2F0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "333",
        "Text_Position": {
            "x": "15924.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2F1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "335",
        "Text_Position": {
            "x": "15924.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2F2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15928.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16006",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "347",
        "Text_Position": {
            "x": "15924.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2F3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "420",
        "Text_Position": {
            "x": "16078.7",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2F4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "432",
        "Text_Position": {
            "x": "16078.7",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2F5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16161.5",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "434",
        "Text_Position": {
            "x": "16078.7",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2F6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16357.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "421",
        "Text_Position": {
            "x": "16284.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2F7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "433",
        "Text_Position": {
            "x": "16284.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2F8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "435",
        "Text_Position": {
            "x": "16284.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2F9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "463",
        "Text_Position": {
            "x": "16284.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2FA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "520",
        "Text_Position": {
            "x": "16438.7",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2FB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "532",
        "Text_Position": {
            "x": "16438.7",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2FC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16521.5",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "534",
        "Text_Position": {
            "x": "16438.7",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2FD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "546",
        "Text_Position": {
            "x": "16438.7",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2FE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16521.5",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "554",
        "Text_Position": {
            "x": "16438.7",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C2FF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "556",
        "Text_Position": {
            "x": "16438.7",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C300",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16717.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "521",
        "Text_Position": {
            "x": "16644.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C301",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "533",
        "Text_Position": {
            "x": "16644.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C302",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "535",
        "Text_Position": {
            "x": "16644.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C303",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "547",
        "Text_Position": {
            "x": "16644.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C304",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "620",
        "Text_Position": {
            "x": "16798.7",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C305",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "632",
        "Text_Position": {
            "x": "16798.7",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C306",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16881.5",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "634",
        "Text_Position": {
            "x": "16798.7",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C307",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "646",
        "Text_Position": {
            "x": "16798.7",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C308",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17077.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "621",
        "Text_Position": {
            "x": "17004.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C309",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "633",
        "Text_Position": {
            "x": "17004.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C30A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "635",
        "Text_Position": {
            "x": "17004.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C30B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "647",
        "Text_Position": {
            "x": "17004.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C30C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "720",
        "Text_Position": {
            "x": "17158.7",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C30D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "732",
        "Text_Position": {
            "x": "17158.7",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C30E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17241.5",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "734",
        "Text_Position": {
            "x": "17158.7",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C30F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "746",
        "Text_Position": {
            "x": "17158.7",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C310",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17241.5",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "754",
        "Text_Position": {
            "x": "17158.7",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C311",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "756",
        "Text_Position": {
            "x": "17158.7",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C312",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17437.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "721",
        "Text_Position": {
            "x": "17364.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C313",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "733",
        "Text_Position": {
            "x": "17364.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C314",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "735",
        "Text_Position": {
            "x": "17364.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C315",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "747",
        "Text_Position": {
            "x": "17364.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C316",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "832",
        "Text_Position": {
            "x": "17518.7",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C317",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17601.5",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "834",
        "Text_Position": {
            "x": "17518.7",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C318",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "846",
        "Text_Position": {
            "x": "17518.7",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C319",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "833",
        "Text_Position": {
            "x": "17724.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C31A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "835",
        "Text_Position": {
            "x": "17724.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C31B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "920",
        "Text_Position": {
            "x": "17878.7",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C31C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "932",
        "Text_Position": {
            "x": "17878.7",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C31D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17961.5",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "934",
        "Text_Position": {
            "x": "17878.7",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C31E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18157.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "921",
        "Text_Position": {
            "x": "18084.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C31F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "933",
        "Text_Position": {
            "x": "18084.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C320",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "935",
        "Text_Position": {
            "x": "18084.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C321",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1020",
        "Text_Position": {
            "x": "18215.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C322",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1032",
        "Text_Position": {
            "x": "18215.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C323",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18326.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1034",
        "Text_Position": {
            "x": "18215.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C324",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18546",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1021",
        "Text_Position": {
            "x": "18444.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C325",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1033",
        "Text_Position": {
            "x": "18444.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C326",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1035",
        "Text_Position": {
            "x": "18444.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C327",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1047",
        "Text_Position": {
            "x": "18444.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C328",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1120",
        "Text_Position": {
            "x": "18575.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C329",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1132",
        "Text_Position": {
            "x": "18575.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C32A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18686.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1134",
        "Text_Position": {
            "x": "18575.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C32B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1146",
        "Text_Position": {
            "x": "18575.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C32C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18686.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1154",
        "Text_Position": {
            "x": "18575.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C32D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1156",
        "Text_Position": {
            "x": "18575.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C32E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18906",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1121",
        "Text_Position": {
            "x": "18804.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C32F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1133",
        "Text_Position": {
            "x": "18804.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C330",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1135",
        "Text_Position": {
            "x": "18804.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C331",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1147",
        "Text_Position": {
            "x": "18804.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C332",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1220",
        "Text_Position": {
            "x": "18935.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C333",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1232",
        "Text_Position": {
            "x": "18935.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C334",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19046.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1234",
        "Text_Position": {
            "x": "18935.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C335",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19266",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1221",
        "Text_Position": {
            "x": "19164.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C336",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1233",
        "Text_Position": {
            "x": "19164.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C337",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1235",
        "Text_Position": {
            "x": "19164.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C338",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1247",
        "Text_Position": {
            "x": "19164.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C339",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1320",
        "Text_Position": {
            "x": "19295.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C33A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1332",
        "Text_Position": {
            "x": "19295.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C33B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19406.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1334",
        "Text_Position": {
            "x": "19295.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C33C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19406.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1354",
        "Text_Position": {
            "x": "19295.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C33D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1356",
        "Text_Position": {
            "x": "19295.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C33E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19626",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1321",
        "Text_Position": {
            "x": "19524.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C33F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1333",
        "Text_Position": {
            "x": "19524.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C340",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1335",
        "Text_Position": {
            "x": "19524.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C341",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1420",
        "Text_Position": {
            "x": "19655.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C342",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1432",
        "Text_Position": {
            "x": "19655.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C343",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19766.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1434",
        "Text_Position": {
            "x": "19655.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C344",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19986",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1421",
        "Text_Position": {
            "x": "19884.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C345",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1433",
        "Text_Position": {
            "x": "19884.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C346",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1435",
        "Text_Position": {
            "x": "19884.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C347",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1447",
        "Text_Position": {
            "x": "19884.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C348",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1520",
        "Text_Position": {
            "x": "20015.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C349",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1532",
        "Text_Position": {
            "x": "20015.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C34A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20126.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1534",
        "Text_Position": {
            "x": "20015.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C34B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1546",
        "Text_Position": {
            "x": "20015.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C34C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1533",
        "Text_Position": {
            "x": "20244.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C34D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1535",
        "Text_Position": {
            "x": "20244.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C34E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1632",
        "Text_Position": {
            "x": "20375.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C34F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20486.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1634",
        "Text_Position": {
            "x": "20375.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C350",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20706",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1621",
        "Text_Position": {
            "x": "20604.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C351",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1623",
        "Text_Position": {
            "x": "20604.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C352",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1625",
        "Text_Position": {
            "x": "20604.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C353",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1627",
        "Text_Position": {
            "x": "20604.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C354",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20713.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1629",
        "Text_Position": {
            "x": "20604.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C355",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20706",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1631",
        "Text_Position": {
            "x": "20604.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C356",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1633",
        "Text_Position": {
            "x": "20604.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C357",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1635",
        "Text_Position": {
            "x": "20604.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C358",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1637",
        "Text_Position": {
            "x": "20604.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C359",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20713.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1639",
        "Text_Position": {
            "x": "20604.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C35A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20706",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1641",
        "Text_Position": {
            "x": "20604.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C35B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1643",
        "Text_Position": {
            "x": "20604.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C35C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1645",
        "Text_Position": {
            "x": "20604.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C35D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1647",
        "Text_Position": {
            "x": "20604.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C35E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20713.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1649",
        "Text_Position": {
            "x": "20604.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C35F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20706",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1651",
        "Text_Position": {
            "x": "20604.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C360",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1653",
        "Text_Position": {
            "x": "20604.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C361",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1655",
        "Text_Position": {
            "x": "20604.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C362",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1657",
        "Text_Position": {
            "x": "20604.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C363",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20713.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1659",
        "Text_Position": {
            "x": "20604.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C364",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20706",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1661",
        "Text_Position": {
            "x": "20604.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C365",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20613.2",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20714.6",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1663",
        "Text_Position": {
            "x": "20604.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C366",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1732",
        "Text_Position": {
            "x": "20735.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C367",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20846.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1734",
        "Text_Position": {
            "x": "20735.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C368",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21066",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1721",
        "Text_Position": {
            "x": "20964.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C369",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1733",
        "Text_Position": {
            "x": "20964.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C36A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1735",
        "Text_Position": {
            "x": "20964.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C36B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1747",
        "Text_Position": {
            "x": "20964.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C36C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1832",
        "Text_Position": {
            "x": "21095.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C36D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21206.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1834",
        "Text_Position": {
            "x": "21095.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C36E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21426",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1821",
        "Text_Position": {
            "x": "21324.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C36F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1833",
        "Text_Position": {
            "x": "21324.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C370",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1835",
        "Text_Position": {
            "x": "21324.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C371",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1847",
        "Text_Position": {
            "x": "21324.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C372",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1920",
        "Text_Position": {
            "x": "21455.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C373",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1932",
        "Text_Position": {
            "x": "21455.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C374",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21566.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1934",
        "Text_Position": {
            "x": "21455.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C375",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1933",
        "Text_Position": {
            "x": "21684.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C376",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1935",
        "Text_Position": {
            "x": "21684.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C377",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1947",
        "Text_Position": {
            "x": "21684.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C378",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2020",
        "Text_Position": {
            "x": "21815.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C379",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2032",
        "Text_Position": {
            "x": "21815.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C37A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21926.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2034",
        "Text_Position": {
            "x": "21815.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C37B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2046",
        "Text_Position": {
            "x": "21815.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C37C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22146",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2021",
        "Text_Position": {
            "x": "22044.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C37D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2033",
        "Text_Position": {
            "x": "22044.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C37E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2035",
        "Text_Position": {
            "x": "22044.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C37F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2055",
        "Text_Position": {
            "x": "22044.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C380",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2057",
        "Text_Position": {
            "x": "22044.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C381",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2120",
        "Text_Position": {
            "x": "22175.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C382",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2132",
        "Text_Position": {
            "x": "22175.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C383",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22286.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2134",
        "Text_Position": {
            "x": "22175.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C384",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2133",
        "Text_Position": {
            "x": "22404.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C385",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2135",
        "Text_Position": {
            "x": "22404.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C386",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2147",
        "Text_Position": {
            "x": "22404.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C387",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2220",
        "Text_Position": {
            "x": "22535.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C388",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2232",
        "Text_Position": {
            "x": "22535.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C389",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22646.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2234",
        "Text_Position": {
            "x": "22535.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C38A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2246",
        "Text_Position": {
            "x": "22535.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C38B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2233",
        "Text_Position": {
            "x": "22764.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C38C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2235",
        "Text_Position": {
            "x": "22764.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C38D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2255",
        "Text_Position": {
            "x": "22764.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C38E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2257",
        "Text_Position": {
            "x": "22764.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C38F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2332",
        "Text_Position": {
            "x": "22895.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C390",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23006.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2334",
        "Text_Position": {
            "x": "22895.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C391",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23226",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2321",
        "Text_Position": {
            "x": "23124.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C392",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2333",
        "Text_Position": {
            "x": "23124.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C393",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2335",
        "Text_Position": {
            "x": "23124.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C394",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2347",
        "Text_Position": {
            "x": "23124.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C395",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2420",
        "Text_Position": {
            "x": "23255.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C396",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2432",
        "Text_Position": {
            "x": "23255.4",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C397",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23366.8",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2434",
        "Text_Position": {
            "x": "23255.4",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C398",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23259.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23365.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2446",
        "Text_Position": {
            "x": "23255.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "23348.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C399",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15286",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "133",
        "Text_Position": {
            "x": "15204.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C39A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15286",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "135",
        "Text_Position": {
            "x": "15204.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C39B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-1519.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-1489.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "232",
        "Text_Position": {
            "x": "15358.7",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-1519.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C39C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-1399.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15441.5",
                "y": "-1369.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "234",
        "Text_Position": {
            "x": "15358.7",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-1399.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C39D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1910",
        "Text_Position": {
            "x": "21455.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C39E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21786",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1911",
        "Text_Position": {
            "x": "21684.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C39F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2010",
        "Text_Position": {
            "x": "21815.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3A0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2006",
        "Text_Position": {
            "x": "21815.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3A1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2008",
        "Text_Position": {
            "x": "21815.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3A2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16717.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "551",
        "Text_Position": {
            "x": "16644.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3A3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "553",
        "Text_Position": {
            "x": "16644.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3A4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "555",
        "Text_Position": {
            "x": "16644.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3A5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "557",
        "Text_Position": {
            "x": "16644.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3A6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16724.6",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "559",
        "Text_Position": {
            "x": "16644.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3A7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16717.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "561",
        "Text_Position": {
            "x": "16644.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3A8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "563",
        "Text_Position": {
            "x": "16644.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3A9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "650",
        "Text_Position": {
            "x": "16798.7",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3AA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "652",
        "Text_Position": {
            "x": "16798.7",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3AB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "653",
        "Text_Position": {
            "x": "17004.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3AC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16881.5",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "654",
        "Text_Position": {
            "x": "16798.7",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3AD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "656",
        "Text_Position": {
            "x": "16798.7",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3AE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "658",
        "Text_Position": {
            "x": "16798.7",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3AF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17084.6",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "659",
        "Text_Position": {
            "x": "17004.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3B0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "660",
        "Text_Position": {
            "x": "16798.7",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3B1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17077.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "661",
        "Text_Position": {
            "x": "17004.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3B2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "662",
        "Text_Position": {
            "x": "16798.7",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3B3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "280.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "310.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "663",
        "Text_Position": {
            "x": "17004.6",
            "y": "280.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3B4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "847",
        "Text_Position": {
            "x": "17724.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3B5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "946",
        "Text_Position": {
            "x": "17878.7",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3B6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "947",
        "Text_Position": {
            "x": "18084.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3B7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1046",
        "Text_Position": {
            "x": "18215.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3B8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2253",
        "Text_Position": {
            "x": "22764.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3B9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2352",
        "Text_Position": {
            "x": "22895.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3BA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16161.5",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "424",
        "Text_Position": {
            "x": "16078.7",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3BB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "426",
        "Text_Position": {
            "x": "16078.7",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3BC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1912",
        "Text_Position": {
            "x": "21455.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3BD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1913",
        "Text_Position": {
            "x": "21684.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3BE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21566.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1914",
        "Text_Position": {
            "x": "21455.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3BF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1915",
        "Text_Position": {
            "x": "21684.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3C0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2012",
        "Text_Position": {
            "x": "21815.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3C1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21926.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2014",
        "Text_Position": {
            "x": "21815.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3C2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17804.6",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "849",
        "Text_Position": {
            "x": "17724.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3C3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17797.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "851",
        "Text_Position": {
            "x": "17724.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3C4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "853",
        "Text_Position": {
            "x": "17724.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3C5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "855",
        "Text_Position": {
            "x": "17724.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3C6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "857",
        "Text_Position": {
            "x": "17724.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3C7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17804.6",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "859",
        "Text_Position": {
            "x": "17724.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3C8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17797.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "861",
        "Text_Position": {
            "x": "17724.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3C9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "948",
        "Text_Position": {
            "x": "17878.7",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3CA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18164.6",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "949",
        "Text_Position": {
            "x": "18084.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3CB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "950",
        "Text_Position": {
            "x": "17878.7",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3CC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18157.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "951",
        "Text_Position": {
            "x": "18084.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3CD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "952",
        "Text_Position": {
            "x": "17878.7",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3CE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "953",
        "Text_Position": {
            "x": "18084.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3CF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17961.5",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "954",
        "Text_Position": {
            "x": "17878.7",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3D0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "955",
        "Text_Position": {
            "x": "18084.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3D1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "956",
        "Text_Position": {
            "x": "17878.7",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3D2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "957",
        "Text_Position": {
            "x": "18084.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3D3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "958",
        "Text_Position": {
            "x": "17878.7",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3D4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18164.6",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "959",
        "Text_Position": {
            "x": "18084.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3D5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "960",
        "Text_Position": {
            "x": "17878.7",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3D6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18157.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "961",
        "Text_Position": {
            "x": "18084.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3D7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1048",
        "Text_Position": {
            "x": "18215.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3D8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1050",
        "Text_Position": {
            "x": "18215.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3D9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1052",
        "Text_Position": {
            "x": "18215.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3DA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18326.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1054",
        "Text_Position": {
            "x": "18215.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3DB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1056",
        "Text_Position": {
            "x": "18215.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3DC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1058",
        "Text_Position": {
            "x": "18215.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3DD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1060",
        "Text_Position": {
            "x": "18215.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3DE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19633.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1329",
        "Text_Position": {
            "x": "19524.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3DF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19626",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1331",
        "Text_Position": {
            "x": "19524.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3E0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20353.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1519",
        "Text_Position": {
            "x": "20244.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3E1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20346",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1521",
        "Text_Position": {
            "x": "20244.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3E2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1618",
        "Text_Position": {
            "x": "20375.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3E3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1620",
        "Text_Position": {
            "x": "20375.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3E4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "318",
        "Text_Position": {
            "x": "15718.7",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3E5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "320",
        "Text_Position": {
            "x": "15718.7",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3E6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "350",
        "Text_Position": {
            "x": "15718.7",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3E7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15722.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15800.1",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "352",
        "Text_Position": {
            "x": "15718.7",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15788.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3E8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16357.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "411",
        "Text_Position": {
            "x": "16284.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3E9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "413",
        "Text_Position": {
            "x": "16284.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3EA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "510",
        "Text_Position": {
            "x": "16438.7",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3EB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "512",
        "Text_Position": {
            "x": "16438.7",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3EC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "416",
        "Text_Position": {
            "x": "16078.7",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3ED",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "418",
        "Text_Position": {
            "x": "16078.7",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3EE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "428",
        "Text_Position": {
            "x": "16078.7",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3EF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "430",
        "Text_Position": {
            "x": "16078.7",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3F0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16364.6",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "429",
        "Text_Position": {
            "x": "16284.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3F1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16357.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "431",
        "Text_Position": {
            "x": "16284.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3F2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "528",
        "Text_Position": {
            "x": "16438.7",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3F3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "530",
        "Text_Position": {
            "x": "16438.7",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3F4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "436",
        "Text_Position": {
            "x": "16078.7",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3F5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "438",
        "Text_Position": {
            "x": "16078.7",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3F6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "437",
        "Text_Position": {
            "x": "16284.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3F7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16364.6",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "439",
        "Text_Position": {
            "x": "16284.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3F8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "536",
        "Text_Position": {
            "x": "16438.7",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3F9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "538",
        "Text_Position": {
            "x": "16438.7",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3FA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16161.5",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "444",
        "Text_Position": {
            "x": "16078.7",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3FB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "446",
        "Text_Position": {
            "x": "16078.7",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3FC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "448",
        "Text_Position": {
            "x": "16078.7",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3FD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "445",
        "Text_Position": {
            "x": "16284.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3FE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16288.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16366",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "447",
        "Text_Position": {
            "x": "16284.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C3FF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "456",
        "Text_Position": {
            "x": "16078.7",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C400",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16082.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16160.1",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "458",
        "Text_Position": {
            "x": "16078.7",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16148.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C401",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "502",
        "Text_Position": {
            "x": "16438.7",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C402",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16521.5",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "504",
        "Text_Position": {
            "x": "16438.7",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C403",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "507",
        "Text_Position": {
            "x": "16644.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C404",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16724.6",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "509",
        "Text_Position": {
            "x": "16644.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C405",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16717.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "511",
        "Text_Position": {
            "x": "16644.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C406",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "606",
        "Text_Position": {
            "x": "16798.7",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C407",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "608",
        "Text_Position": {
            "x": "16798.7",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C408",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "610",
        "Text_Position": {
            "x": "16798.7",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C409",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "516",
        "Text_Position": {
            "x": "16438.7",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C40A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16442.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16520.1",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "518",
        "Text_Position": {
            "x": "16438.7",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16508.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C40B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "523",
        "Text_Position": {
            "x": "16644.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C40C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "525",
        "Text_Position": {
            "x": "16644.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C40D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "622",
        "Text_Position": {
            "x": "16798.7",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C40E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16881.5",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "624",
        "Text_Position": {
            "x": "16798.7",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C40F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16717.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "531",
        "Text_Position": {
            "x": "16644.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C410",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "630",
        "Text_Position": {
            "x": "16798.7",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C411",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16726",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "537",
        "Text_Position": {
            "x": "16644.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C412",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16648.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16724.6",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "539",
        "Text_Position": {
            "x": "16644.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C413",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "636",
        "Text_Position": {
            "x": "16798.7",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C414",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "16804.4",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "16880.1",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "638",
        "Text_Position": {
            "x": "16798.7",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "16868.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C415",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "617",
        "Text_Position": {
            "x": "17004.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C416",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17084.6",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "619",
        "Text_Position": {
            "x": "17004.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C417",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "625",
        "Text_Position": {
            "x": "17004.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C418",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "627",
        "Text_Position": {
            "x": "17004.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C419",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17077.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "631",
        "Text_Position": {
            "x": "17004.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C41A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "730",
        "Text_Position": {
            "x": "17158.7",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C41B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "637",
        "Text_Position": {
            "x": "17004.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C41C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "736",
        "Text_Position": {
            "x": "17158.7",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C41D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17084.6",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "649",
        "Text_Position": {
            "x": "17004.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C41E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17077.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "651",
        "Text_Position": {
            "x": "17004.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C41F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "748",
        "Text_Position": {
            "x": "17158.7",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C420",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "750",
        "Text_Position": {
            "x": "17158.7",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C421",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "655",
        "Text_Position": {
            "x": "17004.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C422",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17010.3",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17086",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "657",
        "Text_Position": {
            "x": "17004.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C423",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17437.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "701",
        "Text_Position": {
            "x": "17364.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C424",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "703",
        "Text_Position": {
            "x": "17364.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C425",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "710",
        "Text_Position": {
            "x": "17158.7",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C426",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "712",
        "Text_Position": {
            "x": "17158.7",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C427",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17437.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "711",
        "Text_Position": {
            "x": "17364.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C428",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "713",
        "Text_Position": {
            "x": "17364.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C429",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "715",
        "Text_Position": {
            "x": "17364.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C42A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "810",
        "Text_Position": {
            "x": "17518.7",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C42B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "812",
        "Text_Position": {
            "x": "17518.7",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C42C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17601.5",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "814",
        "Text_Position": {
            "x": "17518.7",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C42D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17444.6",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "729",
        "Text_Position": {
            "x": "17364.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C42E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17437.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "731",
        "Text_Position": {
            "x": "17364.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C42F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "828",
        "Text_Position": {
            "x": "17518.7",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C430",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "830",
        "Text_Position": {
            "x": "17518.7",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C431",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17446",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "737",
        "Text_Position": {
            "x": "17364.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C432",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17368.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17444.6",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "739",
        "Text_Position": {
            "x": "17364.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C433",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17240.1",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "742",
        "Text_Position": {
            "x": "17158.7",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C434",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17162.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17241.5",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "744",
        "Text_Position": {
            "x": "17158.7",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17228.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C435",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "800",
        "Text_Position": {
            "x": "17518.7",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C436",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "802",
        "Text_Position": {
            "x": "17518.7",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C437",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "807",
        "Text_Position": {
            "x": "17724.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C438",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17804.6",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "809",
        "Text_Position": {
            "x": "17724.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C439",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "820",
        "Text_Position": {
            "x": "17518.7",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C43A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17522.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17600.1",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "822",
        "Text_Position": {
            "x": "17518.7",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17588.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C43B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17797.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "821",
        "Text_Position": {
            "x": "17724.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C43C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "823",
        "Text_Position": {
            "x": "17724.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C43D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "827",
        "Text_Position": {
            "x": "17724.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C43E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17804.6",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "829",
        "Text_Position": {
            "x": "17724.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C43F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17797.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "831",
        "Text_Position": {
            "x": "17724.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C440",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "926",
        "Text_Position": {
            "x": "17878.7",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C441",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "928",
        "Text_Position": {
            "x": "17878.7",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C442",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "930",
        "Text_Position": {
            "x": "17878.7",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C443",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17806",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "837",
        "Text_Position": {
            "x": "17724.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C444",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17728.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17804.6",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "839",
        "Text_Position": {
            "x": "17724.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C445",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "936",
        "Text_Position": {
            "x": "17878.7",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C446",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "938",
        "Text_Position": {
            "x": "17878.7",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C447",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17960.1",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "902",
        "Text_Position": {
            "x": "17878.7",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C448",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "17882.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "17961.5",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "904",
        "Text_Position": {
            "x": "17878.7",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "17948.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C449",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "907",
        "Text_Position": {
            "x": "18084.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C44A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18164.6",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "909",
        "Text_Position": {
            "x": "18084.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C44B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "923",
        "Text_Position": {
            "x": "18084.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C44C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "925",
        "Text_Position": {
            "x": "18084.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C44D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1022",
        "Text_Position": {
            "x": "18215.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C44E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18326.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1024",
        "Text_Position": {
            "x": "18215.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C44F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18164.6",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "929",
        "Text_Position": {
            "x": "18084.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C450",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18157.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "931",
        "Text_Position": {
            "x": "18084.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C451",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18088.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18166",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "937",
        "Text_Position": {
            "x": "18084.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C452",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1036",
        "Text_Position": {
            "x": "18215.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C453",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1000",
        "Text_Position": {
            "x": "18215.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C454",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1002",
        "Text_Position": {
            "x": "18215.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C455",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1007",
        "Text_Position": {
            "x": "18444.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C456",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18553.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1009",
        "Text_Position": {
            "x": "18444.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C457",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1012",
        "Text_Position": {
            "x": "18215.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C458",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18326.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1014",
        "Text_Position": {
            "x": "18215.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C459",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1017",
        "Text_Position": {
            "x": "18444.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C45A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18553.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1019",
        "Text_Position": {
            "x": "18444.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C45B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1116",
        "Text_Position": {
            "x": "18575.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C45C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1118",
        "Text_Position": {
            "x": "18575.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C45D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18453.2",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18554.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1037",
        "Text_Position": {
            "x": "18444.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C45E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1136",
        "Text_Position": {
            "x": "18575.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C45F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18325.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1042",
        "Text_Position": {
            "x": "18215.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C460",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18223.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18326.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1044",
        "Text_Position": {
            "x": "18215.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18308.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C461",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1103",
        "Text_Position": {
            "x": "18804.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C462",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1105",
        "Text_Position": {
            "x": "18804.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C463",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1110",
        "Text_Position": {
            "x": "18575.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C464",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1112",
        "Text_Position": {
            "x": "18575.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C465",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1128",
        "Text_Position": {
            "x": "18575.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C466",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1130",
        "Text_Position": {
            "x": "18575.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C467",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18906",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1131",
        "Text_Position": {
            "x": "18804.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C468",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1230",
        "Text_Position": {
            "x": "18935.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C469",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1140",
        "Text_Position": {
            "x": "18575.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C46A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18583.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18685.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1142",
        "Text_Position": {
            "x": "18575.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "18668.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C46B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18906",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1141",
        "Text_Position": {
            "x": "18804.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C46C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18813.2",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "18914.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1143",
        "Text_Position": {
            "x": "18804.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C46D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1207",
        "Text_Position": {
            "x": "19164.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C46E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19273.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1209",
        "Text_Position": {
            "x": "19164.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C46F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1212",
        "Text_Position": {
            "x": "18935.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C470",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19046.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1214",
        "Text_Position": {
            "x": "18935.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C471",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1223",
        "Text_Position": {
            "x": "19164.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C472",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1225",
        "Text_Position": {
            "x": "19164.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C473",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1227",
        "Text_Position": {
            "x": "19164.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C474",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19273.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1229",
        "Text_Position": {
            "x": "19164.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C475",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19266",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1231",
        "Text_Position": {
            "x": "19164.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C476",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1322",
        "Text_Position": {
            "x": "19295.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C477",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19406.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1324",
        "Text_Position": {
            "x": "19295.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C478",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1326",
        "Text_Position": {
            "x": "19295.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C479",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1328",
        "Text_Position": {
            "x": "19295.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C47A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1330",
        "Text_Position": {
            "x": "19295.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C47B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19274.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1237",
        "Text_Position": {
            "x": "19164.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C47C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19173.2",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19273.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1239",
        "Text_Position": {
            "x": "19164.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C47D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1336",
        "Text_Position": {
            "x": "19295.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C47E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1338",
        "Text_Position": {
            "x": "19295.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C47F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1246",
        "Text_Position": {
            "x": "18935.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C480",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "18943.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19045.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1248",
        "Text_Position": {
            "x": "18935.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19028.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C481",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19626",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1301",
        "Text_Position": {
            "x": "19524.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C482",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1303",
        "Text_Position": {
            "x": "19524.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C483",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1400",
        "Text_Position": {
            "x": "19655.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C484",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1402",
        "Text_Position": {
            "x": "19655.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C485",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1307",
        "Text_Position": {
            "x": "19524.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C486",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19633.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1309",
        "Text_Position": {
            "x": "19524.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C487",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1310",
        "Text_Position": {
            "x": "19295.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C488",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1312",
        "Text_Position": {
            "x": "19295.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C489",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1316",
        "Text_Position": {
            "x": "19295.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C48A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1318",
        "Text_Position": {
            "x": "19295.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C48B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1317",
        "Text_Position": {
            "x": "19524.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C48C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19633.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1319",
        "Text_Position": {
            "x": "19524.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C48D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1416",
        "Text_Position": {
            "x": "19655.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C48E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1418",
        "Text_Position": {
            "x": "19655.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C48F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1346",
        "Text_Position": {
            "x": "19295.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C490",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1348",
        "Text_Position": {
            "x": "19295.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C491",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1347",
        "Text_Position": {
            "x": "19524.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C492",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19633.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1349",
        "Text_Position": {
            "x": "19524.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C493",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1446",
        "Text_Position": {
            "x": "19655.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C494",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1448",
        "Text_Position": {
            "x": "19655.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C495",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1353",
        "Text_Position": {
            "x": "19524.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C496",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19634.6",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1355",
        "Text_Position": {
            "x": "19524.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C497",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1358",
        "Text_Position": {
            "x": "19295.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C498",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19303.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19405.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1360",
        "Text_Position": {
            "x": "19295.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19388.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C499",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19633.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1359",
        "Text_Position": {
            "x": "19524.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C49A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19533.2",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19626",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1361",
        "Text_Position": {
            "x": "19524.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C49B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1458",
        "Text_Position": {
            "x": "19655.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C49C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1460",
        "Text_Position": {
            "x": "19655.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C49D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19986",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1401",
        "Text_Position": {
            "x": "19884.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C49E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1403",
        "Text_Position": {
            "x": "19884.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C49F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1405",
        "Text_Position": {
            "x": "19884.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4A0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1407",
        "Text_Position": {
            "x": "19884.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4A1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1500",
        "Text_Position": {
            "x": "20015.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4A2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1502",
        "Text_Position": {
            "x": "20015.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4A3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20126.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1504",
        "Text_Position": {
            "x": "20015.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4A4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1506",
        "Text_Position": {
            "x": "20015.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4A5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19986",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1411",
        "Text_Position": {
            "x": "19884.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4A6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1413",
        "Text_Position": {
            "x": "19884.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4A7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1422",
        "Text_Position": {
            "x": "19655.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4A8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1423",
        "Text_Position": {
            "x": "19884.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4A9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19766.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1424",
        "Text_Position": {
            "x": "19655.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4AA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1425",
        "Text_Position": {
            "x": "19884.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4AB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1426",
        "Text_Position": {
            "x": "19655.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4AC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1427",
        "Text_Position": {
            "x": "19884.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4AD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1428",
        "Text_Position": {
            "x": "19655.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4AE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19993.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1429",
        "Text_Position": {
            "x": "19884.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4AF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19663.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19765.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1430",
        "Text_Position": {
            "x": "19655.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "19748.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4B0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19986",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1431",
        "Text_Position": {
            "x": "19884.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4B1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1522",
        "Text_Position": {
            "x": "20015.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4B2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1523",
        "Text_Position": {
            "x": "20244.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4B3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20126.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1524",
        "Text_Position": {
            "x": "20015.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4B4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1525",
        "Text_Position": {
            "x": "20244.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4B5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1526",
        "Text_Position": {
            "x": "20015.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4B6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1527",
        "Text_Position": {
            "x": "20244.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4B7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1528",
        "Text_Position": {
            "x": "20015.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4B8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20353.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1529",
        "Text_Position": {
            "x": "20244.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4B9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1530",
        "Text_Position": {
            "x": "20015.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4BA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20346",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1531",
        "Text_Position": {
            "x": "20244.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4BB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1622",
        "Text_Position": {
            "x": "20375.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4BC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20486.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1624",
        "Text_Position": {
            "x": "20375.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4BD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1626",
        "Text_Position": {
            "x": "20375.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4BE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1628",
        "Text_Position": {
            "x": "20375.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4BF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1630",
        "Text_Position": {
            "x": "20375.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4C0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19994.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1437",
        "Text_Position": {
            "x": "19884.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4C1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19993.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1439",
        "Text_Position": {
            "x": "19884.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4C2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1536",
        "Text_Position": {
            "x": "20015.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4C3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1538",
        "Text_Position": {
            "x": "20015.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4C4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19993.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1459",
        "Text_Position": {
            "x": "19884.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4C5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "19893.2",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "19986",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1461",
        "Text_Position": {
            "x": "19884.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4C6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1558",
        "Text_Position": {
            "x": "20015.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4C7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20023.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20125.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1560",
        "Text_Position": {
            "x": "20015.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20108.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4C8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20346",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1501",
        "Text_Position": {
            "x": "20244.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4C9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1503",
        "Text_Position": {
            "x": "20244.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4CA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1505",
        "Text_Position": {
            "x": "20244.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4CB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1507",
        "Text_Position": {
            "x": "20244.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4CC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1600",
        "Text_Position": {
            "x": "20375.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4CD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1602",
        "Text_Position": {
            "x": "20375.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4CE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20486.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1604",
        "Text_Position": {
            "x": "20375.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4CF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1606",
        "Text_Position": {
            "x": "20375.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4D0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20346",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1511",
        "Text_Position": {
            "x": "20244.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4D1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1513",
        "Text_Position": {
            "x": "20244.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4D2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1515",
        "Text_Position": {
            "x": "20244.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4D3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1610",
        "Text_Position": {
            "x": "20375.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4D4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1612",
        "Text_Position": {
            "x": "20375.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4D5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20486.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1614",
        "Text_Position": {
            "x": "20375.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4D6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1537",
        "Text_Position": {
            "x": "20244.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4D7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20353.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1539",
        "Text_Position": {
            "x": "20244.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4D8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20346",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1541",
        "Text_Position": {
            "x": "20244.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4D9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1543",
        "Text_Position": {
            "x": "20244.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4DA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1545",
        "Text_Position": {
            "x": "20244.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4DB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20354.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1547",
        "Text_Position": {
            "x": "20244.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4DC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20353.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1549",
        "Text_Position": {
            "x": "20244.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4DD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20346",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1551",
        "Text_Position": {
            "x": "20244.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4DE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1636",
        "Text_Position": {
            "x": "20375.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4DF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1638",
        "Text_Position": {
            "x": "20375.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4E0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-1039.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-1009.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1640",
        "Text_Position": {
            "x": "20375.4",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-1039.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4E1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1642",
        "Text_Position": {
            "x": "20375.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4E2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20486.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1644",
        "Text_Position": {
            "x": "20375.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4E3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1646",
        "Text_Position": {
            "x": "20375.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4E4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1648",
        "Text_Position": {
            "x": "20375.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4E5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1650",
        "Text_Position": {
            "x": "20375.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4E6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20353.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1559",
        "Text_Position": {
            "x": "20244.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4E7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20253.2",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20346",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1561",
        "Text_Position": {
            "x": "20244.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4E8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20486.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1654",
        "Text_Position": {
            "x": "20375.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4E9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20383.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20485.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1656",
        "Text_Position": {
            "x": "20375.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20468.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4EA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1700",
        "Text_Position": {
            "x": "20735.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4EB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1702",
        "Text_Position": {
            "x": "20735.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4EC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20846.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1704",
        "Text_Position": {
            "x": "20735.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4ED",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1706",
        "Text_Position": {
            "x": "20735.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4EE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1710",
        "Text_Position": {
            "x": "20735.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4EF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1712",
        "Text_Position": {
            "x": "20735.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4F0",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20846.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1714",
        "Text_Position": {
            "x": "20735.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4F1",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1718",
        "Text_Position": {
            "x": "20735.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4F2",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1720",
        "Text_Position": {
            "x": "20735.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4F3",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1722",
        "Text_Position": {
            "x": "20735.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4F4",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20846.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1724",
        "Text_Position": {
            "x": "20735.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4F5",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1728",
        "Text_Position": {
            "x": "20735.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4F6",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1730",
        "Text_Position": {
            "x": "20735.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4F7",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1736",
        "Text_Position": {
            "x": "20735.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4F8",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21074.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1737",
        "Text_Position": {
            "x": "20964.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4F9",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1738",
        "Text_Position": {
            "x": "20735.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4FA",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20973.2",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21073.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1739",
        "Text_Position": {
            "x": "20964.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4FB",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1836",
        "Text_Position": {
            "x": "21095.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4FC",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1838",
        "Text_Position": {
            "x": "21095.4",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4FD",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1742",
        "Text_Position": {
            "x": "20735.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4FE",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20846.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1744",
        "Text_Position": {
            "x": "20735.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C4FF",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1746",
        "Text_Position": {
            "x": "20735.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C500",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1748",
        "Text_Position": {
            "x": "20735.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C501",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1752",
        "Text_Position": {
            "x": "20735.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C502",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20846.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1754",
        "Text_Position": {
            "x": "20735.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C503",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1756",
        "Text_Position": {
            "x": "20735.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C504",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1758",
        "Text_Position": {
            "x": "20735.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C505",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "20743.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "20845.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1760",
        "Text_Position": {
            "x": "20735.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "20828.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C506",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1800",
        "Text_Position": {
            "x": "21095.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C507",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1802",
        "Text_Position": {
            "x": "21095.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C508",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21206.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1804",
        "Text_Position": {
            "x": "21095.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C509",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1806",
        "Text_Position": {
            "x": "21095.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C50A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1810",
        "Text_Position": {
            "x": "21095.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C50B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1812",
        "Text_Position": {
            "x": "21095.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C50C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21206.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1814",
        "Text_Position": {
            "x": "21095.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C50D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1818",
        "Text_Position": {
            "x": "21095.4",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C50E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1820",
        "Text_Position": {
            "x": "21095.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C50F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1822",
        "Text_Position": {
            "x": "21095.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C510",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1825",
        "Text_Position": {
            "x": "21324.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C511",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21434.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1827",
        "Text_Position": {
            "x": "21324.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C512",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21433.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1829",
        "Text_Position": {
            "x": "21324.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C513",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21426",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1831",
        "Text_Position": {
            "x": "21324.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C514",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21566.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1924",
        "Text_Position": {
            "x": "21455.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C515",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1926",
        "Text_Position": {
            "x": "21455.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C516",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1928",
        "Text_Position": {
            "x": "21455.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C517",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1930",
        "Text_Position": {
            "x": "21455.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C518",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1826",
        "Text_Position": {
            "x": "21095.4",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C519",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1828",
        "Text_Position": {
            "x": "21095.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C51A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1830",
        "Text_Position": {
            "x": "21095.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C51B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1842",
        "Text_Position": {
            "x": "21095.4",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C51C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21206.8",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1844",
        "Text_Position": {
            "x": "21095.4",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C51D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1846",
        "Text_Position": {
            "x": "21095.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C51E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1848",
        "Text_Position": {
            "x": "21095.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C51F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1852",
        "Text_Position": {
            "x": "21095.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C520",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21206.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1854",
        "Text_Position": {
            "x": "21095.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C521",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1858",
        "Text_Position": {
            "x": "21095.4",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C522",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21103.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21205.4",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1860",
        "Text_Position": {
            "x": "21095.4",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21188.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C523",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21433.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1859",
        "Text_Position": {
            "x": "21324.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C524",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21333.2",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21426",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1861",
        "Text_Position": {
            "x": "21324.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C525",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1900",
        "Text_Position": {
            "x": "21455.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C526",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21786",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1901",
        "Text_Position": {
            "x": "21684.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C527",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1902",
        "Text_Position": {
            "x": "21455.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C528",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1903",
        "Text_Position": {
            "x": "21684.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C529",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2000",
        "Text_Position": {
            "x": "21815.4",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C52A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2002",
        "Text_Position": {
            "x": "21815.4",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C52B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1906",
        "Text_Position": {
            "x": "21455.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C52C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1907",
        "Text_Position": {
            "x": "21684.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C52D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1908",
        "Text_Position": {
            "x": "21455.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C52E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21793.2",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1909",
        "Text_Position": {
            "x": "21684.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C52F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21793.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1919",
        "Text_Position": {
            "x": "21684.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C530",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21786",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1921",
        "Text_Position": {
            "x": "21684.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C531",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21793.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1929",
        "Text_Position": {
            "x": "21684.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C532",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21786",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1931",
        "Text_Position": {
            "x": "21684.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C533",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1937",
        "Text_Position": {
            "x": "21684.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C534",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2036",
        "Text_Position": {
            "x": "21815.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C535",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-919.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-889.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1943",
        "Text_Position": {
            "x": "21684.6",
            "y": "-919.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C536",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-799.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21794.6",
                "y": "-769.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1945",
        "Text_Position": {
            "x": "21684.6",
            "y": "-799.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C537",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1946",
        "Text_Position": {
            "x": "21455.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C538",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1948",
        "Text_Position": {
            "x": "21455.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C539",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21793.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1949",
        "Text_Position": {
            "x": "21684.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C53A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21693.2",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21786",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1951",
        "Text_Position": {
            "x": "21684.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C53B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2048",
        "Text_Position": {
            "x": "21815.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C53C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21819.6",
                "y": "-439.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21925.4",
                "y": "-409.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2050",
        "Text_Position": {
            "x": "21815.4",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21908.6",
            "y": "-439.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C53D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-319.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21565.4",
                "y": "-289.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1952",
        "Text_Position": {
            "x": "21455.4",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-319.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C53E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "21463.9",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "21566.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "1954",
        "Text_Position": {
            "x": "21455.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "21548.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C53F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2025",
        "Text_Position": {
            "x": "22044.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C540",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2027",
        "Text_Position": {
            "x": "22044.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C541",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22146",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2031",
        "Text_Position": {
            "x": "22044.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C542",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2130",
        "Text_Position": {
            "x": "22175.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C543",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22154.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2047",
        "Text_Position": {
            "x": "22044.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C544",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22153.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2049",
        "Text_Position": {
            "x": "22044.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C545",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2146",
        "Text_Position": {
            "x": "22175.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C546",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2148",
        "Text_Position": {
            "x": "22175.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C547",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22153.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2059",
        "Text_Position": {
            "x": "22044.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C548",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22048.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22146",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2061",
        "Text_Position": {
            "x": "22044.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C549",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2105",
        "Text_Position": {
            "x": "22404.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C54A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2107",
        "Text_Position": {
            "x": "22404.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C54B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2106",
        "Text_Position": {
            "x": "22175.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C54C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-2959.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-2929.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2108",
        "Text_Position": {
            "x": "22175.4",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-2959.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C54D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22506",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2111",
        "Text_Position": {
            "x": "22404.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C54E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2113",
        "Text_Position": {
            "x": "22404.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C54F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-2839.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-2809.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2210",
        "Text_Position": {
            "x": "22535.4",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-2839.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C550",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-2719.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-2689.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2212",
        "Text_Position": {
            "x": "22535.4",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-2719.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C551",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-2599.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22286.8",
                "y": "-2569.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2114",
        "Text_Position": {
            "x": "22175.4",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-2599.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C552",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22179.6",
                "y": "-2479.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22285.4",
                "y": "-2449.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2116",
        "Text_Position": {
            "x": "22175.4",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22268.6",
            "y": "-2479.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C553",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-2359.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22513.2",
                "y": "-2329.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2119",
        "Text_Position": {
            "x": "22404.6",
            "y": "-2359.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C554",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22506",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2121",
        "Text_Position": {
            "x": "22404.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C555",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-1879.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-1849.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2127",
        "Text_Position": {
            "x": "22404.6",
            "y": "-1879.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C556",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22513.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2129",
        "Text_Position": {
            "x": "22404.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C557",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22514.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2137",
        "Text_Position": {
            "x": "22404.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C558",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2236",
        "Text_Position": {
            "x": "22535.4",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C559",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "40.2221",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22513.2",
                "y": "70.2221",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2159",
        "Text_Position": {
            "x": "22404.6",
            "y": "40.2221",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C55A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22408.9",
                "y": "160.222",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22506",
                "y": "190.222",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2161",
        "Text_Position": {
            "x": "22404.6",
            "y": "160.222",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C55B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22866",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2221",
        "Text_Position": {
            "x": "22764.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C55C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2223",
        "Text_Position": {
            "x": "22764.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C55D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2225",
        "Text_Position": {
            "x": "22764.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C55E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2320",
        "Text_Position": {
            "x": "22895.4",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C55F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2322",
        "Text_Position": {
            "x": "22895.4",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C560",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23006.8",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2324",
        "Text_Position": {
            "x": "22895.4",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C561",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2228",
        "Text_Position": {
            "x": "22535.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C562",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22539.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22645.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2230",
        "Text_Position": {
            "x": "22535.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22628.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C563",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22873.2",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2229",
        "Text_Position": {
            "x": "22764.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C564",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22866",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2231",
        "Text_Position": {
            "x": "22764.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C565",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-1759.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-1729.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2328",
        "Text_Position": {
            "x": "22895.4",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-1759.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C566",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-1639.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-1609.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2330",
        "Text_Position": {
            "x": "22895.4",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-1639.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C567",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22874.6",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2247",
        "Text_Position": {
            "x": "22764.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C568",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22768.9",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "22873.2",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2249",
        "Text_Position": {
            "x": "22764.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C569",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2346",
        "Text_Position": {
            "x": "22895.4",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C56A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-559.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-529.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2348",
        "Text_Position": {
            "x": "22895.4",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-559.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C56B",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-3439.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23226",
                "y": "-3409.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2301",
        "Text_Position": {
            "x": "23124.6",
            "y": "-3439.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C56C",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-3319.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-3289.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2303",
        "Text_Position": {
            "x": "23124.6",
            "y": "-3319.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C56D",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-3199.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23006.8",
                "y": "-3169.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2304",
        "Text_Position": {
            "x": "22895.4",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-3199.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C56E",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-3079.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-3049.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2306",
        "Text_Position": {
            "x": "22895.4",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-3079.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C56F",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-2119.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-2089.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2323",
        "Text_Position": {
            "x": "23124.6",
            "y": "-2119.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C570",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-1999.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-1969.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2325",
        "Text_Position": {
            "x": "23124.6",
            "y": "-1999.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C571",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-1279.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23234.6",
                "y": "-1249.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2337",
        "Text_Position": {
            "x": "23124.6",
            "y": "-1279.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C572",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "23128.9",
                "y": "-1159.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23233.2",
                "y": "-1129.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2339",
        "Text_Position": {
            "x": "23124.6",
            "y": "-1159.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C573",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-199.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23006.8",
                "y": "-169.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2354",
        "Text_Position": {
            "x": "22895.4",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-199.778",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Text",
        "Handle": "103C574",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "22899.6",
                "y": "-79.7779",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "23005.4",
                "y": "-49.7779",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "2356",
        "Text_Position": {
            "x": "22895.4",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "22988.6",
            "y": "-79.7779",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Polyline",
        "Handle": "103C575",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15196.6",
                "y": "-687.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15316.6",
                "y": "-567.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-567.778",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15196.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-687.778",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-567.778",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15196.6",
                "y": "-567.778",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Text",
        "Handle": "103C577",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15213.2",
                "y": "-679.778",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15286",
                "y": "-649.778",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "147",
        "Text_Position": {
            "x": "15204.6",
            "y": "-679.778",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "0",
            "y": "0",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 0,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    },
    {
        "Type": "Polyline",
        "Handle": "103C579",
        "Layer": "MaxBoothOutline",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "ByLayer",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15316.6",
                "y": "-2247.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15436.6",
                "y": "-2127.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Thickness": 0,
        "Has_Width": false,
        "Constant_Width": 0,
        "Has_Bulges": false,
        "Elevation": 0,
        "Closed": true,
        "Vertices": [
            {
                "Index": 1,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            },
            {
                "Index": 2,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15316.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 3,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-2247.78",
                    "z": "0"
                }
            },
            {
                "Index": 4,
                "Segment_Type": "Line",
                "Location": {
                    "x": "15436.6",
                    "y": "-2127.78",
                    "z": "0"
                }
            }
        ],
        "Feature_Type": {
            "Type": "Square",
            "First_Point": {
                "x": "15316.6",
                "y": "-2127.78",
                "z": "0"
            },
            "X_Distance": 120,
            "Y_Distance": 120,
            "X_Rotation": 0,
            "Normal": 90
        }
    },
    {
        "Type": "Text",
        "Handle": "103C57A",
        "Layer": "MaxBoothNumber",
        "Color_Index": "256",
        "Color": {
            "Color_Method": 0
        },
        "Linetype": "Continuous",
        "Visibility": "kVisible",
        "Entity_Extents": {
            "Minimum_Point": {
                "x": "15362.9",
                "y": "-2239.78",
                "z": "0"
            },
            "Maximum_Point": {
                "x": "15440.1",
                "y": "-2209.78",
                "z": "0"
            }
        },
        "Normal": {
            "x": "0",
            "y": "0",
            "z": "1"
        },
        "Text_String": "220",
        "Text_Position": {
            "x": "15358.7",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Alignment_Point": {
            "x": "15428.6",
            "y": "-2239.78",
            "z": "0"
        },
        "Text_Height": 30,
        "Horizontal_Mode": 2,
        "Vertical_Mode": 0,
        "Width_Factor": 1,
        "Oblique": 0,
        "Text_Style": "Expo_Arial",
        "Rotation": 0
    }
]