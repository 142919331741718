import React ,{ useEffect, useState } from 'react';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { setLeftPage } from '../../../actions/ui'
import { setOpenLogin, setLeftBoothList, setBookmardUpdated, setOpenBoothList } from '../../../actions/ui'
import Exhibitors from "./Exhibitors"
import { setActive3dObject } from '../../../actions/main';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Fade from '@mui/material/Fade';

function Index(props) {
  const [boothList, setBoothList] = useState([]);

  const getBoothList = ()=>{
    var boothList = [];
    if(props.main.mainCanvas){
      var objects = props.main.mainCanvas.getObjects();
      for(var i = 0; i< objects.length; i++){
        var obejct = objects[i]
        var row = {}
        if((obejct.get('class')=="booth" || obejct.get('class')=="booth-group") && obejct.get('sold') ){
          var textobjects = obejct.getObjects('text');
          textobjects.sort(function(a, b) {
            const nameA = a.get('left'); // ignore upper and lowercase
            const nameB = b.get('left'); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          })
          textobjects.sort(function(a, b) {
            const nameA = a.get('left'); // ignore upper and lowercase
            const nameB = b.get('left'); // ignore upper and lowercase
            return nameA - nameB;
          })
          if(textobjects && textobjects[0]){
              var txt = "";
              for(var j = 0; j < textobjects.length; j++){
                if(textobjects[j].get('class')!="companyName")
                  txt+=(txt==""?"":" ")+textobjects[j].get('text')
              }
              row['name'] = txt;
          }
          row['priced'] = false
          var priceCheck = obejct.get('priceCheck');
          var premiumCheck = obejct.get('premiumCheck');
          if(premiumCheck){
            row['priced'] = true
          }
          if(priceCheck && priceCheck.length > 0){
            row['priced'] = true
          }
          if(obejct.get('sold')){
            row['priced'] = true
          }
          var groupId = obejct.get('id');
          row['id'] = groupId?groupId.replace("booth-",""):""
          row['company'] = obejct.get('company')
          row['boothgroup'] = obejct.get('boothGroup')
          row['boothType'] = obejct.get('boothType')
          row['bookmark'] = obejct.get('bookmark')
          row['company_tag_3dbooth'] = obejct.get('company_tag_3dbooth')
          row['booth'] = obejct
          if(row['company']){
            row['name'] = row['name'].replace(row['company'],"")
            row['name'] = row['name'].replace('\n',"")
          }
          boothList.push(row)
        }
      }
    }

    boothList.sort(function(a, b) {
      const nameA = (a.company+" "+a.name).toLowerCase(); // ignore upper and lowercase
      const nameB = (b.company+" "+a.name).toLowerCase(); // ignore upper and lowercase
      
      return `${nameA}`.localeCompare(`${nameB}`)
    });

    boothList = boothList.filter((booth)=>{
      return (booth.company_tag_3dbooth)
    })
    props.setLeftBoothList(boothList)
  }

  useEffect(() => {
    getBoothList();
  }, [props.main.mainCanvas])

  useEffect(() => {
    if(props.ui.bookmarkUpdated){
      getBoothList();
      props.setBookmardUpdated(false)
    }
  }, [props.ui.bookmarkUpdated])


  return (
    <Fade in={true} timeout={1200}>
      <div className='appLeftSearch'>
        <Box display="flex" style={{alignItems:'center', justifyContent:'space-between'}} pt={2}  pb={2}>
          
          <div style={{marginLeft:20}}></div>
          <Box textAlign="left">
            <Box display="flex">
              <h4 style={{textTransform:'capitalize', margin:0}}>
              3D Booths
              </h4> 
              <span style={{color:'#a0a0a0',  marginLeft:5}}>({props.ui.leftBoothList?props.ui.leftBoothList.length:0})</span>
            </Box>
          </Box>
          <div style={{display:'flex', alignItems:'center', paddingRight:3}}>
            {props.role == 'admin' ?          
            <IconButton
                size="small"
                edge="start"
                aria-label="assign"
                style={{float:'right'}}
                onClick={()=>{props.setOpenBoothList(false)}}
              >
                <HighlightOffOutlinedIcon/>
            </IconButton>:
            <IconButton
              size="small"
              edge="start"
              aria-label="assign"
              style={{float:'right'}}
              onClick={()=>{props.setLeftPage('home')}}
            >
              <ChevronLeftIcon/>
            </IconButton>
            }
          </div>
        </Box>
        <Divider/>
        <Exhibitors boothList={boothList} setBoothList={setBoothList} role={props.role}/>
      </div>
    </Fade>
  );
}

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
    main: state.main,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return { 
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
    setLeftBoothList:  data=>dispatch(setLeftBoothList(data)),
    setBookmardUpdated:   data=>dispatch(setBookmardUpdated(data)),
    setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
    setActive3dObject: value=>dispatch(setActive3dObject(value)) ,
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
