import React ,{ useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers, setGridSize, setGridAngle, setShowGrid, setUnit } from '../../../actions/main'
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, setObjectBG} from '../../../utils/CanvasTool'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import { fabric } from "fabric";
// import Cropper from 'react-easy-crop'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import getCroppedImg from '../../utils'
import { API_URL, S3_URL } from '../../../config.js'
import { setLoading } from '../../../actions/main'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {PaperComponent} from '../../../utils/Common'

function BackgroundModal(props) {
  const { onClose,  onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [imgSrc, setImgSrc] = useState('')
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const cropperRef = useRef(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    // console.log(croppedArea, croppedAreaPixels)
  }, [])

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    // console.log(cropper.getCroppedCanvas().toDataURL());
  };

  useEffect(() => {
    if(open){
      setImgSrc(null)
    }
  }, [open])
  

  const onOk =async (e)=>{
    // const croppedImage = await getCroppedImg(
    //   imgSrc,
    //   croppedAreaPixels,
    //   0
    // )
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.getCroppedCanvas().toBlob(async (croppedImage)=> {
      var filename = "bg.png"
      // const reader = new FileReader();
      var data = croppedImage.slice(0, croppedImage.size);
      var filePath = `asset/${uuidv4()}`
      const formData = new FormData();
      const fileType = croppedImage.type || "image/png"; 
      const blob = croppedImage.type ? croppedImage : new Blob([croppedImage], { type: fileType });
      formData.append('file', blob, 'img.png'); 
      formData.append('filePath', filePath);
      var url = `${S3_URL()}uploadfile`
      props.setLoading(true)
      const headers = {headers: {'Content-Type': 'multipart/form-data'}};
      try{
        const response = await axios.post(url, formData, headers);
        if(response.status == 200){
          var target = props.main.menuControl.target;
          var objectImgs = target.getObjects('image');
          if(objectImgs){
            for(var i = 0; i < objectImgs.length; i++){
              target.remove(objectImgs[i])
            }
          }
          if(target){
            fabric.Image.fromURL(`${S3_URL()}s3/${filePath}`, function(img) {
              console.log("img", img)
              img.set('left', 0-target.width/2).set('top', 0-target.height/2).set({scaleX: target.width/img.width, scaleY: target.height/img.height});
              target.add(img);              
              img.sendToBack();
              setObjectBG(props.main.mainCanvas, target, 'transparent')
              props.main.mainCanvas.renderAll();
            });
          }
        }
      }
      catch(e){
      }
      props.setLoading(false)
  
      
      onClose()
    });

    
  }

  const onSelectFile = (e)=> {
    if (e.target.files && e.target.files.length > 0) {
      setCrop({ x: 0, y: 0 }) // Makes crop preview update between images.
      setZoom(1)
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        setImgSrc(reader.result.toString() || '')
        e.target.value=null;
      }
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  return (
    <Dialog 
      PaperComponent={(props)=>PaperComponent(props, 'background-dialog-title')}
      aria-labelledby="background-dialog-title"
      fullWidth={true}
      maxWidth={"md"}
      onClose={onClose} open={open}>
      <DialogTitle id='background-dialog-title' style={{display:'flex', alignItems:'center'}}><AddPhotoAlternateIcon style={{marginRight: 10}}/> Background</DialogTitle>
      <DialogContent>
        <Box>
          <input type="file" accept="image/*" onChange={onSelectFile} />
          <Box mt={1} style={{position:'relative', width: '100%', height:'500px'}}>
            {Boolean(imgSrc) && 
              // <Cropper
              //   image={imgSrc}
              //   crop={crop}
              //   zoom={zoom}
              //   aspect={4 / 4}
              //   onCropChange={setCrop}
              //   onCropComplete={onCropComplete}
              //   onZoomChange={setZoom}
              // />
              // <ReactCrop 
              // style={{ height: 400, width: "100%" }}
              //   crop={crop} 
              //   onChange={c => setCrop(c)}
              // >
              //   <img src={imgSrc} />
              // </ReactCrop>
              <Cropper
                src={imgSrc}
                style={{ height: 450, width: "100%" }}
                // Cropper.js options
                aspectRatio={props.main.menuControl.target?(props.main.menuControl.target.width /props.main.menuControl.target.height ) :(16 / 9)}
                initialAspectRatio={props.main.menuControl.target?(props.main.menuControl.target.width /props.main.menuControl.target.height ) :(16 / 9)}
                guides={false}
                crop={onCrop}
                ref={cropperRef}
              />
            }
            
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setLoading:  data=>dispatch(setLoading(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(BackgroundModal);