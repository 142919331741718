import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { setLeftSearchVal, setOpenBoothList, setLeftPage, setOpenSetBoothModal, setOpenBoothDrawer } from '../../../actions/ui'
import InputBase from '@mui/material/InputBase';
import { useEffect,useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import  area  from 'area-polygon'
import Draggable from 'react-draggable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setActive3dObject } from '../../../actions/main';
import { px2unit } from '../../../utils/CanvasTool'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function BoothList(props) {
  const [boothList, setBoothList] = React.useState([]);
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [filteredBoothList, setFilteredBoothList] = React.useState([]);
  const [filteredBoothListDeleted, setFilteredBoothListDeleted] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState(null);
  const [tabValue, setTabValue] = React.useState(0);
  const [totalOrgQty, setTotalOrgQty] = React.useState(0);
  const [totalOrgSqft, setTotalOrgSqft] = React.useState(0);
  var mainCanvas = props.main.mainCanvas  

  useEffect(() => {
    if(props.ui.openBoothList){
      setLoadingFlag(true)
      setTimeout(function(){
        if(props.main.mainCanvas){
          var boothList = [];
          var objects = props.main.mainCanvas.getObjects('group');
          var convertRateX= 1
          if(props.main.mainCanvas.convertRateX) {
              var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
          }
          for(var i = 0; i< objects.length; i++){
            var object = objects[i]
            var row = {}
            if(object.get('class')=="booth"){
              row['name'] = object.booth_number
              row['priced'] = false
              var priceCheck = object.get('priceCheck');
              var premiumCheck = object.get('premiumCheck');
              if(premiumCheck){
                row['priced'] = true
              }
              if(priceCheck && priceCheck.length > 0){
                row['priced'] = true
              }
              if(object.get('sold')){
                row['priced'] = true
              }
              row['company'] = object.get('company')
              row['boothgroup'] = object.get('boothGroup')
              row['boothType'] = object.get('boothType')
              var booth_width = px2unit(object.width, convertRateX, mainCanvas.mapUnit).toFixed(0)
              var booth_height = px2unit(object.height, convertRateX, mainCanvas.mapUnit).toFixed(0)
              row['boothsize'] = booth_width+" x "+ booth_height
              var sqrt = 0;
              if(object.class1 == "custom-booth"){
                var objects = object.getObjects('polyline');
                if(objects && objects.length > 0 ){
                    var points = objects[0].points;
                    var polyline = [];
                    for(var i = 0; i < points.length; i++){
                      polyline.push([px2unit(points[i].x, convertRateX, mainCanvas.mapUnit), px2unit(points[i].y, convertRateX, mainCanvas.mapUnit)])
                    }
                    sqrt = area(polyline)
                }
              }
              else{
                  sqrt = (booth_width*booth_height)
              }
              row['sqrt'] = (sqrt*1).toFixed(0)
              row['booth'] = object
             
              boothList.push(row)
            }
            else if(object.get('class')=="original-booth"){
              var booth_width = px2unit(object.width, convertRateX, mainCanvas.mapUnit).toFixed(0)
              var booth_height = px2unit(object.height, convertRateX, mainCanvas.mapUnit).toFixed(0)
              var sqrt = 0;
              if(object.class1 == "custom-booth"){
                var objects = object.getObjects('polyline');
                if(objects && objects.length > 0 ){
                    var points = objects[0].points;
                    var polyline = [];
                    for(var i = 0; i < points.length; i++){
                      polyline.push([px2unit(points[i].x, convertRateX, mainCanvas.mapUnit), px2unit(points[i].y, convertRateX, mainCanvas.mapUnit)])
                    }
                    sqrt = area(polyline)
                }
              }
              else{
                  sqrt = (booth_width*booth_height)
              }
            }
          }
        }
      
        boothList.sort(function(a, b) {
          const nameA = a.name; // ignore upper and lowercase
          const nameB = b.name; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        });
        boothList.sort(function(a, b) {
          return a.name - b.name;
        });

        if(searchVal){
          var filteredBoothList = boothList.filter((booth)=>{
            var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
            return (str.toLowerCase().includes(searchVal.toLowerCase()))
          })
          setFilteredBoothList(filteredBoothList)
        }
        else{
          setFilteredBoothList(boothList)
        }
        const active_booth_file = props.main.active_booth_file
        setBoothList(boothList)
        setTotalOrgQty(active_booth_file.org_booth_cnt?active_booth_file.org_booth_cnt:0)
        setTotalOrgSqft(active_booth_file.org_booth_sqrt?active_booth_file.org_booth_sqrt:0)
        
        setLoadingFlag(false)
      }, 100)
    }
  }, [props.ui.openBoothList,props.main.canvasRefresh])

  useEffect(() => {
    if(props.ui.openBoothList && tabValue == 1){ //deleted booth list
      setLoadingFlag(true)
      setTimeout(function(){
        if(props.main.mainCanvas){
          var boothList = [];
          var deletedObjects = [...props.main.mapRemoveObjects]
          var convertRateX= 1
          var convertRateY= 1
          if(props.main.mainCanvas.convertRateX) {
              var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
              var convertRateY= Math.abs(props.main.mainCanvas.convertRateY)
          }
          var objects = [];
          for(var i = 0; i< deletedObjects.length; i++){
            var object = deletedObjects[i]
            if(object.type=="group"){
              if(object.class=="booth"){
                objects.push(object)
              }
            }
            else if(object.type=="activeSelection"){
              var subObjects = object.objects;
              if(subObjects){
                for(var j = 0; j<subObjects.length; j++){
                  var subObject = subObjects[j];
                  if(subObject.class=="booth"){
                    objects.push(subObject)
                  }
                }

              }
            }
          }
          console.log("deletedObjects", deletedObjects)
          for(var i = 0; i< objects.length; i++){
            var object = objects[i]
            var row = {}
            if(object.class=="booth"){
              row['name'] = object.booth_number
              row['priced'] = false
              var priceCheck = object.priceCheck;
              var premiumCheck = object.premiumCheck;
              if(premiumCheck){
                row['priced'] = true
              }
              if(priceCheck && priceCheck.length > 0){
                row['priced'] = true
              }
              if(object.sold){
                row['priced'] = true
              }
              row['company'] = object.company
              row['boothgroup'] = object.boothGroup
              row['boothType'] = object.boothType
              var booth_width = px2unit(object.width, convertRateX, mainCanvas.mapUnit).toFixed(0)
              var booth_height = px2unit(object.height, convertRateX, mainCanvas.mapUnit).toFixed(0)
              row['boothsize'] = booth_width+" x "+ booth_height
              var sqrt = 0;
              if(object.class1 == "custom-booth"){
                var objects = object.objects.filter((el) => el.type == 'polyline');
                if(objects && objects.length > 0 ){
                    var points = objects[0].points;
                    var polyline = [];
                    for(var i = 0; i < points.length; i++){
                      polyline.push([px2unit(points[i].x, convertRateX, mainCanvas.mapUnit), px2unit(points[i].y, convertRateX, mainCanvas.mapUnit)])
                    }
                    sqrt = area(polyline)
                }
              }
              else{
                  sqrt = (booth_width*booth_height)
              }
              row['sqrt'] = (sqrt*1).toFixed(0)
              row['booth'] = object
             
              boothList.push(row)
            }
          }
        }
      
        boothList.sort(function(a, b) {
          const nameA = a.name; // ignore upper and lowercase
          const nameB = b.name; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        });
        boothList.sort(function(a, b) {
          return a.name - b.name;
        });

        if(searchVal){
          var filteredBoothList = boothList.filter((booth)=>{
            var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
            return (str.toLowerCase().includes(searchVal.toLowerCase()))
          })
          setFilteredBoothListDeleted(filteredBoothList)
        }
        else{
          setFilteredBoothListDeleted(boothList)
        }        
        setLoadingFlag(false)
      }, 100)
    }
  }, [props.ui.openBoothList,props.main.canvasRefresh, tabValue])

  const doSearch = (searchVal)=>{
    if(searchVal){
      var filteredBoothList = boothList.filter((booth)=>{
        var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
        return (str.toLowerCase().includes(searchVal.toLowerCase()))
      })
      setFilteredBoothList(filteredBoothList)
    }
    else
      setFilteredBoothList(boothList)
  }

  var totalLiveQty = 0
  var totalLiveSqft = 0

  const getGroupBySize = (filteredBoothList)=>{
    let groupBySize = filteredBoothList.reduce((r, a) => {
      r[a.boothsize] = [...r[a.boothsize] || [], a];
      return r;
     }, {});
     boothList.sort(function(a, b) {
      const nameA = a.boothsize; // ignore upper and lowercase
      const nameB = b.boothsize; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    });
    var output = [];
    for(var boothsize in groupBySize){
      var items = groupBySize[boothsize];
      var itemSqrtSum = 0;
      if(items){
        for(var i = 0; i < items.length; i++){
          itemSqrtSum+= items[i].sqrt*1
        }
        totalLiveQty+= items.length;
        totalLiveSqft+= itemSqrtSum;
      }
      output.push({boothsize, items, itemSqrtSum})
    }
    console.log("groupBySize", output)
    return output;
  }
  
  var totalLiveQtyDeleted = 0
  var totalLiveSqftDeleted = 0
  const getGroupBySizeDeleted = (filteredBoothListDeleted)=>{
    let groupBySize = filteredBoothListDeleted.reduce((r, a) => {
      r[a.boothsize] = [...r[a.boothsize] || [], a];
      return r;
     }, {});
    var output = [];
    for(var boothsize in groupBySize){
      var items = groupBySize[boothsize];
      var itemSqrtSum = 0;
      if(items){
        for(var i = 0; i < items.length; i++){
          itemSqrtSum+= items[i].sqrt*1
        }
        totalLiveQtyDeleted+= items.length;
        totalLiveSqftDeleted+= itemSqrtSum;
      }
      output.push({boothsize, items, itemSqrtSum})
    }
    console.log("groupBySize", output)
    return output;
  }


  var groupBySize = getGroupBySize(filteredBoothList)
  var groupBySizeDeleted = getGroupBySizeDeleted(filteredBoothListDeleted)

  var totalDiffQty = totalLiveQty*1 - totalOrgQty*1
  var totalDiffSqft = totalLiveSqft*1 - totalOrgSqft*1
  var totalDiffQtyPercent = 0;
  var totalDiffSqftPercent = 0;
  if(totalLiveQty > 0){
    totalDiffQtyPercent = (totalDiffQty*100/totalLiveQty).toFixed(2)
  }
  if(totalLiveSqft > 0){
    totalDiffSqftPercent = (totalDiffSqft*100/totalLiveSqft).toFixed(2)
  }
  var percentColor = 'black'
  if(totalDiffQtyPercent*1 < 0 || totalDiffSqftPercent*1 < 0){
    percentColor = 'red'
  }
  
  return (
    <Draggable
    handle=".handle"
    defaultPosition={{x: 0, y: 0}}
    position={null}
    grid={[2, 2]}
    bounds="body"
    scale={1}>
      <Box className="booth-drawer">
          <Box display="flex" pt={2} pb={2} style={{width:'100%', justifyContent:'space-between', alignItems:'center'}}>
              <DragIndicatorIcon fontSize="small" style={{cursor:'move', width:20}} className="handle"/>
              <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search company or booth"
                  inputProps={{ 'aria-label': 'search booth' }}
                  // value={searchVal}
                  onBlur={
                      (e) => {
                          setSearchVal(e.target.value)
                      }
                  }
                  onKeyDown={
                      (e)=>{
                          if(e.keyCode == 13){
                              e.preventDefault();
                              doSearch(e.target.value)
                          }
                      }
                  }
                  onChange={
                    (e)=>{
                      if(e.target.value == "" || !e.target.value){
                        doSearch(e.target.value)
                      }
                    }
                  }
              />
              <IconButton size="small" type="button" onClick = {()=>doSearch(searchVal)} aria-label="search">
                  <SearchIcon />
              </IconButton>
            <div style={{display:'flex', alignItems:'center', paddingRight:3}}>
              {props.role == 'admin' ?          
              <IconButton
                  size="small"
                  edge="start"
                  aria-label="assign"
                  style={{float:'right'}}
                  onClick={()=>{props.setOpenBoothList(false)}}
                >
                  <HighlightOffOutlinedIcon/>
              </IconButton>:
              <IconButton
                size="small"
                edge="start"
                aria-label="assign"
                style={{float:'right'}}
                onClick={()=>{props.setLeftPage('home')}}
              >
                <ChevronLeftIcon/>
              </IconButton>
              }
            </div>
          </Box>
          <Divider />
          <Box value={tabValue} index={0} style={{height:600, overflowY:'auto'}}>
          <LoadingOverlay
              active={loadingFlag}
              styles={{
                  // spinner: (base) => ({
                  // ...base,
                  // width: '50px',
                  // '& svg circle': {
                  //     stroke: '#1976d2'
                  // }
                  // }),
                  overlay: (base) => ({
                  ...base,
                  fontSize:'18px',
                  color: 'rgb(5, 37, 51)',
                  // background: 'rgb(229 229 229 / 92%)',
                  background: 'transparent',
                  position:'absolute',
                  left:'50%',
                  top:'200px',
                  transform:'translateX(-50%)',
                  zIndex:1000000000
                  })
              }}
              spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
              // spinner
              // text='Loading ...'
              >
            </LoadingOverlay> 
            <Box display="flex"  style={{justifyContent:'space-between', padding:'10px 16px', borderBottom:'1px solid #e0e0e0'}}>
              <Box style={{width: '30%', textAlign:'left'}}><b>Booth</b></Box>
              <Box style={{width: '30%', textAlign:'left'}}><b>Size</b></Box>
              <Box style={{width: '40%', textAlign:'center'}}><b>Company</b></Box>
            </Box>
            <Box>
              {
                  filteredBoothList.map((booth, booth_index) => (
                  <ListItem key={booth_index}>
                    <ListItemText 
                      primary={
                        <Box display="flex" style={{justifyContent:'space-between'}}>
                          <Box style={{width: '30%', textAlign:'left'}}><label>{booth.name}</label></Box>
                          <Box style={{width: '30%', textAlign:'left'}}><label>{(booth.boothsize)}</label></Box>
                          <Box style={{width: '40%', textAlign:'center'}}>{booth.company}</Box>
                        </Box>
                      } 
                    />
                    
                  </ListItem>
                ))
              }
            </Box>
          </Box>
          
          
        
      </Box>
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setLeftSearchVal: value=>dispatch(setLeftSearchVal(value)) ,
    setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
    setOpenSetBoothModal:  data=>dispatch(setOpenSetBoothModal(data)),
    setActive3dObject: value=>dispatch(setActive3dObject(value)) ,
    setOpenBoothDrawer: value=>dispatch(setOpenBoothDrawer(value)) ,
  };
};

export default connect(mapStateToProps, mapDispatchProps)(BoothList);
